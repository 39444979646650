import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import React from 'react';
import { Table } from '../../../components/UI/Table';
import TableFooterBar from '../../../components/UI/Shared/TableFooterBar';
import { profileRankId } from '../../../enums/profile';
import { validDate, displayDate } from '../../../utils/moment';
import { getEagleHistory } from '../../../services/Bonuses';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var columnsDefinition = [{
  name: 'Statement Date',
  render: function render(row) {
    return ___EmotionJSX("h4", null, validDate(row.rankRunTargetDateFrom) && validDate(row.rankRunTargetDateTo) ? "From ".concat(displayDate(row.rankRunTargetDateFrom), "\n           To ").concat(displayDate(row.rankRunTargetDateTo)) : ___EmotionJSX("div", {
      className: "table-data__cell__no-value"
    }));
  },
  width: '150px'
}, {
  name: 'Previous Eagle Rank ',
  render: function render(_ref) {
    var _profileRankId$previo;
    var previousRankId = _ref.previousRankId,
      NAItem = _ref.NAItem;
    return (_profileRankId$previo = profileRankId[previousRankId]) !== null && _profileRankId$previo !== void 0 ? _profileRankId$previo : NAItem;
  }
}, {
  name: 'Previous Eagle Stars',
  value: 'previousRankStars'
}, {
  name: 'Current Eagle Rank',
  render: function render(_ref2) {
    var _profileRankId$eagleR;
    var eagleRankId = _ref2.eagleRankId,
      NAItem = _ref2.NAItem;
    return (_profileRankId$eagleR = profileRankId[eagleRankId]) !== null && _profileRankId$eagleR !== void 0 ? _profileRankId$eagleR : NAItem;
  }
}, {
  name: 'Current Eagle Stars',
  value: 'eagleRankStars'
}, {
  name: 'Accounts',
  render: function render(row) {
    return row.accounts.length ? row.accounts.map(function (el) {
      return el.accountId;
    }).join() : ___EmotionJSX("div", {
      className: "table-data__cell__no-value"
    });
  }
}];
var EagleTable = function EagleTable() {
  return ___EmotionJSX("div", {
    className: "vk-paper-box"
  }, ___EmotionJSX(Table, {
    getDataKey: "eagleStats",
    getDataMethod: getEagleHistory,
    columns: columnsDefinition,
    hasPagination: true,
    pageSize: 5,
    footerBar: TableFooterBar({
      hasPageSize: false
    }),
    emptyMessage: "You do not have any Eagle Rank information for this period"
  }));
};
export default EagleTable;