import "core-js/modules/es.function.name.js";
import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import LinkButton from '../../../components/UI/Button/LinkButton';
import ProfilePaymentMobile from './ProfilePaymentMobile';
import ProfileMobileAppInfo from './ProfileMobileAppInfo';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ProfilePlanSponsor = function ProfilePlanSponsor(props) {
  var user = props.user,
    packages = props.packages;
  var currentPlan = packages.current;
  var payForMobileModalRef = useRef();
  var mobileInfoModalRef = useRef();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    xs: 12,
    justify: "space-between",
    alignItems: "flex-start",
    className: "vk-profile-sponsor-wrap"
  }, currentPlan && ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-profile-current-plan-wrapper"
  }, ___EmotionJSX("div", {
    className: "vk-current-plan-logo"
  }, ___EmotionJSX("img", {
    alt: "Pic",
    src: currentPlan.logo
  })), ___EmotionJSX("div", {
    className: "vk-current-plan-name-label-wrapper"
  }, ___EmotionJSX("p", {
    className: "vk-profile-sponsor-label"
  }, "Current Plan"), ___EmotionJSX("p", {
    className: "vk-profile-sponsor-value"
  }, currentPlan.name), ___EmotionJSX(LinkButton, {
    linkType: true,
    to: "/shop"
  }, "Upgrade Plan"))))), ___EmotionJSX(ProfilePaymentMobile, {
    ref: payForMobileModalRef,
    userId: user.userId
  }), ___EmotionJSX(ProfileMobileAppInfo, {
    ref: mobileInfoModalRef,
    user: user
  }));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var user = _ref.user,
    packages = _ref.packages;
  return {
    user: user,
    packages: packages
  };
};
export default connect(mapStateToProps)(ProfilePlanSponsor);