import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _startCase from "lodash/startCase";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import { compareWithNow } from '../../../utils/moment';
import { showInfo } from '../../../components/UI/Notifications';
import ColorBadge from '../../../components/UI/Shared/ColorBadge';
import { copyToClipboard } from '../../../utils/copyText';
import { giftCodeStatuses } from '../../../enums/orders';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var GiftCodeWithStatus = function GiftCodeWithStatus(row) {
  var _mapGiftCodeStatus, _mapGiftCodeStatus$st, _mapGiftCodeStatus$st2;
  var giftCode = row.giftCode,
    status = row.status,
    expireDate = row.expireDate;
  var notPaid = status === giftCodeStatuses.Unpaid;
  var mapGiftCodeStatus = (_mapGiftCodeStatus = {}, _defineProperty(_mapGiftCodeStatus, giftCodeStatuses.Redeemed, {
    text: 'Redeemed',
    color: 'green'
  }), _defineProperty(_mapGiftCodeStatus, _startCase(giftCodeStatuses.NotRedeemed), {
    text: 'Not Redeemed',
    color: 'blue'
  }), _defineProperty(_mapGiftCodeStatus, giftCodeStatuses.Unpaid, {
    text: 'Not Paid',
    color: 'red'
  }), _mapGiftCodeStatus);
  var hasExpired = compareWithNow(expireDate) < 0;
  var handleClick = function handleClick() {
    if (hasExpired) return null;
    copyToClipboard(giftCode);
    showInfo({
      title: 'Copy to clipboard'
    });
  };
  return ___EmotionJSX("div", {
    className: "vk-order-history-gift-code-table-col"
  }, ___EmotionJSX(ColorBadge, {
    type: (_mapGiftCodeStatus$st = mapGiftCodeStatus[status]) === null || _mapGiftCodeStatus$st === void 0 ? void 0 : _mapGiftCodeStatus$st.color
  }, (_mapGiftCodeStatus$st2 = mapGiftCodeStatus[status]) === null || _mapGiftCodeStatus$st2 === void 0 ? void 0 : _mapGiftCodeStatus$st2.text), !notPaid && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, giftCode), ___EmotionJSX("button", {
    className: "custom-btn vk-primary-btn vk-btn-size-s ".concat(hasExpired ? 'custom-btn-disabled' : ''),
    onClick: handleClick,
    disabled: hasExpired
  }, "Copy")));
};
export var redeemedByNameAccountId = function redeemedByNameAccountId(row) {
  var redeemedBy = row.redeemedBy,
    redeemAccountId = row.redeemAccountId;
  return redeemedBy || redeemAccountId ? ___EmotionJSX("span", null, redeemedBy, "/#", redeemAccountId) : ___EmotionJSX("div", {
    className: "table-data__cell__no-value"
  });
};