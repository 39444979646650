import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import { ReactComponent as SuccessIcn } from '../../../assets/images/layout-svg-icons/icn-success.svg';
import { ReactComponent as PendingIcn } from '../../../assets/images/layout-svg-icons/icn-loading.svg';
import { ReactComponent as FailIcn } from '../../../assets/images/layout-svg-icons/cancel.svg';
import { pendingWindowStatus } from '../../../enums/transferCoinWallet';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TransferActionsStatus = function TransferActionsStatus(props) {
  var status = props.status;
  return ___EmotionJSX("div", {
    className: "vk-shop-order-complete"
  }, ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content"
  }, ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content-image"
  }, status === pendingWindowStatus.SUCCESS ? ___EmotionJSX(SuccessIcn, null) : status === pendingWindowStatus.PENDING ? ___EmotionJSX(PendingIcn, null) : ___EmotionJSX(FailIcn, null)), ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, status === pendingWindowStatus.SUCCESS ? 'Success. Deployment done' : status === pendingWindowStatus.PENDING ? 'Deployment in progress' : 'Process Fail'), ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content-text"
  }, status === pendingWindowStatus.SUCCESS ? ___EmotionJSX("p", null, "Now you can see your new wallet.") : status === pendingWindowStatus.PENDING ? ___EmotionJSX("p", null, "The blockchain needs technical time to process the transaction and it may take a while.") : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, "Please contact our support agent for more details at"), ___EmotionJSX("a", {
    href: "mailto:support@oneecosystem.eu"
  }, " support@oneecosystem.eu"))), ___EmotionJSX("div", {
    className: "transfer-actions-container"
  }, ___EmotionJSX(Link, {
    to: "/dashboard",
    className: "vk-link"
  }, "Go to Dashboard"), status === pendingWindowStatus.SUCCESS && ___EmotionJSX(Link, {
    to: "/wallet/one"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-l"
  }, "View my wallets"))))));
};
TransferActionsStatus.propTypes = {
  status: PropTypes.string,
  onStartTransfer: PropTypes.func
};
export default TransferActionsStatus;