/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as AngleDoubleLeftIcon } from '../../../../assets/images/layout-svg-icons/icn-burgerClosed.svg';
import { ReactComponent as AngleDoubleRightIcon } from '../../../../assets/images/layout-svg-icons/icn-burgerOpened.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AsideHeader = function AsideHeader(props) {
  var isAsideOpen = props.isAsideOpen,
    onAsideToggleClick = props.onAsideToggleClick;
  return ___EmotionJSX("div", {
    className: "kt-aside__brand kt-grid__item",
    id: "kt_aside_brand"
  }, ___EmotionJSX("div", {
    className: "kt-aside__brand-tools"
  }, ___EmotionJSX("button", {
    className: "kt-aside__brand-aside-toggler",
    id: "kt_aside_toggler",
    onClick: onAsideToggleClick
  }, ___EmotionJSX("span", null, isAsideOpen ? ___EmotionJSX(AngleDoubleLeftIcon, null) : ___EmotionJSX(AngleDoubleRightIcon, null)))), isAsideOpen && ___EmotionJSX("div", {
    className: "kt-aside__brand-logo"
  }, ___EmotionJSX(Link, {
    to: ""
  }, ___EmotionJSX("img", {
    alt: "logo",
    src: "https://onelife.blob.core.windows.net/images/one_ecosystem_logo_light_v2.png"
  }))));
};
AsideHeader.propTypes = {
  isAsideOpen: PropTypes.bool,
  onAsideToggleClick: PropTypes.func
};
export default AsideHeader;