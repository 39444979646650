function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CounterInput from '../../../components/UI/Input/CounterInput';
import Button from '../../../components/UI/Button/Button';
import currencyByType from '../../../utils/currencyByType';
import { walletType } from '../../../enums/wallets';
import starterLogo from '../../../assets/images/bonuses/starter.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Package = function Package(props) {
  var _ref, _item$picThumbnailUrl;
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onAddToCard = props.onAddToCard;
  var name = item.name,
    price = item.price;
  var amountRef = useRef(1);
  return ___EmotionJSX("div", {
    className: "wallet-gas-shop-package-card"
  }, ___EmotionJSX(Link, {
    to: {
      pathname: "/shop/package-details/".concat(item.catalogueItemId),
      state: _objectSpread({}, item)
    }
  }, ___EmotionJSX("div", {
    className: "wallet-gas-shop-package-card-top"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 7
  }, ___EmotionJSX("h4", {
    className: "wallet-gas-page-heading"
  }, name, ___EmotionJSX("br", null), currencyByType(price.toFixed(2), walletType.CASH))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 5
  }, ___EmotionJSX("img", {
    src: (_ref = (_item$picThumbnailUrl = item === null || item === void 0 ? void 0 : item.picThumbnailUrl) !== null && _item$picThumbnailUrl !== void 0 ? _item$picThumbnailUrl : item === null || item === void 0 ? void 0 : item.pictureUrl) !== null && _ref !== void 0 ? _ref : starterLogo,
    className: "vk-image-responsive",
    alt: "img-networkbonus"
  }))))), onAddToCard && ___EmotionJSX("div", {
    className: "vk-shop-package-card-add"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-card-add-input-btn"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX(CounterInput, {
    count: 1,
    min: 1,
    max: 10,
    onCountChange: function onCountChange(count) {
      amountRef.current = count;
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-s",
    clicked: function clicked() {
      return onAddToCard(_objectSpread(_objectSpread({}, item), {}, {
        amount: amountRef.current
      }));
    }
  }, "Add to cart"))))));
};
Package.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func
};
export default Package;