import "core-js/modules/es.array.map.js";
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckIcn } from '../../../assets/images/layout-svg-icons/checked-green.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PaymentMethodsComponent = function PaymentMethodsComponent(props) {
  var _props$paymentMethods = props.paymentMethods,
    paymentMethods = _props$paymentMethods === void 0 ? {} : _props$paymentMethods,
    catalogueItemId = props.catalogueItemId;
  return ___EmotionJSX("div", {
    className: "subscription-data-methods"
  }, ___EmotionJSX("div", {
    className: "payment-methods-title"
  }, "Payment methods"), paymentMethods && paymentMethods[catalogueItemId] && paymentMethods[catalogueItemId].length > 0 ? paymentMethods[catalogueItemId].map(function (method, index) {
    return ___EmotionJSX("div", {
      key: "method-".concat(index)
    }, ___EmotionJSX(CheckIcn, null), ___EmotionJSX("span", {
      className: "payment-methods-data"
    }, " ", method.displayName));
  }) : ___EmotionJSX("span", null, "No payment methods available!"));
};
PaymentMethodsComponent.propTypes = {
  paymentMethods: PropTypes.object,
  catalogueItemId: PropTypes.number
};
export default PaymentMethodsComponent;