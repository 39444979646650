import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcn } from '../../../assets/images/layout-svg-icons/icn-sm-checkmark-tick.svg';
import { ReactComponent as ErrorIcn } from '../../../assets/images/layout-svg-icons/icn-alert-warning.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TextArea = function TextArea(props) {
  var rows = props.rows,
    label = props.label,
    name = props.name,
    value = props.value,
    error = props.error,
    errorMsg = props.errorMsg,
    successMsg = props.successMsg,
    success = props.success,
    handleBlur = props.handleBlur,
    handleChange = props.handleChange;
  var inputClasses = ['vk-form-control'];
  var formGroupClasses = ['vk-form-group'];
  if (error) {
    inputClasses.push('vk-invalid-input');
    formGroupClasses.push('vk-invalid-group');
  }
  if (success && value) {
    inputClasses.push('vk-valid-input');
    formGroupClasses.push('vk-valid-group');
  }
  return ___EmotionJSX("div", {
    className: formGroupClasses.join(' ')
  }, label && ___EmotionJSX("label", {
    className: "vk-form-label"
  }, label), ___EmotionJSX("textarea", {
    value: value,
    rows: rows,
    className: inputClasses.join(' '),
    name: name,
    onBlur: handleBlur,
    onChange: handleChange
  }), ___EmotionJSX("div", {
    className: "vk-input-validation-message vk-ivm-error"
  }, ___EmotionJSX("i", null, ___EmotionJSX(ErrorIcn, null)), ___EmotionJSX("span", null, errorMsg)), ___EmotionJSX("div", {
    className: "vk-input-validation-message vk-ivm-success"
  }, ___EmotionJSX("i", null, ___EmotionJSX(SuccessIcn, null)), ___EmotionJSX("span", null, successMsg !== null && successMsg !== void 0 ? successMsg : "".concat(label, " is correct"))));
};
TextArea.propTypes = {
  rows: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  value: PropTypes.string,
  success: PropTypes.bool,
  successMsg: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
};
export default TextArea;