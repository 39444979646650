function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CustomCheckBox from '../../../components/UI/Input/CustomCheckBox';
import CounterInput from '../../../components/UI/Input/CounterInput';
import Button from '../../../components/UI/Button/Button';
import starterLogo from '../../../assets/images/bonuses/starter.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Package = function Package(props) {
  var _ref, _item$picThumbnailUrl;
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onAddToCard = props.onAddToCard,
    cashWallet = props.cashWallet;
  var name = item.name,
    mandatoryActivation = item.mandatoryActivation,
    price = item.price,
    catalogueItemId = item.catalogueItemId;
  var _useState = useState(1),
    _useState2 = _slicedToArray(_useState, 2),
    amount = _useState2[0],
    setAmount = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    activationFee = _useState4[0],
    setActivationFee = _useState4[1];
  var isNegativeCashBalance = (cashWallet === null || cashWallet === void 0 ? void 0 : cashWallet.balance) < 0;
  var isSoldOut = !!soldOutPackages.find(function (el) {
    return el.systemName === item.systemName;
  });
  return ___EmotionJSX("div", {
    className: "vk-shop-package-card"
  }, ___EmotionJSX(Link, {
    to: {
      pathname: "/shop/package-details/".concat(catalogueItemId),
      state: _objectSpread({}, item)
    }
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-card-top"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 7
  }, ___EmotionJSX("h3", {
    className: clsx('vk-page-heading', isSoldOut && 'vk-shop-package-sold-out')
  }, name, !isSoldOut && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("br", null), "\u20AC", price.toFixed(2))), isSoldOut && ___EmotionJSX("h2", {
    className: "vk-shop-package-sold-out"
  }, "SOLD OUT")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 5
  }, ___EmotionJSX("img", {
    src: (_ref = (_item$picThumbnailUrl = item === null || item === void 0 ? void 0 : item.picThumbnailUrl) !== null && _item$picThumbnailUrl !== void 0 ? _item$picThumbnailUrl : item === null || item === void 0 ? void 0 : item.pictureUrl) !== null && _ref !== void 0 ? _ref : starterLogo,
    className: "vk-image-responsive",
    alt: "img-networkbonus"
  }))))), onAddToCard && !isSoldOut && ___EmotionJSX("div", {
    className: "vk-shop-package-card-add"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-card-add-checkbox"
  }, !mandatoryActivation && ___EmotionJSX(CustomCheckBox, {
    name: name,
    onCheckboxChange: function onCheckboxChange(event) {
      return setActivationFee(event);
    }
  }), ___EmotionJSX("span", {
    className: "vk-shop-package-card-add-checkbox-percent"
  }, "With activation fee (+ \u20AC30)")), ___EmotionJSX("div", {
    className: "vk-shop-package-card-add-input-btn"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: "auto",
    sm: "auto"
  }, ___EmotionJSX(CounterInput, {
    count: 1,
    min: 1,
    max: 10,
    onCountChange: function onCountChange(count) {
      return setAmount(count);
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: "auto",
    sm: true
  }, !isNegativeCashBalance && ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-m",
    clicked: function clicked() {
      return onAddToCard(_objectSpread(_objectSpread({}, item), {}, {
        activationFee: activationFee,
        amount: amount
      }));
    }
  }, "Add to cart"))))));
};
Package.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func,
  cashWallet: PropTypes.object
};
export default Package;