import React from 'react';
import { paymentMethodTypes } from '../../../../enums/paymentMethods';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var descriptionByPaymentType = function descriptionByPaymentType(paymentType) {
  switch (true) {
    case paymentType === paymentMethodTypes.BankDetails:
      return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, "Following Your Crypto Currency Request To Purchase The NewLife Package, The OneEcosystem Team Would Like To Kindly Refer You To An Official OneEcosystem Member For Your Region Who Will Handle The Payment For You.", ' '), ___EmotionJSX("p", null, "This Procedure Is Necessary So That We Can Ensure That Your Payment Will Get Processed Effectively And On Time."), ___EmotionJSX("p", null, "You Can Use The Crypto Currency Payment By Contacting Distributor:", ' ', ___EmotionJSX("a", {
        href: "mailto:invoice@onelifecorp.eu"
      }, "invoice@onelifecorp.eu")), ___EmotionJSX("p", null, "Please Don\u2019t Forget To Include The Following Information:"), ___EmotionJSX("ul", null, ___EmotionJSX("li", null, "First Name"), ___EmotionJSX("li", null, "Last Name"), ___EmotionJSX("li", null, "Country"), ___EmotionJSX("li", null, "City")), ___EmotionJSX("p", null, "Alternatively, You Can UseOne Of The Other Payment Methods Available!"), ___EmotionJSX("p", null, "Thank You For Your Understanding!"), ___EmotionJSX("p", null, "Best Wishes,"), ___EmotionJSX("p", null, "The OneEcosystem Team"));
    default:
      return null;
  }
};