function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import UpcomingBackground from '../../../assets/images/layout-svg-icons/coint-exchange-icon.jpg';
import Subscription from '../components/Subscription';
import PaymentMethodsComponent from '../components/PaymentMethodsComponent';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Subscriptions = function Subscriptions(props) {
  var addToShoppingCart = props.addToShoppingCart,
    subscriptions = props.subscriptions,
    submitButtonDisabled = props.submitButtonDisabled,
    paymentMethods = props.paymentMethods;
  return ___EmotionJSX("div", {
    className: "one-forex-subscriptions"
  }, (subscriptions || []).map(function (catItem) {
    return ___EmotionJSX(Grid, {
      container: true,
      spacing: 2,
      direction: "row",
      alignItems: "stretch",
      className: "subscriptions-container",
      key: "".concat(catItem.systemName).concat(catItem.id)
    }, ___EmotionJSX(Grid, {
      container: true,
      item: true,
      xs: 12,
      lg: 8,
      alignItems: "stretch",
      justify: "space-evenly"
    }, catItem.subscriptionPlans.map(function (plan, index) {
      var subscriptionPlan = _objectSpread(_objectSpread({}, plan), {}, {
        picThumbnailUrl: catItem.picThumbnailUrl,
        pictureUrl: catItem.pictureUrl
      });
      return ___EmotionJSX(Grid, {
        item: true,
        xs: true,
        sm: 5,
        key: "".concat(plan.vendor, "-").concat(plan.name, "-").concat(index),
        className: "subscriptions-container-item"
      }, ___EmotionJSX(Subscription, {
        item: subscriptionPlan,
        btnType: index === catItem.subscriptionPlans.length - 1 ? 'subscribe-primary-btn' : 'subscribe-secondary-btn',
        onAddToCart: addToShoppingCart,
        submitButtonDisabled: submitButtonDisabled
      }));
    }), ___EmotionJSX(Grid, {
      item: true,
      xs: 12,
      sm: true,
      className: "subscriptions-container-item"
    }, ___EmotionJSX("div", {
      className: "subscription-data"
    }, ___EmotionJSX(PaymentMethodsComponent, {
      paymentMethods: paymentMethods,
      catalogueItemId: catItem.id
    })))), ___EmotionJSX(Grid, {
      container: true,
      item: true,
      xs: true,
      justify: "center",
      alignItems: "center"
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: true,
      className: "upcoming-subscriptions",
      style: {
        backgroundImage: "url(".concat(UpcomingBackground, ")")
      }
    }, ___EmotionJSX("div", {
      className: "plans"
    }, ___EmotionJSX("div", {
      className: "new-subscription-info"
    }, ___EmotionJSX("div", {
      className: "new-subscriptions-label"
    }, "In 3 months")), ___EmotionJSX("div", {
      className: "new-subscriptions-container"
    }, ___EmotionJSX("div", {
      className: "new-subscription-info"
    }, ___EmotionJSX("div", {
      className: "new-subscription-price"
    }, "\u20AC160"), ___EmotionJSX("div", {
      className: "new-subscription-duration"
    }, "/month")), ___EmotionJSX("div", null, ___EmotionJSX("div", {
      className: "new-subscription-price"
    }, "\u20AC140"), ___EmotionJSX("div", {
      className: "new-subscription-duration"
    }, "/6 months")), ___EmotionJSX("div", null, ___EmotionJSX("div", {
      className: "new-subscription-price"
    }, "\u20AC120"), ___EmotionJSX("div", {
      className: "new-subscription-duration"
    }, "/12 months")))))));
  }));
};
Subscriptions.propTypes = {
  addToShoppingCart: PropTypes.func,
  subscriptions: PropTypes.array,
  vendor: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  paymentMethods: PropTypes.object
};
export default connect()(Subscriptions);