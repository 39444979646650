import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var imaToolsData = [{
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Arabic',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Arabic.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities English',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-English.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Italian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Italian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Japanese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Japanese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Korean',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Korean.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Mandarin',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Mandarin.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Portuguese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Portuguese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Romanian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Romanian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Russian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Russian.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Spanish',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Spanish.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Bonus Generating Opportunities Vietnamese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/portal/apps/documents/help-faq/ima-tools/Bonus-Generating-Opportunities-Vietnamese.pdf'
}, {
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem - Leadership Program Ranks',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneEcosystem - Leadership Program Ranks.pdf'
}];