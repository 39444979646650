import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _RESOURCE_MEDIA_TYPES;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import { ReactComponent as PdfLogo } from '../../../../assets/images/help/pdf.svg';
import { ReactComponent as JpgLogo } from '../../../../assets/images/help/jpg.svg';
import { ReactComponent as PngLogo } from '../../../../assets/images/help/png.svg';
import { ReactComponent as YoutubeLogo } from '../../../../assets/images/help/youtube.svg';
import { ReactComponent as SvgLogo } from '../../../../assets/images/help/svg.svg';
import videosLogo from '../../../../assets/images/help/videos.jpg';
import webinarsLogo from '../../../../assets/images/help/webinars.jpg';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RESOURCE_MEDIA_TYPES = {
  PDF: 'pdf',
  JPG: 'jpg',
  PNG: 'png',
  YOUTUBE: 'youtube',
  SVG: 'svg'
};
export var RESOURCE_MEDIA_TYPES_ICONS = (_RESOURCE_MEDIA_TYPES = {}, _defineProperty(_RESOURCE_MEDIA_TYPES, RESOURCE_MEDIA_TYPES.PDF, ___EmotionJSX(PdfLogo, null)), _defineProperty(_RESOURCE_MEDIA_TYPES, RESOURCE_MEDIA_TYPES.JPG, ___EmotionJSX(JpgLogo, null)), _defineProperty(_RESOURCE_MEDIA_TYPES, RESOURCE_MEDIA_TYPES.PNG, ___EmotionJSX(PngLogo, null)), _defineProperty(_RESOURCE_MEDIA_TYPES, RESOURCE_MEDIA_TYPES.YOUTUBE, ___EmotionJSX(YoutubeLogo, null)), _defineProperty(_RESOURCE_MEDIA_TYPES, RESOURCE_MEDIA_TYPES.SVG, ___EmotionJSX(SvgLogo, null)), _RESOURCE_MEDIA_TYPES);
export var mapResourceDataToViewModel = function mapResourceDataToViewModel(data, viewerIsAdmin) {
  var publicData = data.filter(function (row) {
    return viewerIsAdmin === undefined || viewerIsAdmin === true ? true : row["public"];
  });
  return publicData.map(function (row) {
    return _objectSpread(_objectSpread({}, row), {}, {
      image: RESOURCE_MEDIA_TYPES_ICONS[row.type]
    });
  });
};
export var getResourcesCardData = function getResourcesCardData(isAdmin) {
  return [{
    id: 1,
    title: 'Ima Tools',
    url: '/information-center/resources/ima-tools'
  }, {
    id: 2,
    title: 'Branding Materials',
    url: '/information-center/resources/branding-materials'
  }, {
    id: 3,
    title: 'Product and business presentations',
    url: '/information-center/resources/product-and-business-presentations'
  }, {
    id: 4,
    title: 'Video',
    image: videosLogo,
    url: '/information-center/resources/video'
  }, isAdmin && {
    id: 5,
    title: 'Webinars',
    image: webinarsLogo,
    url: '/information-center/resources/webinars'
  }].filter(Boolean);
};