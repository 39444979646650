import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AddMemberComp from './AddMember';
import InformationIcon from './InformationIcon';
import AccountInfo from './AccountInfo';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTreeAvatar = function NetworkTreeAvatar(props) {
  var nodeData = props.nodeData,
    onChange = props.onChange,
    onAccountCreate = props.onAccountCreate,
    root = props.root,
    packageColor = props.packageColor,
    disabled = props.disabled;
  var isEmpty = !nodeData.id;
  return ___EmotionJSX("div", {
    className: clsx('network-tree-avatar-container', isEmpty && 'network-tree-avatar-container--empty')
  }, !isEmpty ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(AccountInfo, {
    nodeData: nodeData,
    onClick: onChange,
    root: root,
    packageColor: packageColor
  }), !disabled && ___EmotionJSX(InformationIcon, {
    nodeData: nodeData
  })) : ___EmotionJSX(AddMemberComp, {
    onAccountCreate: onAccountCreate,
    nodeData: nodeData
  }));
};
NetworkTreeAvatar.propTypes = {
  disabled: PropTypes.bool,
  packageColor: PropTypes.string,
  nodeData: PropTypes.object,
  root: PropTypes.object,
  onAccountCreate: PropTypes.func,
  onChange: PropTypes.func
};
export default NetworkTreeAvatar;