import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _sortBy from "lodash/sortBy";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import hash from 'object-hash';
import SearchInput from '../../../components/UI/Input/SearchInput';
import ReactSelect from '../../../components/UI/ReactSelect';
import { jsx as ___EmotionJSX } from "@emotion/react";
var filterOptions = [{
  label: 'Sort by: Value',
  value: 'price'
}, {
  label: 'Sort by: Date',
  value: 'createdOn'
}, {
  label: 'Sort by: Name',
  value: 'name'
}];
var FilterBar = function FilterBar(props) {
  var _selectRef$current;
  var items = props.items,
    setItems = props.setItems;
  var filterValuesRef = useRef({
    sort: filterOptions[0].value
  });
  var selectRef = useRef();
  var selectStyles = _objectSpread(_objectSpread({}, (_selectRef$current = selectRef.current) === null || _selectRef$current === void 0 ? void 0 : _selectRef$current.colorStyles), {}, {
    control: function control() {
      var _selectRef$current2, _selectRef$current2$c;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return _objectSpread(_objectSpread({}, (_selectRef$current2 = selectRef.current) === null || _selectRef$current2 === void 0 ? void 0 : (_selectRef$current2$c = _selectRef$current2.colorStyles).control.apply(_selectRef$current2$c, args)), {}, {
        minHeight: 41
      });
    }
  });
  useEffect(function () {
    if (!(items !== null && items !== void 0 && items.length)) return;
    var _filterValuesRef$curr = filterValuesRef.current,
      sort = _filterValuesRef$curr.sort,
      filter = _filterValuesRef$curr.filter;
    sort && handleSortChange({
      value: sort
    });
    filter && handleFilterChange({
      target: {
        value: filter
      }
    });
  }, [hash({
    items: items
  })]);
  var handleSortChange = function handleSortChange(_ref) {
    var value = _ref.value;
    filterValuesRef.current.sort = value;
    setItems(_sortBy(items, value));
  };
  var handleFilterChange = function handleFilterChange(value) {
    filterValuesRef.current.filter = value;
    setItems(items.filter(function (el) {
      return el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }));
  };
  return ___EmotionJSX("div", {
    className: "vk-shop-page-filters"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "flex-start"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("div", {
    className: "vk-shop-search-filter"
  }, ___EmotionJSX(SearchInput, {
    noMinLength: true,
    noDebounce: true,
    placeholder: "Search Modules",
    handleChange: handleFilterChange
  }))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-shop-sort-filter"
  }, ___EmotionJSX(ReactSelect, {
    ref: selectRef,
    options: filterOptions,
    onChange: handleSortChange,
    defaultValue: filterOptions[0],
    styles: selectStyles
  })))));
};
FilterBar.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func
};
export default FilterBar;