import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _startCase from "lodash/startCase";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WalletBalanceCard from '../components/WalletBalanceCard';
import TablesFilterBar from '../components/TablesFilterBar';
import { boldText, amount, descriptionWithStatus } from '../components/TableDataComponents';
import { Table } from '../../../components/UI/Table';
import TableFooterBar from '../../../components/UI/Shared/TableFooterBar';
import Button from '../../../components/UI/Button/Button';
import NoWallets from '../../../components/UI/Shared/NoWallets';
import { walletType } from '../../../enums/wallets';
import currencyByType from '../../../utils/currencyByType';
import { getTransactionsByWallet } from '../../../services/Wallets';
import { ReactComponent as BalanceIcn } from '../../../assets/images/layout-svg-icons/icn-wallet-freecoins.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var columnsDefinition = [{
  name: 'Date',
  value: 'createdOn',
  sortable: true,
  dateTimeFormat: 'DD/MM/YYYY',
  width: '100px'
}, {
  name: 'Description',
  render: descriptionWithStatus
}, {
  name: 'System reason',
  render: function render(row) {
    return _startCase(row.reason);
  },
  width: '120px'
}, {
  name: 'Amount',
  width: '100px',
  render: amount('amount')
}, {
  name: 'Balance',
  width: '110px',
  render: boldText('newBalance')
}];
var Trading = function Trading(_ref) {
  var tradingWallet = _ref.tradingWallet;
  var history = useHistory();
  return ___EmotionJSX("div", {
    className: "vk-wallets-balance-wraper"
  }, ___EmotionJSX("div", {
    className: "transactions-history-title-container vk-mt-32"
  }, ___EmotionJSX("div", {
    className: "transactions-history-title "
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Trading Account")), ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    clicked: function clicked() {
      history.push('/wallet/trading/archive');
    }
  }, "Archive")), !(tradingWallet !== null && tradingWallet !== void 0 && tradingWallet.id) && ___EmotionJSX(NoWallets, null), !!(tradingWallet !== null && tradingWallet !== void 0 && tradingWallet.id) && ___EmotionJSX("div", {
    className: "wallets-blue-container"
  }, ___EmotionJSX(WalletBalanceCard, {
    title: "Total Balance",
    amount: currencyByType(tradingWallet.balance, walletType.TRADING),
    image: ___EmotionJSX(BalanceIcn, null)
  })), !!(tradingWallet !== null && tradingWallet !== void 0 && tradingWallet.id) && ___EmotionJSX("div", {
    className: "vk-paper-box"
  }, ___EmotionJSX(Table, {
    getDataKey: "transactions",
    getDataMethod: getTransactionsByWallet(tradingWallet.id, false),
    columns: columnsDefinition,
    pageSize: 10,
    hasPagination: true,
    filterBar: function filterBar(options) {
      return TablesFilterBar(_objectSpread(_objectSpread({}, options), {}, {
        fromArchive: false
      }));
    },
    footerBar: TableFooterBar(),
    emptyMessage: "No Transactions to Show",
    updateOn: tradingWallet === null || tradingWallet === void 0 ? void 0 : tradingWallet.id
  })));
};
var mapProps = function mapProps(_ref2) {
  var _accounts$current;
  var accounts = _ref2.accounts;
  return {
    tradingWallet: (accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.wallets.find(function (el) {
      return el.walletType === walletType.TRADING;
    })) || {}
  };
};
export default connect(mapProps)(Trading);