import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import React, { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Network from './tabs/Network';
import MatchingTree from './tabs/MatchingTree';
import Community from './tabs/Community';
import MainTabs from '../../components/UI/Shared/MainTabs';
import { useLayoutDispatch } from '../../components/UI/Layout/LayoutContext';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkPage = function NetworkPage(props) {
  var user = props.user;
  var chineseProfile = user.country.toLowerCase() === 'china';
  var path = props.match.path;
  var layoutDispatch = useLayoutDispatch();
  useEffect(function () {
    if (chineseProfile) {
      layoutDispatch({
        type: 'SET_SUBHEADER',
        payload: 'My Community'
      });
    }
  }, [chineseProfile]);
  var tabs = [{
    label: chineseProfile ? 'Affiliates' : 'Network',
    route: chineseProfile ? "".concat(path, "/affiliates") : "".concat(path, "/binary-tree")
  }, !chineseProfile && {
    label: 'Matching Tree',
    route: "".concat(path, "/table-tree")
  }, {
    label: 'Community',
    route: "".concat(path, "/community")
  }].filter(Boolean);
  return ___EmotionJSX(Fragment, null, ___EmotionJSX(MainTabs, {
    tabs: tabs
  }), ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX(Switch, null, !chineseProfile ? ___EmotionJSX(Route, {
    path: "".concat(path, "/binary-tree"),
    component: Network
  }) : ___EmotionJSX(Route, {
    path: "".concat(path, "/affiliates"),
    component: Network
  }), !chineseProfile && ___EmotionJSX(Route, {
    path: "".concat(path, "/table-tree"),
    component: MatchingTree
  }), ___EmotionJSX(Route, {
    path: "".concat(path, "/community"),
    component: Community
  }), ___EmotionJSX(Redirect, {
    to: tabs[0].route
  }))));
};
export default connect(function (_ref) {
  var user = _ref.user;
  return {
    user: user
  };
})(NetworkPage);