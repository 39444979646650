import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/UI/Button/Button';
import { ReactComponent as ApprovedIcn } from '../../../assets/images/layout-svg-icons/icn-alert-success.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ProfileUploadDocsWarning = function ProfileUploadDocsWarning(_ref) {
  var onAccept = _ref.onAccept,
    onDeny = _ref.onDeny;
  return ___EmotionJSX("div", {
    className: "vk-kyc-upload-docs-modal-warning-wrapper"
  }, ___EmotionJSX("div", {
    className: "vk-kyc-upload-docs-modal-warning-content"
  }, ___EmotionJSX("div", {
    className: "vk-kyc-upload-docs-modal-warning-title"
  }, ___EmotionJSX(ApprovedIcn, null), ___EmotionJSX("h1", null, "Your profile is ", ___EmotionJSX("b", null, "ACTIVE"))), ___EmotionJSX("p", {
    className: "vk-kyc-upload-docs-modal-warning-message"
  }, "If you upload new document your profile status will become ", ___EmotionJSX("b", null, "NOT VERIFIED"), ". Are you sure you want to continue?")), ___EmotionJSX("div", {
    className: "vk-kyc-upload-docs-modal-warning-buttons"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-l",
    clicked: onAccept
  }, "Agree"), ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-l",
    clicked: onDeny
  }, "Cancel")));
};
ProfileUploadDocsWarning.propTypes = {
  onAccept: PropTypes.func,
  onDeny: PropTypes.func
};
export default ProfileUploadDocsWarning;