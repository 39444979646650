function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import currencyByType from '../../../utils/currencyByType';
import Button from '../../../components/UI/Button/Button';
import { walletType } from '../../../enums/wallets';
import { ReactComponent as CalendarIcn } from '../../../assets/images/layout-svg-icons/icn-calendar.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var WalletMaturityDateBox = function WalletMaturityDateBox(props) {
  var _depositedType;
  var deposit = props.deposit,
    onCashOut = props.onCashOut,
    type = props.type;
  var maturityDate = deposit.maturityDate,
    plan = deposit.plan,
    accruedAmount = deposit.accruedAmount;
  var withdrawableAmount = accruedAmount + plan.interestRate / 100 * accruedAmount;
  var maturity = moment(maturityDate);
  var nowDate = moment();
  var nowMonth = nowDate.format('MM-YYYY');
  var getMonths = function getMonths(start, end) {
    return Array === null || Array === void 0 ? void 0 : Array.from({
      length: end.diff(start, 'month') + 2
    }).map(function (el, index) {
      return moment(start).add(index, 'month').format('MM-YYYY');
    });
  };
  var months = getMonths(moment(nowDate, 'MM-YYYY'), moment(maturity, 'MM-YYYY')).filter(function (el) {
    return el !== nowMonth;
  });
  var firstDayOfMonth = months.map(function (el) {
    return moment(el, 'MM-YYYY').format('DD-MM-YYYY');
  });
  var depositedType = (_depositedType = {}, _defineProperty(_depositedType, walletType.COIN, {
    text: 'Coins Deposited'
  }), _defineProperty(_depositedType, walletType.CASH, {
    text: 'Cash Deposited'
  }), _depositedType);
  return ___EmotionJSX("div", {
    className: "vk-maturity-date-box-wraper"
  }, ___EmotionJSX("div", {
    className: "vk-maturity-date-box-header"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("div", {
    className: "vk-maturity-date-box-header-text-wraper"
  }, ___EmotionJSX("div", {
    className: "vk-maturity-date-box-header-text-date"
  }, ___EmotionJSX("div", {
    className: "vk-maturity-date-box-header-icon"
  }, ___EmotionJSX(CalendarIcn, null)), ___EmotionJSX("p", {
    className: "vk-maturity-date-box-header-text"
  }, "Maturity Date: ", moment(maturityDate).format('DD-MM-YYYY'), " ", "In (".concat(plan === null || plan === void 0 ? void 0 : plan.duration, " Months)"))), !!firstDayOfMonth.length && ___EmotionJSX("div", {
    className: "vk-maturity-date-box-header-text-date"
  }, ___EmotionJSX("div", {
    className: "vk-maturity-date-box-header-icon"
  }, ___EmotionJSX(CalendarIcn, null)), ___EmotionJSX("p", {
    className: "vk-maturity-date-box-header-text"
  }, "Payment Dates:", ' ', firstDayOfMonth.map(function (el, ind, arr) {
    return arr.length - 1 === ind ? "".concat(el) : "".concat(el, ", ");
  }))))), ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-s",
    clicked: function clicked() {
      return onCashOut(deposit);
    }
  }, "Cashout")))), ___EmotionJSX("div", {
    className: "vk-maturity-date-box-body"
  }, ___EmotionJSX("div", {
    className: "vk-maturity-date-box-item-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading-light"
  }, depositedType[type].text), ___EmotionJSX("div", {
    className: "vk-maturity-date-box-item-amount-wrapper"
  }, ___EmotionJSX("h3", {
    className: "vk-maturity-date-box-item-amount"
  }, currencyByType(accruedAmount, type)))), ___EmotionJSX("div", {
    className: "vk-maturity-date-box-item-separator"
  }), ___EmotionJSX("div", {
    className: "vk-maturity-date-box-item-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading-light"
  }, "Withdrawable amount"), ___EmotionJSX("div", {
    className: "vk-maturity-date-box-item-amount-wrapper"
  }, ___EmotionJSX("h3", {
    className: "vk-maturity-date-box-item-amount"
  }, currencyByType(withdrawableAmount, type)), ___EmotionJSX("span", {
    className: "vk-maturity-date-box-item-percent ".concat(plan.interestRate ? 'positive' : 'negative')
  }, plan.interestRate, "%")), ___EmotionJSX("p", {
    className: "vk-maturity-date-box-item-text"
  }, "This amount will be available on the maturity date"))));
};
WalletMaturityDateBox.propTypes = {
  deposit: PropTypes.any,
  onCashOut: PropTypes.func,
  type: PropTypes.string
};
export default WalletMaturityDateBox;