import React from 'react';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcn } from '../../../assets/images/layout-svg-icons/icn-close.svg';
import Button from '../../../components/UI/Button/Button';
import DirectSalesBonusInfo from '../inner/DirectSalesBonusInfo';
import NetworkBonusInfo from '../inner/NetworkBonusInfo';
import MatchingBonusInfo from '../inner/MatchingBonusInfo';
import StartUpBonusInfo from '../inner/StartUpBonusInfo';
import { jsx as ___EmotionJSX } from "@emotion/react";
var getBonusInfoComponent = function getBonusInfoComponent(bonusType) {
  var components = {
    network: ___EmotionJSX(NetworkBonusInfo, null),
    direct: ___EmotionJSX(DirectSalesBonusInfo, null),
    matching: ___EmotionJSX(MatchingBonusInfo, null),
    startup: ___EmotionJSX(StartUpBonusInfo, null)
  };
  return components[bonusType];
};
var BonusHelpModal = function BonusHelpModal(props) {
  var isOpen = props.isOpen,
    bonusType = props.bonusType,
    onClose = props.onClose;
  return ___EmotionJSX(Modal, {
    className: "bonus-help-modal-container",
    open: isOpen,
    onClose: onClose,
    closeAfterTransition: true,
    BackdropComponent: Backdrop,
    BackdropProps: {
      timeout: 500
    }
  }, ___EmotionJSX(Fade, {
    "in": isOpen
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-body"
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: "bonus-help-modal-close-btn",
    onClick: onClose
  }, ___EmotionJSX(CloseIcn, null)), getBonusInfoComponent(bonusType), ___EmotionJSX("div", {
    className: "bonus-help-modal-body-actions"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-l",
    clicked: onClose
  }, "OK")))));
};
BonusHelpModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  bonusType: PropTypes.string
};
export default BonusHelpModal;