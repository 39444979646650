import React from 'react';
import PropTypes from 'prop-types';
import ColorBadge from '../../../components/UI/Shared/ColorBadge';
import { accountStatusMap } from '../../../enums/account';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableStatusCol = function NetworkTableStatusCol(props) {
  var _accountStatusMap$sta, _accountStatusMap$sta2;
  var status = props.status;
  return ___EmotionJSX(ColorBadge, {
    type: (_accountStatusMap$sta = accountStatusMap[status]) === null || _accountStatusMap$sta === void 0 ? void 0 : _accountStatusMap$sta.color
  }, (_accountStatusMap$sta2 = accountStatusMap[status]) === null || _accountStatusMap$sta2 === void 0 ? void 0 : _accountStatusMap$sta2.text);
};
NetworkTableStatusCol.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default NetworkTableStatusCol;