import _isNil from "lodash/isNil";
import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ReceiverInfo = function ReceiverInfo(props) {
  var info = props.info;
  return _isNil(info.receiverNickName) && _isNil(info.receiverAccountId) ? 'N/A' : ___EmotionJSX("div", null, (info === null || info === void 0 ? void 0 : info.receiverAccountId) && ___EmotionJSX("b", {
    className: "wallets-transaction-history-accountId"
  }, "#", info === null || info === void 0 ? void 0 : info.receiverAccountId), (info === null || info === void 0 ? void 0 : info.receiverNickName) && ___EmotionJSX("p", null, info === null || info === void 0 ? void 0 : info.receiverNickName, " "));
};
ReceiverInfo.propTypes = {
  info: PropTypes.object
};
export default ReceiverInfo;