import React from 'react';
import PropTypes from 'prop-types';
import getCurrencyByType from '../../../utils/currencyByType';
import { walletType } from '../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DirectSaleStartupBonusRoundInfo = function DirectSaleStartupBonusRoundInfo(_ref) {
  var isCurrentRound = _ref.isCurrentRound,
    bonusRoundData = _ref.bonusRoundData;
  return ___EmotionJSX("div", {
    className: "direct-startup-bonus-round-container"
  }, !isCurrentRound && ___EmotionJSX("div", {
    className: "direct-startup-bonus-round-box"
  }, ___EmotionJSX("p", {
    className: "direct-startup-bonus-round-box-column-title"
  }, "Bonus Cap"), ___EmotionJSX("p", {
    className: "direct-startup-bonus-round-box-column-price"
  }, getCurrencyByType((bonusRoundData === null || bonusRoundData === void 0 ? void 0 : bonusRoundData.bonusCap) || 0, walletType.CASH))), ___EmotionJSX("div", {
    className: "direct-startup-bonus-round-box"
  }, ___EmotionJSX("p", {
    className: "direct-startup-bonus-round-box-column-title"
  }, "Total Bonus"), ___EmotionJSX("p", {
    className: "direct-startup-bonus-round-box-column-price"
  }, getCurrencyByType((bonusRoundData === null || bonusRoundData === void 0 ? void 0 : bonusRoundData.totalBonus) || 0, walletType.CASH))), ___EmotionJSX("div", {
    className: "direct-startup-bonus-round-box"
  }, ___EmotionJSX("p", {
    className: "direct-startup-bonus-round-box-column-title"
  }, isCurrentRound ? 'Bonus to be Paid' : 'Paid Bonus'), ___EmotionJSX("p", {
    className: "direct-startup-bonus-round-box-column-price"
  }, getCurrencyByType((bonusRoundData === null || bonusRoundData === void 0 ? void 0 : bonusRoundData.paidBonus) || 0, walletType.CASH))));
};
DirectSaleStartupBonusRoundInfo.propTypes = {
  isCurrentRound: PropTypes.bool,
  bonusRoundData: PropTypes.object
};
export default DirectSaleStartupBonusRoundInfo;