import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var IconTextCart = function IconTextCart(props) {
  var title = props.title,
    text = props.text,
    image = props.image;
  return ___EmotionJSX("div", {
    className: "vk-icon-text-card-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    sm: "auto",
    spacing: 3,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true
  }, ___EmotionJSX("div", {
    className: "vk-icon-text-card-icon"
  }, image)), ___EmotionJSX(Grid, {
    item: true,
    sm: true
  }, ___EmotionJSX("div", {
    className: "vk-icon-text-card-text-wraper"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading"
  }, title), ___EmotionJSX("p", {
    className: "vk-icon-text-card-text"
  }, text)))));
};
IconTextCart.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.any
};
export default IconTextCart;