import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { jsx as ___EmotionJSX } from "@emotion/react";
var linkButton = function linkButton(props) {
  var disabled = props.disabled,
    type = props.type,
    clicked = props.clicked,
    linkbtnClass = props.linkbtnClass,
    linkType = props.linkType,
    children = props.children,
    linkSize = props.linkSize,
    to = props.to,
    replace = props.replace;
  var className = [linkType ? 'link-custom-link' : 'link-custom-btn', linkbtnClass, linkSize].join(' ');
  if (to) {
    return disabled ? ___EmotionJSX("span", {
      className: className
    }, children) : ___EmotionJSX(Link, {
      to: to,
      replace: replace,
      className: className
    }, children);
  }
  return ___EmotionJSX("button", {
    disabled: disabled,
    className: className,
    onClick: clicked,
    type: type !== null && type !== void 0 ? type : 'button'
  }, children);
};
linkButton.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  clicked: PropTypes.func,
  linkbtnClass: PropTypes.string,
  linkType: PropTypes.bool,
  replace: PropTypes.bool,
  linkSize: PropTypes.string,
  children: PropTypes.any
};
export default linkButton;