import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ReactComponent as AllowedIcn } from '../../../assets/images/layout-svg-icons/icn-checklist-checked.svg';
import { ReactComponent as NotAllowedIcn } from '../../../assets/images/layout-svg-icons/icn-not-allowed.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackageBonusesBox = function PackageBonusesBox(props) {
  var network = props.network,
    matching = props.matching,
    packageData = props.packageData,
    title = props.title,
    _props$inModal = props.inModal,
    inModal = _props$inModal === void 0 ? false : _props$inModal;
  var hasMatchingBonus = (packageData === null || packageData === void 0 ? void 0 : packageData.hasMatch1Bonus) || (packageData === null || packageData === void 0 ? void 0 : packageData.hasMatch2Bonus) || (packageData === null || packageData === void 0 ? void 0 : packageData.hasMatch3Bonus) || (packageData === null || packageData === void 0 ? void 0 : packageData.hasMatch4Bonus);
  return ___EmotionJSX("div", {
    className: "vk-package-details-bonus-wrap"
  }, title && ___EmotionJSX("h4", {
    className: "vk-section-heading"
  }, title), ___EmotionJSX("div", {
    className: "vk-package-details-bonus-box"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 3,
    justify: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    md: !inModal
  }, network && ___EmotionJSX("div", {
    className: "vk-package-details-bonus-item"
  }, ___EmotionJSX("div", {
    className: "vk-package-details-bonus-item-icon"
  }, packageData !== null && packageData !== void 0 && packageData.hasNetworkBonus ? ___EmotionJSX(AllowedIcn, null) : ___EmotionJSX(NotAllowedIcn, null)), ___EmotionJSX("h4", {
    className: "vk-package-details-bonus-item-title"
  }, "Network"), ___EmotionJSX("p", {
    className: "vk-package-details-bonus-item-value"
  }, packageData !== null && packageData !== void 0 && packageData.hasNetworkBonus ? 'Yes' : 'No')), matching && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "vk-package-details-bonus-item"
  }, ___EmotionJSX("div", {
    className: "vk-package-details-bonus-item-icon"
  }, hasMatchingBonus ? ___EmotionJSX(AllowedIcn, null) : ___EmotionJSX(NotAllowedIcn, null)), ___EmotionJSX("h4", {
    className: "vk-package-details-bonus-item-title"
  }, "Matching"), ___EmotionJSX("p", {
    className: "vk-package-details-bonus-item-value"
  }, hasMatchingBonus ? 'Yes' : 'No')), ___EmotionJSX("div", {
    className: "vk-package-details-matching-bonus-item"
  }, ___EmotionJSX("h4", {
    className: "vk-package-details-bonus-item-title"
  }, "1st Level"), ___EmotionJSX("p", {
    className: "vk-package-details-bonus-item-value"
  }, packageData !== null && packageData !== void 0 && packageData.hasMatch1Bonus ? '10%' : 'N/A')), ___EmotionJSX("div", {
    className: "vk-package-details-matching-bonus-item"
  }, ___EmotionJSX("h4", {
    className: "vk-package-details-bonus-item-title"
  }, "2nd Level"), ___EmotionJSX("p", {
    className: "vk-package-details-bonus-item-value"
  }, packageData !== null && packageData !== void 0 && packageData.hasMatch2Bonus ? '10%' : 'N/A')), ___EmotionJSX("div", {
    className: "vk-package-details-matching-bonus-item"
  }, ___EmotionJSX("h4", {
    className: "vk-package-details-bonus-item-title"
  }, "3rd Level"), ___EmotionJSX("p", {
    className: "vk-package-details-bonus-item-value"
  }, packageData !== null && packageData !== void 0 && packageData.hasMatch3Bonus ? '20%' : 'N/A')), ___EmotionJSX("div", {
    className: "vk-package-details-matching-bonus-item"
  }, ___EmotionJSX("h4", {
    className: "vk-package-details-bonus-item-title"
  }, "4th Level"), ___EmotionJSX("p", {
    className: "vk-package-details-bonus-item-value"
  }, packageData !== null && packageData !== void 0 && packageData.hasMatch4Bonus ? '25%' : 'N/A')))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    md: inModal ? false : 'auto'
  }, ___EmotionJSX("div", {
    className: "vk-package-details-bonus-cap-box ".concat(inModal && 'in-modal')
  }, ___EmotionJSX("h4", {
    className: "vk-package-details-bonus-cap-label"
  }, "Bonus Cap"), ___EmotionJSX("h3", {
    className: "vk-package-details-bonus-cap-amount"
  }, packageData === null || packageData === void 0 ? void 0 : packageData.bonusCap))))))

  // </div>
  ;
};

PackageBonusesBox.propTypes = {
  network: PropTypes.bool,
  matching: PropTypes.bool,
  packageData: PropTypes.object,
  title: PropTypes.string,
  inModal: PropTypes.bool
};
export default PackageBonusesBox;