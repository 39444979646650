import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import { components } from 'react-select';
import clsx from 'clsx';
import { ReactComponent as DefaultCountryImage } from '../../../assets/images/layout-svg-icons/default-country-image.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var countryComp = function countryComp(image, className) {
  return !_isNil(image) ? ___EmotionJSX("img", {
    src: image,
    className: clsx(className || 'vk-select-flag-img'),
    alt: "".concat(image, "-flag")
  }) : ___EmotionJSX("div", {
    className: clsx('vk-select-flag-img', className && "".concat(className))
  }, ___EmotionJSX(DefaultCountryImage, null));
};
var optionRender = function optionRender(data) {
  return ___EmotionJSX("div", {
    className: "vk-select-flag-wraper"
  }, countryComp(data === null || data === void 0 ? void 0 : data.image), ___EmotionJSX("span", null, data.label));
};
var countrySelectOption = function countrySelectOption(props) {
  var changeProps = _objectSpread(_objectSpread({}, props), {}, {
    children: optionRender(props.data)
  });
  return ___EmotionJSX(components.Option, changeProps);
};
var codeOptionRender = function codeOptionRender(data) {
  return ___EmotionJSX("div", {
    className: "vk-select-flag-wraper"
  }, countryComp(data === null || data === void 0 ? void 0 : data.image), ___EmotionJSX("span", null, data === null || data === void 0 ? void 0 : data.phoneCode));
};
export var countryPhoneCodeOption = function countryPhoneCodeOption(props) {
  var changeProps = _objectSpread(_objectSpread({}, props), {}, {
    children: codeOptionRender(props.data)
  });
  return ___EmotionJSX(components.Option, changeProps);
};
var codeSingleValueRender = function codeSingleValueRender(data) {
  return data === null || data === void 0 ? void 0 : data.phoneCode;
};
export var countryPhoneCodeSingleValue = function countryPhoneCodeSingleValue(props) {
  var changeProps = _objectSpread(_objectSpread({}, props), {}, {
    children: codeSingleValueRender(props.data)
  });
  return ___EmotionJSX(components.SingleValue, changeProps);
};
export default countrySelectOption;