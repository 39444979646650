import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isString from "lodash/isString";
import _pick from "lodash/pick";
import _isArray from "lodash/isArray";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import moment from 'moment';
import queryString from 'query-string';
import defaultAvatarLogo from '../../assets/images/icons/default-avatar.png';
export var myProfileModel = function myProfileModel(data) {
  var _data$pictureUrl, _data$picThumbnailUrl;
  return _objectSpread(_objectSpread({}, data), {}, {
    pictureUrl: (_data$pictureUrl = data.pictureUrl) !== null && _data$pictureUrl !== void 0 ? _data$pictureUrl : defaultAvatarLogo,
    picThumbnailUrl: (_data$picThumbnailUrl = data.picThumbnailUrl) !== null && _data$picThumbnailUrl !== void 0 ? _data$picThumbnailUrl : defaultAvatarLogo,
    dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
    idExpiration: moment(data.idExpiration).format('YYYY-MM-DD')
  });
};
export var myKycModel = function myKycModel(data) {
  return _isArray(data === null || data === void 0 ? void 0 : data.items) ? {
    items: data.items.sort(function (a, b) {
      return moment(a.createdOn).isBefore(b.createdOn) ? 1 : -1;
    })
  } : null;
};
export var getNetworkAccountsListParams = function getNetworkAccountsListParams(options) {
  var _options$search;
  var filterField = [];
  var filterValue = [];
  if (options.searchBy && (_options$search = options.search) !== null && _options$search !== void 0 && _options$search.length) {
    filterField.push(options.searchBy);
    filterValue.push(options.search);
  }
  return queryString.stringify(_objectSpread({}, filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var getCommunityListParams = function getCommunityListParams(options) {
  var profileUserName = options.profileUserName,
    profileCountry = options.profileCountry,
    status = options.status,
    currentPackageName = options.currentPackageName,
    leadershipRankId = options.leadershipRankId,
    networkDepth = options.networkDepth,
    matchingLevel = options.matchingLevel,
    profileEagleRankId = options.profileEagleRankId,
    profileEagleRankStars = options.profileEagleRankStars;
  var filterField = [];
  var filterValue = [];
  if (_isString(profileUserName) && profileUserName.length) {
    filterField.push('profileUserName');
    filterValue.push(profileUserName);
  }
  if (profileCountry) {
    filterField.push('profileCountry');
    filterValue.push(profileCountry.value);
  }
  if (status) {
    filterField.push('status');
    filterValue.push(status.value);
  }
  if (currentPackageName) {
    filterField.push('currentPackageName');
    filterValue.push(currentPackageName.value);
  }
  if (leadershipRankId) {
    filterField.push('leadershipRankId');
    filterValue.push(leadershipRankId.value);
  }
  if (_isString(networkDepth) && networkDepth.length) {
    filterField.push('networkDepth');
    filterValue.push(networkDepth);
  }
  if (matchingLevel) {
    filterField.push('matchingLevel');
    filterValue.push(matchingLevel.value);
  }
  if (profileEagleRankId) {
    filterField.push('profileEagleRankId');
    filterValue.push(profileEagleRankId.value);
  }
  if (profileEagleRankStars) {
    filterField.push('profileEagleRankStars');
    filterValue.push(profileEagleRankStars.value);
  }
  return queryString.stringify(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};