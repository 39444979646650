import "core-js/modules/es.function.name.js";
import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import AsideHeader from './AsideHeader';
import Menu from './Menu';
import AsideDropdown from './AsideDropdown';
import AsideFooter from './AsideFooter';
import { checkStatus } from '../../../../utils/checkStatus';
import { ReactComponent as RightArrowIcon } from '../../../../assets/images/menu-icons/icn-right-arrow.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AsideLeft = function AsideLeft(props) {
  var hasAccounts = props.hasAccounts,
    htmlClassService = props.htmlClassService,
    currentPackage = props.currentPackage,
    user = props.user,
    onAsideToggle = props.onAsideToggle,
    isAsideOpen = props.isAsideOpen,
    openAsideMoble = props.openAsideMoble,
    onAsideMobileClose = props.onAsideMobileClose,
    menuConfig = props.menuConfig;
  var isActive = checkStatus(user === null || user === void 0 ? void 0 : user.status);
  var packageLogo = (currentPackage === null || currentPackage === void 0 ? void 0 : currentPackage.name) && currentPackage.logo && ___EmotionJSX("div", {
    className: "vk-inductee-button-wrapper-left"
  }, ___EmotionJSX("div", {
    className: "vk-inductee-button-image-wrapper-left"
  }, ___EmotionJSX("img", {
    src: currentPackage.logo,
    className: "vk-inductee-button-image-left",
    alt: "inductee-img"
  })), ___EmotionJSX("div", {
    className: "vk-inductee-button-label-left"
  }, ___EmotionJSX("span", null, currentPackage.name)), ___EmotionJSX(RightArrowIcon, {
    className: "vk-inductee-button-icon-left"
  }));
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "".concat(openAsideMoble ? 'kt-aside--on' : '')
  }, ___EmotionJSX("button", {
    className: "kt-aside-close",
    id: "kt_aside_close_btn",
    onClick: onAsideMobileClose
  }, ___EmotionJSX("i", {
    className: "la la-close"
  }))), ___EmotionJSX("div", {
    id: "kt_aside",
    className: "kt-aside kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop ".concat(openAsideMoble ? 'kt-aside--on' : '')
  }, ___EmotionJSX(AsideHeader, {
    isAsideOpen: isAsideOpen,
    onAsideToggleClick: onAsideToggle
  }), ___EmotionJSX("div", {
    id: "kt_aside_menu_wrapper",
    className: "kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
  }, ___EmotionJSX(AsideDropdown, null), isActive ? ___EmotionJSX(Link, {
    to: "/profile/my-modules"
  }, packageLogo) : packageLogo, ___EmotionJSX(PerfectScrollbar, {
    options: {
      wheelSpeed: 2,
      wheelPropagation: false
    }
  }, ___EmotionJSX(Menu, {
    htmlClassService: htmlClassService,
    menuConfig: menuConfig
  }))), hasAccounts && isActive && ___EmotionJSX(AsideFooter, null)));
};
var mapStateToProps = function mapStateToProps(store) {
  var _store$accounts, _store$accounts$items, _store$packages;
  return {
    hasAccounts: !!(store !== null && store !== void 0 && (_store$accounts = store.accounts) !== null && _store$accounts !== void 0 && (_store$accounts$items = _store$accounts.items) !== null && _store$accounts$items !== void 0 && _store$accounts$items.length),
    currentPackage: store === null || store === void 0 ? void 0 : (_store$packages = store.packages) === null || _store$packages === void 0 ? void 0 : _store$packages.current,
    user: store === null || store === void 0 ? void 0 : store.user
  };
};
export default connect(mapStateToProps)(AsideLeft);