import _toNumber from "lodash/toNumber";
import _findKey from "lodash/findKey";
import React from 'react';
import PropTypes from 'prop-types';
import { profileRankId, profileRankInformation, profileRank } from '../../../enums/profile';
import RatingWidget from '../../../components/UI/Shared/RatingWidget';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CommunityTableEagleCol = function CommunityTableEagleCol(props) {
  var _profileRankInformati, _profileRankInformati2, _profileRankInformati3, _profileRankInformati4;
  var eagleRankId = props.eagleRankId,
    eagleRankStars = props.eagleRankStars;
  var maxStars = eagleRankId === _toNumber(_findKey(profileRankId, function (rank) {
    return rank === profileRank.LifetimeEagle;
  })) ? 12 : 6;
  var starStyles = {
    fontSize: 9,
    marginBottom: 4
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "vk-network-table-package-col"
  }, ((_profileRankInformati = profileRankInformation[profileRankId[eagleRankId]]) === null || _profileRankInformati === void 0 ? void 0 : _profileRankInformati.rankImage) && ___EmotionJSX("img", {
    className: "vk-network-table-package-col-image",
    src: (_profileRankInformati2 = profileRankInformation[profileRankId[eagleRankId]]) === null || _profileRankInformati2 === void 0 ? void 0 : _profileRankInformati2.rankImage,
    alt: "package"
  }), ___EmotionJSX("div", {
    className: "vk-network-table-package-col-name"
  }, (_profileRankInformati3 = (_profileRankInformati4 = profileRankInformation[profileRankId[eagleRankId]]) === null || _profileRankInformati4 === void 0 ? void 0 : _profileRankInformati4.rankName) !== null && _profileRankInformati3 !== void 0 ? _profileRankInformati3 : 'N/A')), ___EmotionJSX(RatingWidget, {
    stars: maxStars,
    rated: eagleRankStars,
    style: starStyles
  }));
};
CommunityTableEagleCol.propTypes = {
  eagleRankId: PropTypes.number,
  eagleRankStars: PropTypes.number
};
export default CommunityTableEagleCol;