import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import { walletType } from '../enums/wallets';
import { transferPanelType } from '../enums/transferCoinWallet';
import currencyByType from './currencyByType';
export var GET_STATUSES_INTERVAL_MS = 30000;
export var getInformation = function getInformation(type, data) {
  switch (type) {
    case transferPanelType.PAYMENT:
      {
        return {
          type: transferPanelType.PAYMENT,
          title: 'Deployment THIRD PHASE',
          description: "Deployment is available now for your current account or for all eligible accounts (".concat(data.count, ") in your profile. You can choose for the following options below and see the applicable charges fees"),
          primaryBtnText: "Pay For All Accounts (".concat(currencyByType(data.allFee, walletType.CASH), ")"),
          secondaryBtnText: "Pay For Current Account (".concat(currencyByType(data.currentFee, walletType.CASH), ")"),
          iconClassName: 'vk-dashboard-bonuses-body-right-icon-wrap-right'
        };
      }
    case transferPanelType.TRANSFER:
      {
        return {
          type: transferPanelType.TRANSFER,
          title: 'Deployment pending',
          description: "Deployment can be started for ".concat(data.count === 1 ? 'account' : 'accounts', " #").concat(data.accounts.map(function (el) {
            return el.accountId;
          }).join(', #'), ". You can deploy your current account, or all your accounts if there are no current pending coin transaction."),
          primaryBtnText: "Deploy All Accounts (".concat(data.count, ")"),
          secondaryBtnText: 'Deploy Current Account',
          iconClassName: 'vk-dashboard-bonuses-body-right-icon-wrap-bottom'
        };
      }
    default:
      return null;
  }
};