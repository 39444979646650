import "core-js/modules/es.array.map.js";
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { faqsCardData } from './faqsConfig';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Faqs = function Faqs() {
  return ___EmotionJSX("div", {
    className: "vk-info-center-main-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 4
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Faqs")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 4
  }, faqsCardData.map(function (el) {
    return ___EmotionJSX(Grid, {
      item: true,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      key: el.id
    }, ___EmotionJSX("div", {
      className: "containerFaqs"
    }, ___EmotionJSX(Link, {
      to: el.url,
      className: "cardContainerFaqs"
    }, ___EmotionJSX("div", {
      className: "wrapFaqs"
    }, ___EmotionJSX("i", {
      className: "".concat(el.icon, " iconFaqsContainer")
    }), ___EmotionJSX("h3", {
      className: "title"
    }, el.title)))));
  })))));
};
export default Faqs;