import React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MenuItemText = function MenuItemText(props) {
  var item = props.item,
    parentItem = props.parentItem;
  return ___EmotionJSX(React.Fragment, null, item.icon && ___EmotionJSX("i", {
    className: "kt-menu__link-icon ".concat(item.icon)
  }), item.svg && ___EmotionJSX("div", {
    className: "kt-menu__link-svg"
  }, item.svg), parentItem && parentItem.bullet === 'dot' && ___EmotionJSX("i", {
    className: "kt-menu__link-bullet kt-menu__link-bullet--dot"
  }, ___EmotionJSX("span", null)), parentItem && parentItem.bullet === 'line' && ___EmotionJSX("i", {
    className: "kt-menu__link-bullet kt-menu__link-bullet--line"
  }, ___EmotionJSX("span", null)), ___EmotionJSX("span", {
    className: "kt-menu__link-text",
    style: {
      whiteSpace: 'nowrap'
    }
  }, item.title), item.badge && ___EmotionJSX("span", {
    className: "kt-menu__link-badge"
  }, ___EmotionJSX("span", {
    className: "kt-badge ".concat(item.badge.type)
  }, item.badge.value)), item.submenu && ___EmotionJSX("i", {
    className: "kt-menu__ver-arrow la la-angle-right"
  }));
};
export default MenuItemText;