function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { vendorBenefits, getVoyageSubscriptionsBenefits, VOYAGE_BENEFIT_TYPES } from '../vendors';
import { ReactComponent as CheckIcn } from '../../../assets/images/layout-svg-icons/checked-purple.svg';
import Subscription from '../components/Subscription';
import PaymentMethodsComponent from '../components/PaymentMethodsComponent';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Subscriptions = function Subscriptions(props) {
  var addToShoppingCart = props.addToShoppingCart,
    subscriptions = props.subscriptions,
    submitButtonDisabled = props.submitButtonDisabled,
    paymentMethods = props.paymentMethods;
  var getBenefitsRow = function getBenefitsRow(benefitKey, plan) {
    var _VOYAGE_BENEFIT_TYPES;
    var cellContent = ___EmotionJSX(CheckIcn, null);
    switch (benefitKey) {
      case 'AIRPORT_LOUNGE_ACCESS':
        cellContent = (_VOYAGE_BENEFIT_TYPES = VOYAGE_BENEFIT_TYPES[plan.duration]) === null || _VOYAGE_BENEFIT_TYPES === void 0 ? void 0 : _VOYAGE_BENEFIT_TYPES.AIRPORT_LOUNGE_ACCESS;
        break;
      default:
        cellContent = ___EmotionJSX(CheckIcn, null);
        break;
    }
    return getVoyageSubscriptionsBenefits(plan.name).includes(benefitKey) ? cellContent : '';
  };
  var getDurationBenefitLabel = function getDurationBenefitLabel(duration) {
    return duration === 1 ? 'Monthly duration' : "".concat(duration, " months");
  };
  return ___EmotionJSX("div", {
    className: "one-voyage-subscriptions"
  }, (subscriptions || []).map(function (catItem) {
    return ___EmotionJSX(Grid, {
      container: true,
      justify: "center",
      className: "subscriptions-container",
      key: "".concat(catItem.systemName).concat(catItem.id)
    }, ___EmotionJSX(Grid, {
      container: true,
      item: true,
      alignItems: "stretch",
      className: "subscriptions-header-row"
    }, ___EmotionJSX(Grid, {
      item: true,
      sm: 3,
      lg: true,
      className: "subscriptions-benefits-sm"
    }, ___EmotionJSX("div", {
      className: "subscription-data"
    }, ___EmotionJSX("div", {
      className: "subscription-data-title"
    }, "Benefits"))), catItem.subscriptionPlans.map(function (plan, index) {
      var subscriptionPlan = _objectSpread(_objectSpread({}, plan), {}, {
        picThumbnailUrl: catItem.picThumbnailUrl,
        pictureUrl: catItem.pictureUrl
      });
      return ___EmotionJSX(Grid, {
        item: true,
        xs: 12,
        sm: true,
        key: "".concat(plan.vendor, "-").concat(plan.name, "-").concat(index)
      }, ___EmotionJSX(Subscription, {
        item: subscriptionPlan,
        planIndex: index,
        btnType: "subscribe-primary-btn",
        onAddToCart: addToShoppingCart,
        discountLabel: plan.name === 'Basic' ? '50% discount (first 3 months)' : '',
        submitButtonDisabled: submitButtonDisabled
      }, ___EmotionJSX(PaymentMethodsComponent, {
        paymentMethods: paymentMethods,
        catalogueItemId: catItem.id
      }), getVoyageSubscriptionsBenefits(plan.name).length > 0 ? ___EmotionJSX("div", {
        className: "subscription-benefits-xs"
      }, ___EmotionJSX("span", {
        className: "subscription-benefits-title"
      }, "Benefits"), getVoyageSubscriptionsBenefits(plan.name).map(function (key) {
        return ___EmotionJSX("div", {
          key: "xs-benefit-".concat(key)
        }, ___EmotionJSX("span", {
          className: "subscription-benefit-data"
        }, " ", vendorBenefits[key]));
      })) : ___EmotionJSX(React.Fragment, null)));
    })), Object.keys(vendorBenefits).map(function (key) {
      return ___EmotionJSX(Grid, {
        container: true,
        item: true,
        alignItems: "stretch",
        key: "benefit-".concat(key),
        className: "subscriptions-benefits-sm"
      }, ___EmotionJSX(Grid, {
        item: true,
        xs: 2,
        sm: 3,
        lg: true
      }, ___EmotionJSX("div", {
        className: "subscription-data subscription-benefit-name"
      }, ___EmotionJSX("div", null, vendorBenefits[key]))), catItem.subscriptionPlans.map(function (plan, index) {
        return ___EmotionJSX(Grid, {
          item: true,
          xs: true,
          key: "".concat(key, "-").concat(index)
        }, ___EmotionJSX("div", {
          className: "subscription-data subscription-benefit-data"
        }, getBenefitsRow(key, plan)));
      }));
    }), ___EmotionJSX(Grid, {
      container: true,
      item: true,
      alignItems: "stretch",
      className: "subscriptions-benefits-sm"
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: 2,
      sm: 3,
      lg: true
    }, ___EmotionJSX("div", {
      className: "subscription-data subscription-benefit-name"
    }, ___EmotionJSX("div", null, "Subscription"))), catItem.subscriptionPlans.map(function (plan, index) {
      return ___EmotionJSX(Grid, {
        item: true,
        xs: true,
        key: "bv-".concat(index)
      }, ___EmotionJSX("div", {
        className: "subscription-data subscription-benefit-data"
      }, getDurationBenefitLabel(plan.duration), " subscription"));
    })), ___EmotionJSX(Grid, {
      container: true,
      item: true,
      alignItems: "stretch",
      className: "subscriptions-benefits-sm"
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: 2,
      sm: 3,
      lg: true
    }, ___EmotionJSX("div", {
      className: "subscription-data subscription-benefit-name"
    }, ___EmotionJSX("div", null, "BVs"))), catItem.subscriptionPlans.map(function (plan, index) {
      return ___EmotionJSX(Grid, {
        item: true,
        xs: true,
        key: "bv-".concat(index)
      }, ___EmotionJSX("div", {
        className: "subscription-data subscription-benefit-data"
      }, "+ ", plan.businessVolume, " BV"));
    })));
  }));
};
Subscriptions.propTypes = {
  addToShoppingCart: PropTypes.func,
  subscriptions: PropTypes.array,
  vendor: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  paymentMethods: PropTypes.object
};
export default connect()(Subscriptions);