import "core-js/modules/es.array.map.js";
/* eslint-disable import/no-cycle */
import React, { Fragment } from 'react';
import MenuSection from './MenuSection';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItem from './MenuItem';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MenuSubmenu = function MenuSubmenu(props) {
  var item = props.item,
    currentUrl = props.currentUrl;
  return ___EmotionJSX("ul", {
    className: "kt-menu__subnav"
  }, item.submenu.map(function (child, index) {
    return ___EmotionJSX(Fragment, {
      key: index
    }, child.section && ___EmotionJSX(MenuSection, {
      item: child,
      parentItem: item,
      currentUrl: currentUrl
    }), child.separator && ___EmotionJSX(MenuItemSeparator, {
      item: child,
      parentItem: item,
      currentUrl: currentUrl
    }), child.title && ___EmotionJSX(MenuItem, {
      item: child,
      parentItem: item,
      currentUrl: currentUrl
    }));
  }));
};
export default MenuSubmenu;