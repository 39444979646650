import "core-js/modules/es.array.slice.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../../../../components/UI/Button/Button';
import { ReactComponent as TopIconLogo } from '../../../../assets/images/icons/topicn-add.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AddMemberComp = function AddMemberComp(props) {
  var _nodeData$parent, _nodeData$path;
  var onAccountCreate = props.onAccountCreate,
    nodeData = props.nodeData;
  var addNewParams = {
    predefinedParentId: nodeData === null || nodeData === void 0 ? void 0 : (_nodeData$parent = nodeData.parent) === null || _nodeData$parent === void 0 ? void 0 : _nodeData$parent.id,
    predefinedLeg: (nodeData === null || nodeData === void 0 ? void 0 : (_nodeData$path = nodeData.path) === null || _nodeData$path === void 0 ? void 0 : _nodeData$path.slice(-1)) === '1' ? 'left' : 'right'
  };
  return ___EmotionJSX("div", {
    className: "network-tree-avatar-add-member-container"
  }, ___EmotionJSX("div", {
    className: "network-tree-avatar-add-member-content"
  }, ___EmotionJSX(TopIconLogo, {
    className: "vk-image-responsive network-tree-avatar-add-member-avatar"
  }), ___EmotionJSX("span", {
    className: "network-tree-avatar-add-member-text"
  }, "Add member")), ___EmotionJSX("div", {
    className: "network-tree-avatar-add-member-pop"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    clicked: function clicked() {
      return onAccountCreate(addNewParams);
    }
  }, "Add Account"), ___EmotionJSX(Link, {
    to: {
      pathname: '/profile/add-member',
      state: addNewParams
    }
  }, ___EmotionJSX(Button, {
    link: true,
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m"
  }, "Add Member"))));
};
AddMemberComp.propTypes = {
  nodeData: PropTypes.object,
  onAccountCreate: PropTypes.func,
  addNewParams: PropTypes.object
};
export default AddMemberComp;