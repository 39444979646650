import "core-js/modules/es.array.map.js";
import React, { Fragment } from 'react';
import MenuSection from './MenuSection';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItem from './MenuItem';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MenuList = function MenuList(props) {
  var currentUrl = props.currentUrl,
    menuConfig = props.menuConfig;
  return menuConfig.aside.items.map(function (child, index) {
    return ___EmotionJSX(Fragment, {
      key: "menuList".concat(index)
    }, child.section && ___EmotionJSX(MenuSection, {
      item: child
    }), child.separator && ___EmotionJSX(MenuItemSeparator, {
      item: child
    }), child.title && ___EmotionJSX(MenuItem, {
      item: child,
      currentUrl: currentUrl
    }));
  });
};
export default MenuList;