import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var videoDataAdminOnly = [{
  id: 1,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'Welcome To The Future Of Cryptocurrency - OneCoin',
  viewUrl: 'https://www.youtube.com/watch?v=sWdScH8UTAs'
}, {
  id: 2,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin Vs. BitCoin',
  viewUrl: 'https://www.youtube.com/watch?v=VpsR0i3vx6A'
}, {
  id: 3,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'Mining Extravaganza 2015 OneCoin Hong Kong Event',
  viewUrl: 'https://www.youtube.com/watch?v=1FMeTbCXi3c'
}, {
  id: 4,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'One World Foundation',
  viewUrl: 'https://www.youtube.com/watch?v=J6X0vSZH3s0'
}, {
  id: 5,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin Presentation Sofia 04.04.2015',
  viewUrl: 'https://www.youtube.com/watch?v=_X0ct2KaSRA'
}, {
  id: 6,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin And Conligus: When Two Giants Join Forces',
  viewUrl: 'https://www.youtube.com/watch?v=WACWld80aAY'
}, {
  id: 7,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'Welcome Of The Year Of Gold - Aurum Gold Coin - In Gold We Trust',
  viewUrl: 'https://www.youtube.com/watch?v=1wWauTaXIPs'
}, {
  id: 8,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin Event: Dubai, May 15th, 2015 At The Madinat Jumeirah Resort!',
  viewUrl: 'https://www.youtube.com/watch?v=3iZOGOuopTE'
}, {
  id: 9,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin Dr.Ruja Ignatova Interview With Auditor Deyan Dimitrov',
  viewUrl: 'https://www.youtube.com/watch?v=WAIKyBF4GuE'
}, {
  id: 10,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'Audit Presentation, Dubai Event, May 15th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=UOSStXW9YbY'
}, {
  id: 11,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'Coin Vegas',
  viewUrl: 'https://www.youtube.com/watch?v=fp5JIOQbaeo'
}, {
  id: 12,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'One Vegas Teaser',
  viewUrl: 'https://www.youtube.com/watch?v=4L-_JUgE41o'
}, {
  id: 13,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'Amazing OneCoin Event In DUBAI',
  viewUrl: 'https://www.youtube.com/watch?v=avmGZoXYyl0'
}, {
  id: 14,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'Dr. Ruja Ignatova, Birthday Party 2015',
  viewUrl: 'https://www.youtube.com/watch?v=VOtkdUSk1UE&feature=youtu.be'
}, {
  id: 15,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'The OneCoin Leadership Program',
  viewUrl: 'https://www.youtube.com/watch?v=jnxyDRJ80Jo&feature=youtu.be'
}];