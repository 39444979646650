import "core-js/modules/es.number.to-fixed.js";
import React from 'react';
import PropTypes from 'prop-types';
import currencyByType from '../../../../../../utils/currencyByType';
import { walletType, transactionType } from '../../../../../../enums/wallets';
import '../../styles.scss';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CharityAmount = function CharityAmount(_ref) {
  var data = _ref.data;
  var type = data.type,
    amount = data.amount;
  return ___EmotionJSX("div", {
    className: "latest-trans-box-item-amount-wrapper\n\t  ".concat(type === transactionType.Outbound && 'latest-trans-box-item-amount-wrapper--red')
  }, type === transactionType.Inbound ? '+ ' : '- ', currencyByType(amount.toFixed(2), walletType));
};
CharityAmount.propTypes = {
  data: PropTypes.object
};