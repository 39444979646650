import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ReactComponent as BackIcn } from '../../../assets/images/layout-svg-icons/icn-back-arrow.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var backButton = function backButton(props) {
  var handleClick = function handleClick() {
    props.history.goBack();
  };
  return ___EmotionJSX("button", {
    disabled: props.disabled,
    className: "vk-back-custom-btn",
    onClick: handleClick
  }, ___EmotionJSX("i", null, ___EmotionJSX(BackIcn, null)), ___EmotionJSX("span", null, "Go Back"));
};
backButton.propTypes = {
  disabled: PropTypes.bool,
  clicked: PropTypes.func
};
export default withRouter(backButton);