import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.date.to-iso-string.js";
import "core-js/modules/es.date.to-string.js";
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Table } from '../../../components/UI/Table';
import { currencyType } from '../../../enums/wallets';
import TableFooterBar from '../../../components/UI/Shared/TableFooterBar';
import { linkText, randomString, amount, dateTime } from '../components/TableDataComponents';
import mockTableRes from '../../../utils/mockTableRes';
import BackButton from '../../../components/UI/Button/BackButton';
import { ReactComponent as LeftIcn } from '../../../assets/images/layout-svg-icons/chevron-left100.svg';
import { ReactComponent as RightIcn } from '../../../assets/images/layout-svg-icons/chevron-right100.svg';
import { ReactComponent as CopyIcn } from '../../../assets/images/layout-svg-icons/copiar.svg';
import noImageLogo from '../../../assets/images/icons/no-image.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var columnsDefinition = [{
  name: 'Hash',
  render: linkText('hash', '/crypto/transaction-details')
}, {
  name: 'Amount Transacted',
  render: amount('amountTransacted'),
  width: '200px'
}, {
  name: 'Date',
  render: dateTime('minedDateTime'),
  width: '200px'
}, {
  name: 'Tx Fee',
  value: 'txFee',
  width: '200px'
}];
var dataKey = 'blocksTransactions';
var dataMock = Array(10).fill().map(function () {
  return {
    hash: randomString(),
    amountTransacted: '6.25',
    minedDateTime: new Date().toISOString(),
    txFee: '0.0000213'
  };
});
var BlockDetails = function BlockDetails() {
  return ___EmotionJSX("div", {
    className: "vk-crypto-block-details-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX(BackButton, null)), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-nav-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-nav-box"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-nav-label-wrap"
  }, ___EmotionJSX(LeftIcn, null), ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "PREVIOUS BLOCK")), ___EmotionJSX(Link, {
    to: "/crypto/transaction-details",
    className: "vk-link"
  }, "oxvfkeityolivf4y60odon6gh1bpd90j7lt6w2udj4sdw1wg56m76b5bo8oc"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-nav-box vk-crypto-block-details-nav-box-current"
  }, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "THIS BLOCK"), ___EmotionJSX("p", null, "oxvfkeityolivf4y60odon6gh1bpd90j7lt6w2udj4sdw1wg56m76b5bo8oc"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-nav-box vk-crypto-block-details-nav-box-next"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-nav-label-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "NEXT BLOCK"), ___EmotionJSX(RightIcn, null)), ___EmotionJSX("p", null, "Not available"))))))), ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-img-title-wrap"
  }, ___EmotionJSX("img", {
    src: noImageLogo,
    alt: "onecoin-logo",
    className: "vk-image-responsive vk-crypto-block-details-panel-img"
  }), ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-name-value-wrap"
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Block Height"), ___EmotionJSX("h3", {
    className: "vk-crypto-block-details-panel-number"
  }, ___EmotionJSX("span", null, "635670"), ___EmotionJSX(CopyIcn, null))))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Block Size"), ___EmotionJSX("p", null, "1089013 bytes"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Received Time"), ___EmotionJSX("p", null, ___EmotionJSX("small", null, "5 minutes ago"), ___EmotionJSX("br", null), ___EmotionJSX("span", null, "22/06/2020 - 15:10:24 UTC")))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Total Transactions"), ___EmotionJSX("p", null, "1,334"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Confirmations"), ___EmotionJSX("p", null, "1"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Difficulty"), ___EmotionJSX("p", null, "15.78 TH"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Rewards"), ___EmotionJSX("p", null, "6.25 ", currencyType.COIN))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Transaction Fees"), ___EmotionJSX("p", null, "0.08723459 ", currencyType.COIN))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 4,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-block-details-panel-info-data-label-value-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, "Merkle Root"), ___EmotionJSX("p", null, "76b7d79cf0567a7fe546...")))))))), ___EmotionJSX("div", {
    className: "vk-crypto-block-body-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading"
  }, "Block Transactions"), ___EmotionJSX("div", {
    className: "overflow-x-auto"
  }, ___EmotionJSX("div", {
    className: "vk-paper-box",
    style: {
      minWidth: '1080px'
    }
  }, ___EmotionJSX(Table, {
    getDataKey: dataKey,
    getDataMethod: mockTableRes(dataKey, dataMock),
    columns: columnsDefinition,
    pageSize: 10,
    hasPagination: true,
    footerBar: TableFooterBar(),
    emptyMessage: "No Blocks Transactions to Show"
  })))));
};
export default BlockDetails;