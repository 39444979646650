import React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MenuSection = function MenuSection(props) {
  var item = props.item;
  return ___EmotionJSX("li", {
    className: "kt-menu__section"
  }, ___EmotionJSX("h4", {
    className: "kt-menu__section-text"
  }, item.section), ___EmotionJSX("i", {
    className: "kt-menu__section-icon flaticon-more-v2"
  }));
};
export default MenuSection;