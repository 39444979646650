function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import { connect } from 'react-redux';
import { Table } from '../../../components/UI/Table';
import TableFooterBar from '../../../components/UI/Shared/TableFooterBar';
import { getLeadersList, getMyVotes } from '../../../services/LeadersSurvey';
import defaultAvatarLogo from '../../../assets/images/icons/default-avatar.png';
import { surveyConfig } from '../../../enums/survey';
import { jsx as ___EmotionJSX } from "@emotion/react";
var renderCandidateData = function renderCandidateData(row) {
  var _row$candidatePicThum;
  return ___EmotionJSX("div", {
    className: "profile-leaders-candidate-name"
  }, ___EmotionJSX("div", {
    className: "profile-leaders-avatar"
  }, ___EmotionJSX("img", {
    alt: "Pic",
    src: (_row$candidatePicThum = row === null || row === void 0 ? void 0 : row.candidatePicThumbnailUrl) !== null && _row$candidatePicThum !== void 0 ? _row$candidatePicThum : defaultAvatarLogo
  })), ___EmotionJSX("div", null, row.candidateFirstName, " ", row.candidateLastName));
};
var getColumnsDefinition = function getColumnsDefinition(isMyVotes) {
  var columns = [{
    name: 'Rank',
    value: 'rank',
    width: '70px',
    visible: !isMyVotes
  }, {
    name: 'Candidate Name',
    render: renderCandidateData,
    visible: true
  }, {
    name: isMyVotes ? 'My Total Votes' : 'Total Votes',
    value: 'totalVotes',
    visible: true,
    width: isMyVotes ? '120px' : '100px'
  }];
  return columns.filter(function (col) {
    return col.visible;
  });
};
var Leaders = function Leaders(props) {
  var voteType = props.voteType;
  var handleGetMyVotes = function handleGetMyVotes(tableOptions) {
    return getMyVotes(_objectSpread(_objectSpread({}, tableOptions), {}, {
      voteType: voteType
    }));
  };
  var handleGetLeadersList = function handleGetLeadersList(tableOptions) {
    return getLeadersList(_objectSpread(_objectSpread({}, tableOptions), {}, {
      voteType: voteType
    }));
  };
  return ___EmotionJSX("div", {
    className: "profile-leaders-paper"
  }, ___EmotionJSX("div", {
    className: "profile-leaders-my-votes"
  }, ___EmotionJSX("h2", null, "My Votes"), ___EmotionJSX(Table, {
    getDataMethod: handleGetMyVotes,
    getDataKey: "votes",
    columns: getColumnsDefinition(true),
    pageSize: 5,
    hasPagination: true,
    footerBar: TableFooterBar({
      value: 5,
      hasPageSize: false
    }),
    emptyMessage: "No Votes to Show"
  })), ___EmotionJSX("div", {
    className: "profile-leaders-network-votes"
  }, ___EmotionJSX("h2", null, surveyConfig[voteType].resultsTitle), ___EmotionJSX(Table, {
    getDataMethod: handleGetLeadersList,
    getDataKey: "votes",
    columns: getColumnsDefinition(false),
    pageSize: 10,
    hasPagination: true,
    footerBar: TableFooterBar(),
    emptyMessage: "No Votes to Show"
  })));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var _accounts$current;
  var accounts = _ref.accounts;
  return {
    currentAccountId: accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.id
  };
};
export default connect(mapStateToProps)(Leaders);