import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '../../../components/UI/Loader';
import { jsx as ___EmotionJSX } from "@emotion/react";
var LoadingSubscriptionCard = function LoadingSubscriptionCard() {
  return ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center"
  }, Array(10).fill().map(function (el, i) {
    return ___EmotionJSX(Grid, {
      container: true,
      spacing: 2,
      xs: 4,
      alignItems: "center",
      justify: "center",
      key: "loading-state-skeleton-".concat(i)
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: 4
    }, ___EmotionJSX(Skeleton, {
      width: 100,
      height: 100,
      circle: true,
      className: "vk-image-responsive subscription-image"
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 10
    }, ___EmotionJSX(Skeleton, {
      className: "image-bottom"
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 10,
      className: "subscription-item"
    }, ___EmotionJSX(Skeleton, {
      className: "subscription-heading vk-page-heading"
    }), ___EmotionJSX("br", null), ___EmotionJSX(Skeleton, {
      className: "subscription-name vk-page-heading"
    }), ___EmotionJSX(Skeleton, {
      className: "subscription-duration vk-page-heading"
    }), ___EmotionJSX(Skeleton, {
      className: "subscription-name vk-page-heading"
    }), ___EmotionJSX(Skeleton, {
      className: "subscription-date vk-page-heading"
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 10,
      className: "subscription-item"
    }, ___EmotionJSX(Skeleton, {
      className: "subscription-actions vk-page-heading"
    })));
  }));
};
export default LoadingSubscriptionCard;