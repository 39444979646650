import _isArray from "lodash/isArray";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Collapse, Tooltip } from '@material-ui/core';
import { paymentMethodTypes } from '../../../../enums/paymentMethods';
import { descriptionByPaymentType } from './config';
import { ReactComponent as AccordionWrapIcn } from '../../../../assets/images/layout-svg-icons/icn-accordion-arrow.svg';
import { ReactComponent as BankTransferIcn } from '../../../../assets/images/layout-svg-icons/icn-big-banktransfer.svg';
import DashboardMessageInformation from '../../../../pages/dashboard/components/DashboardMessageInformation';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PaymentMethod = function PaymentMethod(props) {
  var collapse = props.collapse,
    checked = props.checked,
    onChange = props.onChange,
    onCollapse = props.onCollapse,
    title = props.title,
    paymentType = props.paymentType,
    walletBalance = props.walletBalance,
    orderPrice = props.orderPrice,
    secondOrderPrice = props.secondOrderPrice,
    secondWalletBalance = props.secondWalletBalance,
    disabled = props.disabled,
    configSettings = props.configSettings;
  var isDisabled = _isArray(disabled) && !!disabled.length;
  var showPrimaryBalance = paymentType !== paymentMethodTypes.BankDetails && paymentType !== paymentMethodTypes.Cash75ReserveCash25 && paymentType !== paymentMethodTypes.Cash75Coin25 && paymentType !== paymentMethodTypes.Cash50ReserveCash50 && paymentType !== paymentMethodTypes.Cash50Coins50;
  var reserveOrCoinLabel = paymentType === paymentMethodTypes.Cash75ReserveCash25 || paymentType === paymentMethodTypes.Cash50ReserveCash50 ? ' Reserve cash ballance' : ' Coin ballance';
  var methodsWithExpiration = paymentType === paymentMethodTypes.BankDetails || paymentType === paymentMethodTypes.Bank50Coins50 || paymentType === paymentMethodTypes.Bank75Cash25 || paymentType === paymentMethodTypes.Bank75ReserveCash25 || paymentType === paymentMethodTypes.Bank75Coins25 || paymentType === paymentMethodTypes.Cash75Bank25 || paymentType === paymentMethodTypes.Bank50ReserveCash50;
  var expirationPeriod = "".concat(configSettings.orderExpirePeriodDays, " days");
  var header = ___EmotionJSX("div", {
    className: clsx('vk-payment-method-header', collapse ? 'show' : 'hide', isDisabled && 'disabled')
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("div", {
    className: "vk-payment-method-header-content"
  }, !isDisabled ? ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: clsx('vk-payment-method-header-check', checked && 'checked'),
    onClick: function onClick() {
      return onChange(paymentType);
    }
  }) : ___EmotionJSX("div", {
    className: "vk-payment-method-header-check-disabled"
  }), ___EmotionJSX("h3", {
    className: "vk-section-heading"
  }, title)), isDisabled && ___EmotionJSX("p", {
    className: "disabled-information"
  }, "This payment method cannot be selected.")), ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: "vk-payment-method-header-arrow",
    onClick: function onClick() {
      return onCollapse(paymentType);
    }
  }, ___EmotionJSX(AccordionWrapIcn, null)))));
  return ___EmotionJSX("div", {
    className: "vk-payment-method-wrap"
  }, isDisabled ? ___EmotionJSX(Tooltip, {
    arrow: true,
    placement: "bottom",
    title: ___EmotionJSX("div", {
      className: "disabled-tooltip-title"
    }, disabled.filter(function (el) {
      return el.name !== undefined;
    }).map(function (el) {
      return el.name;
    }).join(', '), " catalogue item", disabled.length > 1 ? 's' : '', " cannot be purchased with this payment method.")
  }, header) : header, ___EmotionJSX(Collapse, {
    "in": collapse
  }, ___EmotionJSX("div", {
    className: "vk-payment-method-body"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 4,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: true
  }, ___EmotionJSX("div", {
    className: "vk-payment-method-body-content"
  }, showPrimaryBalance && ___EmotionJSX("div", {
    className: "vk-payment-method-body-content-title"
  }, "Ballance: ", walletBalance), secondWalletBalance && ___EmotionJSX("div", {
    className: "vk-payment-method-body-content-title"
  }, reserveOrCoinLabel, ": ", walletBalance, ___EmotionJSX("br", null), "Cash Ballance: ", secondWalletBalance), ___EmotionJSX("div", {
    className: "vk-payment-method-body-content-description"
  }, showPrimaryBalance && ___EmotionJSX("p", null, "Please note that your account will be debited", ___EmotionJSX("b", null, " IMMEDIATELY"), " with the amount of ", orderPrice, "\xA0 and this transaction is ", ___EmotionJSX("b", null, "IRREVERSIBLE.")), secondOrderPrice && ___EmotionJSX("p", null, "Please note that your account will be debited", ___EmotionJSX("b", null, " IMMEDIATELY"), " with the amount of ", orderPrice, " from your ", reserveOrCoinLabel, ___EmotionJSX("span", null, " and amount of ", secondOrderPrice, " from your Cash Ballance and this transaction is"), ___EmotionJSX("b", null, " IRREVERSIBLE.")), ___EmotionJSX("br", null), descriptionByPaymentType(paymentType), ___EmotionJSX(DashboardMessageInformation, {
    condensed: true,
    canHide: false,
    show: methodsWithExpiration,
    text: "You must complete the order in a maximum of ".concat(expirationPeriod, ". \n                      After this period, the order will expire automatically and the amount will not be refunded!")
  })))), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-payment-method-body-icon"
  }, ___EmotionJSX(BankTransferIcn, null)))))));
};
PaymentMethod.propTypes = {
  collapse: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onCollapse: PropTypes.func,
  title: PropTypes.string,
  paymentType: PropTypes.string,
  walletBalance: PropTypes.string,
  orderPrice: PropTypes.string,
  secondOrderPrice: PropTypes.string,
  secondWalletBalance: PropTypes.string,
  disabled: PropTypes.array,
  configSettings: PropTypes.object
};
export default PaymentMethod;