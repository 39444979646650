import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _surveyConfig;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var voteTypes = {
  regionalLeaders: 'RegionalLeaders',
  countryBoardMembers: 'CountryBoardMembers'
};
var getIneligibleMessages = function getIneligibleMessages(surveyTitle) {
  return ["You are not eligible to vote! Most likely you haven't gone through the KYC verification. Only verified \n      profiles can participate in the ".concat(surveyTitle, "."), "Your current account is not eligible to vote! Only active accounts can participate in the ".concat(surveyTitle, "."), "You are not eligible to vote! Only Deployed accounts are eligible to participate \n      in the ".concat(surveyTitle, "."), "Your current account is not eligible to vote! You must have at least Starter or higher priced module to\n      participate in the ".concat(surveyTitle, ".")];
};
export var surveyConfig = (_surveyConfig = {}, _defineProperty(_surveyConfig, voteTypes.regionalLeaders, {
  title: 'ONE ECOSYSTEM NETWORK VOTES',
  questionMarkDescription: "In order to ensure fair representation and encourage global collaboration, we are thrilled to announce that free elections is being held for members of the Internation Global Council and leading positions within the OE Network. Vote for an IMA in your local community and ensure your \n        local Community is fairly represented!",
  warningMessage: 'Please, be aware that each account can vote once and the vote cannot be revoked or changed!',
  description: 'Elect your representative in the International Global Council',
  ineligibleMessages: getIneligibleMessages('ONE Ecosystem Network Votes'),
  resultSize: 3,
  resultsTitle: 'Regional Leaders',
  resultsUrl: '/profile/leaders/regional'
}), _defineProperty(_surveyConfig, voteTypes.countryBoardMembers, {
  title: 'LOCAL COUNTRY BOARD',
  questionMarkDescription: 'It\'s time cast your vote for the members of the Local Country Boards! Don\'t miss this chance to shape the future of your Network! Make your vote count and be part of the positive transformation brought by the Local Country Board.',
  warningMessage: 'Be aware that vote only once, and this vote must be for an account that is in the same country.',
  description: 'Elect your representative in the local Country Board.',
  ineligibleMessages: getIneligibleMessages('Local Country Board'),
  resultSize: 5,
  resultsTitle: 'Country Board Leaders',
  resultsUrl: '/profile/leaders/country-board'
}), _surveyConfig);