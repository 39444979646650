import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcn } from '../../../assets/images/layout-svg-icons/icn-alert-warning.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ErrorIconLabel = function ErrorIconLabel(props) {
  var visible = props.visible,
    text = props.text;
  return visible ? ___EmotionJSX("div", {
    className: "vk-error-box"
  }, ___EmotionJSX("i", null, ___EmotionJSX(ErrorIcn, null)), ___EmotionJSX("span", null, text)) : null;
};
ErrorIconLabel.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string
};
export default ErrorIconLabel;