import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SelectCustom from '../../../components/UI/Input/SelectCustom';
import TextInput from '../../../components/UI/Input/TextInput';
import CustomCheckBox from '../../../components/UI/Input/CustomCheckBox';
import Button from '../../../components/UI/Button/Button';
import PhoneInput from '../../../components/UI/PhoneInput';
import countrySelectOption from '../../../components/UI/Shared/countrySelectOptions';
import countryList from '../../../enums/countries';
import { validationsRegexp } from '../../../utils/validations';
import placeholderRookieIcon from '../../../assets/images/logos/img-placeholder-rookie.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var FirstStepRegistration = function FirstStepRegistration(props) {
  var onSubmit = props.onSubmit,
    goBack = props.goBack;
  var handleEmailChange = function handleEmailChange(e, touched, callback) {
    if (touched.preferredAccountNickname) return;
    var value = e.target.value;
    callback({
      target: {
        name: 'preferredAccountNickname',
        value: value.split('@')[0]
      }
    });
  };
  return ___EmotionJSX(Grid, {
    className: "kt-login__body"
  }, ___EmotionJSX(Grid, {
    className: "kt-login__form"
  }, ___EmotionJSX("div", {
    className: "kt-login__title"
  }, ___EmotionJSX("div", {
    className: "kt-login__title-container"
  }, ___EmotionJSX("div", null, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Sign up as a Rookie"), ___EmotionJSX("p", null, "A free educational package"), "\xA0", ___EmotionJSX(Link, {
    to: "/privacy-policy",
    className: "vk-link"
  }, "Learn more")), ___EmotionJSX("img", {
    alt: "Logo",
    src: placeholderRookieIcon
  }))), ___EmotionJSX(Formik, {
    initialValues: {
      firstName: '',
      lastName: '',
      country: 'Please Select...',
      email: '',
      phone: '',
      // userName property will be set to email, since users can login with email we pass as userName the email
      userName: '',
      preferredAccountNickname: '',
      password: '',
      confirmPassword: '',
      imaEnabled: false,
      newsletterEnabled: false,
      ageCompliant: false
    },
    validate: function validate(values) {
      var errors = {};
      if (!values.firstName) errors.firstName = 'First Name is required';else if (values.firstName.length < 1 || values.firstName.length > 100) errors.firstName = 'First name must be at least 1 and max 100 characters long.';
      if (!values.lastName) errors.lastName = 'Last Name is required';else if (values.lastName.length < 1 || values.lastName.length > 100) errors.lastName = 'Last name must be at least 1 and max 100 characters long.';
      if (values.country === 'Please Select...') errors.country = 'Country is required';
      if (!values.email) errors.email = 'Email is required';else if (!validationsRegexp.emailValidPattern.test(values.email)) errors.email = 'Email is not valid';
      if (!values.preferredAccountNickname) errors.preferredAccountNickname = 'Account nickname is required';else if (values.preferredAccountNickname.length < 3 || values.preferredAccountNickname.length > 40) errors.preferredAccountNickname = 'Account nickname must be at least 3 and max 40 characters long.';else if (!validationsRegexp.nickName.test(values.preferredAccountNickname)) errors.preferredAccountNickname = 'Nickname not correct';
      if (!values.phone) errors.phone = 'Phone is required';else if (values.phone.split(' ')[1].length < 6 || values.phone.split(' ')[1].length > 30) errors.phone = 'Phone number must be at least 6 and max 30 characters long.';
      if (!values.password) errors.password = 'Password is required';else if (values.password.length < 8) errors.password = 'The Password must be at least 8 characters long.';else if (!validationsRegexp.passwordSpecialCharPattern.test(values.password)) errors.password = 'Passwords must have at least one non alphanumeric character';else if (!/[a-z]+/.test(values.password)) errors.password = "Passwords must have at least one lowercase ('a'-'z')";else if (!/[A-Z]+/.test(values.password)) errors.password = "Passwords must have at least one uppercase ('A'-'Z')";
      if (!values.confirmPassword) errors.confirmPassword = 'Confirm Password is required';else if (values.password !== values.confirmPassword) errors.confirmPassword = "Password and Confirm Password didn't match.";
      if (!values.ageCompliant) errors.ageCompliant = 'You must be 18 years old!';
      if (!values.imaEnabled) errors.imaEnabled = 'You should agree to become IMA!';
      return errors;
    },
    onSubmit: onSubmit
  }, function (_ref) {
    var values = _ref.values,
      errors = _ref.errors,
      touched = _ref.touched,
      _handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur,
      handleSubmit = _ref.handleSubmit,
      isSubmitting = _ref.isSubmitting;
    return ___EmotionJSX("div", {
      className: "kt-form"
    }, ___EmotionJSX(Grid, {
      container: true,
      spacing: 4
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: 6
    }, ___EmotionJSX(TextInput, {
      type: "email",
      label: "Email Address",
      placeholder: "Email Address",
      name: "email",
      acceptRgx: validationsRegexp.email,
      value: values.email,
      errorMsg: touched.email && errors.email,
      error: Boolean(touched.email && errors.email),
      success: Boolean(touched.email && !errors.email),
      handleBlur: handleBlur,
      handleChange: function handleChange(e) {
        handleEmailChange(e, touched, _handleChange);
        _handleChange(e);
      }
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 6
    }, ___EmotionJSX(TextInput, {
      type: "text",
      label: "Account Nickname",
      placeholder: "Please choose Account Nickname",
      name: "preferredAccountNickname",
      acceptRgx: validationsRegexp.nickName,
      value: values.preferredAccountNickname,
      errorMsg: touched.preferredAccountNickname && errors.preferredAccountNickname,
      error: Boolean(touched.preferredAccountNickname && errors.preferredAccountNickname),
      success: Boolean(touched.preferredAccountNickname && !errors.preferredAccountNickname),
      handleBlur: handleBlur,
      handleChange: _handleChange
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 6
    }, ___EmotionJSX(TextInput, {
      type: "password",
      label: "Password",
      name: "password",
      placeholder: "Password",
      value: values.password,
      errorMsg: touched.password && errors.password,
      error: Boolean(touched.password && errors.password),
      success: Boolean(touched.password && !errors.password),
      handleBlur: handleBlur,
      handleChange: _handleChange
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 6
    }, ___EmotionJSX(TextInput, {
      type: "password",
      label: "Confirm Password",
      name: "confirmPassword",
      placeholder: "Confirm password",
      value: values.confirmPassword,
      errorMsg: touched.confirmPassword && errors.confirmPassword,
      error: Boolean(touched.confirmPassword && errors.confirmPassword),
      success: Boolean(touched.confirmPassword && !errors.confirmPassword),
      handleBlur: handleBlur,
      handleChange: _handleChange
    })), ___EmotionJSX(Grid, {
      item: true,
      sm: 6,
      xs: 12
    }, ___EmotionJSX(TextInput, {
      type: "text",
      placeholder: "First Name",
      label: "First name",
      name: "firstName",
      acceptRgx: validationsRegexp.lettersSpaceDash,
      value: values.firstName,
      errorMsg: touched.firstName && errors.firstName,
      error: Boolean(touched.firstName && errors.firstName),
      success: Boolean(touched.firstName && !errors.firstName),
      handleBlur: handleBlur,
      handleChange: _handleChange
    })), ___EmotionJSX(Grid, {
      item: true,
      sm: 6,
      xs: 12
    }, ___EmotionJSX(TextInput, {
      type: "text",
      placeholder: "Last Name",
      label: "Last name",
      name: "lastName",
      acceptRgx: validationsRegexp.lettersSpaceDash,
      value: values.lastName,
      errorMsg: touched.lastName && errors.lastName,
      error: Boolean(touched.lastName && errors.lastName),
      success: Boolean(touched.lastName && !errors.lastName),
      handleBlur: handleBlur,
      handleChange: _handleChange
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 12,
      sm: 6
    }, ___EmotionJSX(SelectCustom, {
      label: "Country",
      name: "country",
      components: {
        Option: countrySelectOption
      },
      value: values.country,
      options: countryList,
      errorMsg: touched.country && errors.country,
      error: Boolean(touched.country && errors.country),
      success: Boolean(touched.country && !errors.country),
      handleBlur: handleBlur,
      handleChange: _handleChange
    })), ___EmotionJSX(Grid, {
      item: true,
      xs: 12,
      sm: 6
    }, ___EmotionJSX(PhoneInput, {
      type: "telephone",
      label: "Mobile Phone",
      placeholder: "Mobile Phone number",
      name: "phone",
      value: values.phone,
      errorMsg: touched.phone && errors.phone,
      error: Boolean(touched.phone && errors.phone),
      success: Boolean(touched.phone && !errors.phone),
      handleBlur: handleBlur,
      handleChange: _handleChange
    }))), ___EmotionJSX("div", {
      className: "vk-profile-add-member-radio-section"
    }, ___EmotionJSX(CustomCheckBox, {
      checkForm: true,
      customStyle: "vk-register-checkbox",
      name: "ageCompliant",
      label: "I am 18 years of age or older *",
      checked: values.ageCompliant,
      value: values.ageCompliant,
      onCheckboxChange: function onCheckboxChange(e) {
        handleBlur(e);
        _handleChange(e);
      }
    }), ___EmotionJSX("p", {
      className: "vk-error-text"
    }, touched.ageCompliant ? errors.ageCompliant : '', "\xA0"), ___EmotionJSX(CustomCheckBox, {
      checkForm: true,
      customStyle: "vk-register-checkbox",
      name: "imaEnabled",
      label: "I want to become a registered IMA *",
      checked: values.imaEnabled,
      onCheckboxChange: function onCheckboxChange(e) {
        handleBlur(e);
        _handleChange(e);
      }
    }), ___EmotionJSX("p", {
      className: "vk-error-text"
    }, touched.imaEnabled ? errors.imaEnabled : '', "\xA0"), ___EmotionJSX(CustomCheckBox, {
      customStyle: "vk-register-checkbox",
      name: "newsletterEnabled",
      label: "Send me updates, special offers and training by email",
      checked: values.newsletterEnabled,
      checkForm: true,
      onCheckboxChange: function onCheckboxChange(e) {
        handleBlur(e);
        _handleChange(e);
      }
    })), ___EmotionJSX("div", {
      className: "kt-login-containerTerms"
    }, ___EmotionJSX("p", null, "By clicking the Sign up button you agree to the \xA0", ___EmotionJSX(Link, {
      to: "/terms",
      className: "vk-link"
    }, "Terms & Conditions"), "\xA0and\xA0", ___EmotionJSX(Link, {
      to: "/privacy-policy",
      className: "vk-link"
    }, "Privacy Policy"))), ___EmotionJSX("div", {
      className: "kt-login__actions"
    }, ___EmotionJSX(Button, {
      btnType: "vk-outline-btn",
      btnSize: "vk-btn-size-l",
      clicked: goBack
    }, "Go back"), ___EmotionJSX(Button, {
      btnType: "vk-primary-btn",
      btnSize: "vk-btn-size-l",
      type: "submit",
      isSubmitting: isSubmitting,
      clicked: handleSubmit
    }, "Sign up")));
  })));
};
FirstStepRegistration.propTypes = {
  onSubmit: PropTypes.func,
  goBack: PropTypes.func
};
export default FirstStepRegistration;