export var dataNewMembers = [{
  number: '67 460',
  subtitle: 'From Asia including Oceania'
}, {
  number: '22 372',
  subtitle: 'From Europe'
}, {
  number: '8 803',
  subtitle: 'From CIS'
}, {
  number: '1 089',
  subtitle: 'North American  & Carribean'
}, {
  number: '13 689',
  subtitle: 'From Latin America'
}, {
  number: '7 101',
  subtitle: 'From Africa & Middle East'
}, {
  number: '7 485',
  subtitle: 'From Indian Sub-Content'
}, {
  number: '7 101',
  subtitle: 'from Greater China'
}];