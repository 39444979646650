function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _isNil from "lodash/isNil";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SearchInput from '../../../../components/UI/Input/SearchInput';
import Button from '../../../../components/UI/Button/Button';
import ReactSelect from '../../../../components/UI/ReactSelect';
import countryList from '../../../../enums/countries';
import { optionsRank, optionsStatus, matchingOptions, eagleRankOptions, eagleRankStars } from './definitions';
import { jsx as ___EmotionJSX } from "@emotion/react";
var countryListOptions = countryList.map(function (el) {
  return _objectSpread(_objectSpread({}, el), {}, {
    label: ___EmotionJSX("div", {
      className: "country-label-container"
    }, ___EmotionJSX("img", {
      src: el.image,
      alt: "test"
    }), ___EmotionJSX("span", null, el.value)),
    value: el.value
  });
});
var initialFilters = {
  profileUserName: '',
  profileCountry: null,
  status: null,
  currentPackageName: null,
  leadershipRankId: null,
  networkDepth: '',
  matchingLevel: null,
  profileEagleRankId: null,
  profileEagleRankStars: null
};
var TablesFilterBar = function TablesFilterBar(_ref) {
  var _selectedFilters$prof, _selectedFilters$prof2, _selectedFilters$stat, _selectedFilters$curr, _selectedFilters$lead, _selectedFilters$netw, _selectedFilters$matc, _selectedFilters$prof3, _selectedFilters$prof4;
  var getData = _ref.getData,
    packageOptions = _ref.packageOptions;
  var _useState = useState(initialFilters),
    _useState2 = _slicedToArray(_useState, 2),
    selectedFilters = _useState2[0],
    setSelectedFilters = _useState2[1];
  var usernameRef = useRef();
  var networkRef = useRef();
  var handleClear = function handleClear() {
    setSelectedFilters(null);
    usernameRef.current.resetValue();
    networkRef.current.resetValue();
    getData(initialFilters);
  };
  return ___EmotionJSX("div", {
    className: "community-tables-filter-bar__container"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    lg: 4,
    md: 4,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(SearchInput, {
    ref: usernameRef,
    noDebounce: true,
    value: (_selectedFilters$prof = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.profileUserName) !== null && _selectedFilters$prof !== void 0 ? _selectedFilters$prof : '',
    placeholder: "Search by username...",
    handleChange: function handleChange(profileUserName) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          profileUserName: profileUserName
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 2,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(ReactSelect, {
    isSearchable: true,
    placeholder: "Country",
    value: (_selectedFilters$prof2 = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.profileCountry) !== null && _selectedFilters$prof2 !== void 0 ? _selectedFilters$prof2 : null,
    options: countryListOptions,
    onChange: function onChange(profileCountry) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          profileCountry: profileCountry
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 2,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(ReactSelect, {
    placeholder: "Status",
    value: (_selectedFilters$stat = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.status) !== null && _selectedFilters$stat !== void 0 ? _selectedFilters$stat : null,
    options: optionsStatus,
    onChange: function onChange(status) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          status: status
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 2,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(ReactSelect, {
    isSearchable: true,
    placeholder: "Modules",
    value: (_selectedFilters$curr = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.currentPackageName) !== null && _selectedFilters$curr !== void 0 ? _selectedFilters$curr : null,
    options: packageOptions,
    onChange: function onChange(currentPackageName) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          currentPackageName: currentPackageName
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 2,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(ReactSelect, {
    placeholder: "Rank",
    value: (_selectedFilters$lead = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.leadershipRankId) !== null && _selectedFilters$lead !== void 0 ? _selectedFilters$lead : null,
    options: optionsRank,
    onChange: function onChange(leadershipRankId) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          leadershipRankId: leadershipRankId
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 1,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX("h4", null, "Tree Levels:")), ___EmotionJSX(Grid, {
    item: true,
    lg: 3,
    md: 4,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(SearchInput, {
    ref: networkRef,
    noDebounce: true,
    noMinLength: true,
    type: "number",
    value: (_selectedFilters$netw = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.networkDepth) !== null && _selectedFilters$netw !== void 0 ? _selectedFilters$netw : '',
    placeholder: "Search by network level...",
    handleChange: function handleChange(networkDepth) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          networkDepth: networkDepth
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 2,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(ReactSelect, {
    placeholder: "Matching",
    value: (_selectedFilters$matc = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.matchingLevel) !== null && _selectedFilters$matc !== void 0 ? _selectedFilters$matc : null,
    options: matchingOptions,
    onChange: function onChange(matchingLevel) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          matchingLevel: matchingLevel
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 2,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(ReactSelect, {
    placeholder: "Eagle Rank",
    value: (_selectedFilters$prof3 = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.profileEagleRankId) !== null && _selectedFilters$prof3 !== void 0 ? _selectedFilters$prof3 : null,
    options: eagleRankOptions,
    onChange: function onChange(profileEagleRankId) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          profileEagleRankId: profileEagleRankId
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 2,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(ReactSelect, {
    placeholder: "Eagle Rank Stars",
    value: (_selectedFilters$prof4 = selectedFilters === null || selectedFilters === void 0 ? void 0 : selectedFilters.profileEagleRankStars) !== null && _selectedFilters$prof4 !== void 0 ? _selectedFilters$prof4 : null,
    options: eagleRankStars,
    onChange: function onChange(profileEagleRankStars) {
      return setSelectedFilters(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, {
          profileEagleRankStars: profileEagleRankStars
        });
      });
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    lg: 1,
    md: 2,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-m",
    disabled: selectedFilters === initialFilters || _isNil(selectedFilters),
    clicked: function clicked() {
      return getData(selectedFilters);
    }
  }, "Filter")), ___EmotionJSX(Grid, {
    item: true,
    lg: 1,
    md: true,
    sm: true,
    xs: true
  }, ___EmotionJSX("span", {
    role: "button",
    tabIndex: "0",
    className: "clear-filters-button",
    onClick: handleClear
  }, "Clear"))));
};
TablesFilterBar.propTypes = {
  tableOptions: PropTypes.object,
  getData: PropTypes.func,
  exposeData: PropTypes.func,
  packageOptions: PropTypes.array
};
export default TablesFilterBar;