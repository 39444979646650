function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _startCase from "lodash/startCase";
import _isNil from "lodash/isNil";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { displayDate, defaultDate } from '../../../utils/moment';
import { transactionType, transactionStatus, walletType } from '../../../enums/wallets';
import currencyByType from '../../../utils/currencyByType';
import ColorBadge from '../../../components/UI/Shared/ColorBadge';
import TransactionHash from './TransactionHash';
import ReceiverInfo from './ReceiverInfo';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var randomString = function randomString() {
  return Math.random() >= 0.3 ? Array(40).fill().map(function () {
    return Math.random().toString(36).slice(2, Math.round(2 + Math.random() * 4));
  }).join(' ').slice(0, 60) : '';
};
export var amount = function amount(key) {
  return function (row, NAItem) {
    var amount = row[key];
    var isNegative = row.type === transactionType.Outbound;
    return String(amount).length ? ___EmotionJSX("b", {
      className: clsx(isNegative ? 'table-data__cell--red' : 'table-data__cell--green')
    }, isNegative ? "-".concat(amount) : "+".concat(amount)) : NAItem;
  };
};
var renderCharityAmount = function renderCharityAmount(data) {
  var type = data.type,
    amount = data.amount;
  return ___EmotionJSX("p", {
    className: "vk-latest-trans-box-item-amount-wraper\n  ".concat(type === transactionType.Outbound && 'vk-latest-trans-box-item-amount-wraper--red')
  }, type === transactionType.Inbound ? '+ ' : '- ', currencyByType(amount.toFixed(2), walletType.CASH));
};
export var renderDescription = function renderDescription(row) {
  var _row$description;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", null, !_isNil(row.externalTopupHash) && ___EmotionJSX("span", null, ___EmotionJSX("strong", null, "CP Hash: "), " ", row.externalTopupHash)), ___EmotionJSX("div", null, !_isNil(row.userReason) && ___EmotionJSX("span", null, ___EmotionJSX("b", null, "Reason: "), " ", row.userReason)), ((_row$description = row.description) === null || _row$description === void 0 ? void 0 : _row$description.length) > 0 && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("strong", null, "Description log: "), row.description.map(function (el) {
    var _displayDate$split, _displayDate;
    var date = (_displayDate$split = (_displayDate = displayDate(el.createdOn, 'DD/MM/YYYY-HH:mm:ss')) === null || _displayDate === void 0 ? void 0 : _displayDate.split('-')) !== null && _displayDate$split !== void 0 ? _displayDate$split : ['N/A', ''];
    return ___EmotionJSX("div", {
      key: el.createdOn,
      className: "vk-transaction-description-wrapper"
    }, ___EmotionJSX("span", {
      className: "date"
    }, date[0], " ", date[1]), ___EmotionJSX("div", {
      className: "transaction-descriptions-reasons"
    }, ___EmotionJSX("span", null, el.description)));
  })));
};
export var boldText = function boldText(key) {
  return function (row, NAItem) {
    return String(row[key]).length ? ___EmotionJSX("b", null, row[key]) : NAItem;
  };
};
export var descriptionWithStatus = function descriptionWithStatus(row, NAItem) {
  var _statusMap, _statusMap$completion, _statusMap$completion2;
  var statusMap = (_statusMap = {}, _defineProperty(_statusMap, transactionStatus.Pending, {
    text: 'Pending',
    color: 'blue'
  }), _defineProperty(_statusMap, transactionStatus.Completed, {
    text: 'Completed',
    color: 'green'
  }), _defineProperty(_statusMap, transactionStatus.Rejected, {
    text: 'Rejected',
    color: 'red'
  }), _defineProperty(_statusMap, transactionStatus.Blocked, {
    text: 'Blocked',
    color: 'red'
  }), _statusMap);
  var completionStatus = row.completionStatus,
    description = row.description,
    userReason = row.userReason,
    externalTopupHash = row.externalTopupHash;
  return description !== null && description !== void 0 && description.length || userReason || externalTopupHash ? ___EmotionJSX("div", {
    className: "description-styles"
  }, ___EmotionJSX("div", {
    className: "vk-description-column-badge"
  }, ___EmotionJSX(ColorBadge, {
    type: (_statusMap$completion = statusMap[completionStatus]) === null || _statusMap$completion === void 0 ? void 0 : _statusMap$completion.color
  }, (_statusMap$completion2 = statusMap[completionStatus]) === null || _statusMap$completion2 === void 0 ? void 0 : _statusMap$completion2.text)), ___EmotionJSX("div", {
    className: "vk-description-column-info"
  }, renderDescription(row))) : NAItem;
};
export var dateTime = function dateTime(key) {
  return function (props) {
    var NAItem = props.NAItem;
    if (props[key] === defaultDate) return NAItem;
    var transactionDate = moment.utc(props[key]);
    var dateNow = moment.utc();
    var difference = moment.duration(transactionDate.diff(dateNow));
    return ___EmotionJSX("div", {
      className: "vk-crypto-date-time-col-format"
    }, ___EmotionJSX("span", null, "".concat(transactionDate.local().format('DD/MM/YYYY, HH:mm:ss'))), ___EmotionJSX("span", {
      className: "vk-crypto-date-time-min-ago"
    }, difference.humanize(true, {
      d: 7,
      w: 4
    })));
  };
};
export var randomStatus = function randomStatus(items) {
  return items[Math.floor(Math.random() * items.length)];
};
export var getColumnsDefinition = function getColumnsDefinition(currentWalletType) {
  var withReceiverColumn = [walletType.COIN, walletType.COINLEGACY, walletType.OESP, walletType.CASH, walletType.RESERVECASH];
  var withHashColumn = [walletType.COIN, walletType.COINLEGACY, walletType.OESP];
  var hasReceiver = withReceiverColumn.includes(currentWalletType);
  var hasHash = withHashColumn.includes(currentWalletType);
  var columns = [{
    name: 'Date',
    value: 'createdOn',
    sortable: true,
    dateTimeFormat: 'DD/MM/YYYY',
    width: '100px',
    visible: true
  }, {
    name: 'Description',
    render: descriptionWithStatus,
    visible: true
  }, {
    name: 'System reason',
    render: function render(row) {
      return _startCase(row.reason);
    },
    width: '120px',
    visible: true
  }, {
    name: 'Receiver',
    render: function render(row) {
      return ___EmotionJSX(ReceiverInfo, {
        info: row
      });
    },
    width: '100px',
    visible: hasReceiver
  }, {
    name: 'Amount',
    width: hasHash ? '100px' : '120px',
    render: amount('amount'),
    visible: true
  }, {
    name: 'Hash',
    render: function render(_ref) {
      var transactionHash = _ref.transactionHash,
        NAItem = _ref.NAItem;
      return !_isNil(transactionHash) ? ___EmotionJSX(TransactionHash, {
        transactionHash: transactionHash
      }) : NAItem;
    },
    width: '150px',
    visible: hasHash
  }, {
    name: 'Balance',
    width: '120px',
    render: boldText('newBalance'),
    visible: true
  }];
  return columns.filter(function (col) {
    return col.visible;
  });
};
export var getColumnsCharity = function getColumnsCharity() {
  var columns = [{
    name: 'Date',
    value: 'createdOn',
    sortable: true,
    dateTimeFormat: 'DD/MM/YYYY',
    width: '100px',
    visible: true
  }, {
    name: 'Name',
    sortable: true,
    visible: true,
    render: function render(row) {
      var _row$sender;
      return row !== null && row !== void 0 && row.sender ? row === null || row === void 0 ? void 0 : (_row$sender = row.sender) === null || _row$sender === void 0 ? void 0 : _row$sender.profileName : '-';
    }
  }, {
    name: 'Nickname',
    sortable: true,
    visible: true,
    render: function render(row) {
      var _row$sender2;
      return row !== null && row !== void 0 && row.sender ? row === null || row === void 0 ? void 0 : (_row$sender2 = row.sender) === null || _row$sender2 === void 0 ? void 0 : _row$sender2.accountNickName : '-';
    }
  }, {
    name: 'Reason',
    value: 'userReason',
    visible: true
  }, {
    name: 'Amount',
    width: '120px',
    visible: true,
    render: function render(row) {
      return renderCharityAmount(row);
    }
  }];
  return columns.filter(function (col) {
    return col.visible;
  });
};