import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TextAndImage = function TextAndImage(props) {
  var row = props.row;
  return ___EmotionJSX("div", {
    className: "vk-ima-tools-container"
  }, ___EmotionJSX("div", {
    className: "vk-ima-tools-image"
  }, row.image), ___EmotionJSX("span", {
    className: "vk-ima-tools-title"
  }, row.title));
};
TextAndImage.propType = {
  row: PropTypes.object
};
export default TextAndImage;