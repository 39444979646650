import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { softLogout } from '@oneecosystem/authenticate';
import * as routerHelpers from '../../router/RouterHelpers';
import PageSpinner from '../../components/UI/Loader/PageSpinner';
import { userActions, packageActions, shoppingActions, accountActions, bonusesActions } from '../../store';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Logout = function Logout() {
  var dispatch = useDispatch();
  useEffect(function () {
    routerHelpers.forgotLastLocation();
    dispatch(accountActions.deleteAccounts());
    dispatch(bonusesActions.deleteBonuses());
    dispatch(shoppingActions.deleteShoppingCart());
    dispatch(packageActions.deletePackages());
    dispatch(userActions.deleteUser());
    softLogout();
  }, []);
  return ___EmotionJSX(PageSpinner, null);
};
export default Logout;