function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/UI/Button/Button';
import { pluralizeMonths } from '../../../utils/monthYearCalculator';
import { vendorsInformation } from '../vendors';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Subscription = function Subscription(props) {
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onAddToCart = props.onAddToCart,
    discountLabel = props.discountLabel,
    btnType = props.btnType,
    submitButtonDisabled = props.submitButtonDisabled,
    children = props.children,
    planIndex = props.planIndex;
  var getDurationLabel = function getDurationLabel() {
    if (item.duration === 1 || item.duration / 12 === 1) return pluralizeMonths(item.duration);
    return "".concat(item.duration % 12 === 0 ? item.duration / 12 : item.duration, " ").concat(pluralizeMonths(item.duration));
  };
  var planHasThumb = vendorsInformation[item.vendor] && vendorsInformation[item.vendor].plansThumbs && vendorsInformation[item.vendor].plansThumbs.length > 0;
  return ___EmotionJSX("div", {
    className: "subscription-data shop-subscription-card"
  }, !planHasThumb && ___EmotionJSX("div", null, discountLabel && discountLabel.length > 0 ? ___EmotionJSX("div", {
    className: "subscription-discount"
  }, ___EmotionJSX("span", null, discountLabel)) : ___EmotionJSX(React.Fragment, null), ___EmotionJSX("div", {
    className: "subscription-data-name"
  }, item.name)), ___EmotionJSX("div", null, planHasThumb && ___EmotionJSX("img", {
    src: vendorsInformation[item.vendor].plansThumbs[planIndex],
    className: "vk-image-responsive subscription-image",
    alt: "img-subscription-card"
  }), ___EmotionJSX("div", {
    className: "subscription-data-price"
  }, "\u20AC", item.price), ___EmotionJSX("div", {
    className: "subscription-data-duration"
  }, "/", getDurationLabel(item.duration)), !submitButtonDisabled && ___EmotionJSX(Button, {
    btnType: btnType,
    btnSize: "vk-btn-size-m",
    clicked: function clicked() {
      return onAddToCart(_objectSpread({}, item));
    }
  }, "Subscribe"), children));
};
Subscription.propTypes = {
  item: PropTypes.any,
  btnType: PropTypes.string,
  onAddToCart: PropTypes.func,
  discountLabel: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  children: PropTypes.any,
  planIndex: PropTypes.number
};
export default Subscription;