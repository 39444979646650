import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import { accountActions } from '../../../store';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NoWallets = function NoWallets(_ref) {
  var title = _ref.title,
    refreshCurrentWallets = _ref.refreshCurrentWallets;
  return ___EmotionJSX(Grid, {
    item: true
  }, title && ___EmotionJSX("div", {
    className: "vk-page-heading"
  }, title), ___EmotionJSX("p", {
    className: "vk-dashboard-wallets-headline"
  }, "It will take a while to set up your wallets, please wait a few minutes.", ___EmotionJSX("br", null), "Meanwhile, you can continue using the other awesome features of the platform."), ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    clicked: refreshCurrentWallets
  }, "Refresh"));
};
NoWallets.propTypes = {
  title: PropTypes.string,
  refreshCurrentWallets: PropTypes.func
};
export default connect(null, accountActions)(NoWallets);