import _isFunction from "lodash/isFunction";
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import useCountdown from '../../../components/UI/Shared/useCountdown';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TimerCountdown = function TimerCountdown(_ref) {
  var _timerCountdown$month, _timerCountdown$month2, _timerCountdown$days, _timerCountdown$days2, _timerCountdown$days3, _timerCountdown$days4, _timerCountdown$hours, _timerCountdown$hours2, _timerCountdown$min, _timerCountdown$min2;
  var expirationDate = _ref.expirationDate,
    onExpire = _ref.onExpire;
  var timerCountdown = useCountdown(expirationDate);
  var areMinutesLessThanZero = (timerCountdown === null || timerCountdown === void 0 ? void 0 : timerCountdown.min) < 0;
  useEffect(function () {
    if ((timerCountdown === null || timerCountdown === void 0 ? void 0 : timerCountdown.days) <= 0 && (timerCountdown === null || timerCountdown === void 0 ? void 0 : timerCountdown.hours) <= 0 && (timerCountdown === null || timerCountdown === void 0 ? void 0 : timerCountdown.min) <= 0) {
      timerCountdown.days = '00';
      timerCountdown.hours = '00';
      timerCountdown.min = '00';
      _isFunction(onExpire) && onExpire();
    }
  }, [areMinutesLessThanZero]);
  return ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    justify: "center"
  }, timerCountdown.months && ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$month = timerCountdown.months) === null || _timerCountdown$month === void 0 ? void 0 : _timerCountdown$month.charAt(0)), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$month2 = timerCountdown.months) === null || _timerCountdown$month2 === void 0 ? void 0 : _timerCountdown$month2.charAt(1))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-text-wrap"
  }, "MONTHS")), ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$days = timerCountdown.days) === null || _timerCountdown$days === void 0 ? void 0 : _timerCountdown$days.charAt(0)), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$days2 = timerCountdown.days) === null || _timerCountdown$days2 === void 0 ? void 0 : _timerCountdown$days2.charAt(1)), ((_timerCountdown$days3 = timerCountdown.days) === null || _timerCountdown$days3 === void 0 ? void 0 : _timerCountdown$days3.charAt(2)) && ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$days4 = timerCountdown.days) === null || _timerCountdown$days4 === void 0 ? void 0 : _timerCountdown$days4.charAt(2))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-text-wrap"
  }, "DAYS")), ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$hours = timerCountdown.hours) === null || _timerCountdown$hours === void 0 ? void 0 : _timerCountdown$hours.charAt(0)), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$hours2 = timerCountdown.hours) === null || _timerCountdown$hours2 === void 0 ? void 0 : _timerCountdown$hours2.charAt(1))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-text-wrap"
  }, "HRS")), ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$min = timerCountdown.min) === null || _timerCountdown$min === void 0 ? void 0 : _timerCountdown$min.charAt(0)), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-number"
  }, (_timerCountdown$min2 = timerCountdown.min) === null || _timerCountdown$min2 === void 0 ? void 0 : _timerCountdown$min2.charAt(1))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-timer-text-wrap"
  }, "MIN"))));
};
TimerCountdown.propTypes = {
  expirationDate: PropTypes.string,
  useCountdown: PropTypes.func,
  onExpire: PropTypes.func
};
export default TimerCountdown;