import "core-js/modules/es.array.join.js";
import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PortletWrapper = function PortletWrapper(props) {
  var pwStyle = props.pwStyle,
    children = props.children;
  return ___EmotionJSX("div", {
    className: ['vk-portlet-wrapper', pwStyle].join(' ')
  }, children);
};
export var PortletHeader = function PortletHeader(props) {
  var phStyle = props.phStyle,
    children = props.children;
  return ___EmotionJSX("div", {
    className: ['vk-portlet-header', phStyle].join(' ')
  }, children);
};
export var PortletBody = function PortletBody(props) {
  var pbStyle = props.pbStyle,
    children = props.children;
  return ___EmotionJSX("div", {
    className: ['vk-portlet-body', pbStyle].join(' ')
  }, children);
};
PortletWrapper.propTypes = {
  pwStyle: PropTypes.string,
  children: PropTypes.any
};
PortletHeader.propTypes = {
  phStyle: PropTypes.string,
  children: PropTypes.any
};
PortletBody.propTypes = {
  pbStyle: PropTypes.string,
  children: PropTypes.any
};