import React from 'react';
import RatingWidget from '../../../components/UI/Shared/RatingWidget';
import { profileRankInformation, profileRankId, profileRank } from '../../../enums/profile';
import { jsx as ___EmotionJSX } from "@emotion/react";
var EagleRankInformation = function EagleRankInformation(props) {
  var _profileInfo$eagleRan, _profileRankInformati;
  var profileInfo = props.profileInfo;
  var eagleRank = (_profileInfo$eagleRan = profileInfo === null || profileInfo === void 0 ? void 0 : profileInfo.eagleRankId) !== null && _profileInfo$eagleRan !== void 0 ? _profileInfo$eagleRan : 1;
  var eagleRankStars = profileInfo === null || profileInfo === void 0 ? void 0 : profileInfo.eagleRankStars;
  return ___EmotionJSX("div", {
    className: "recognition-wrapper-eagle-rank"
  }, ___EmotionJSX("div", {
    className: "recognition-current-eagle-rank"
  }, ___EmotionJSX("img", {
    src: (_profileRankInformati = profileRankInformation[profileRankId[eagleRank]]) === null || _profileRankInformati === void 0 ? void 0 : _profileRankInformati.rankImage,
    alt: "package",
    className: "recognition-current-eagle-wrap vk-mr-16"
  }), ___EmotionJSX("div", null, ___EmotionJSX("h2", {
    className: "recognition-current-eagle-title"
  }, "Current rank"), ___EmotionJSX("h2", {
    className: "recognition-name-eagle"
  }, profileRankId[eagleRank]), ___EmotionJSX(RatingWidget, {
    stars: profileRankId[eagleRank] === profileRank.LifetimeEagle ? 12 : 6,
    rated: eagleRankStars
  }))));
};
export default EagleRankInformation;