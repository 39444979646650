import _isNumber from "lodash/isNumber";
import React from 'react';
import PropTypes from 'prop-types';
import { sub, mul, div } from 'exact-math';
import { walletType } from '../../../enums/wallets';
import getCurrencyValue from '../../../utils/currencyByType';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ValuePercent = function ValuePercent(props) {
  var oldValue = props.oldValue,
    value = props.value,
    _props$currency = props.currency,
    currency = _props$currency === void 0 ? walletType.CASH : _props$currency,
    sm = props.sm;
  var percentage = _isNumber(value) && _isNumber(oldValue) && mul(Math.abs(div(sub(oldValue, value), oldValue)), 100);
  var positive = value >= oldValue;
  return ___EmotionJSX("div", {
    className: "vk-value-percent-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-pv-value ".concat(sm ? 'vk-vp-sm' : '')
  }, getCurrencyValue(value !== null && value !== void 0 ? value : 0, currency)), percentage && ___EmotionJSX("div", {
    className: "vk-pv-value-percent ".concat(positive ? 'positive' : 'negative')
  }, percentage, "%"));
};
ValuePercent.propTypes = {
  value: PropTypes.number,
  oldValue: PropTypes.number,
  sm: PropTypes.bool,
  currency: PropTypes.string
};
export default ValuePercent;