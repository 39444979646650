import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var RatingWidget = function RatingWidget(props) {
  var _props$stars = props.stars,
    stars = _props$stars === void 0 ? 5 : _props$stars,
    rated = props.rated,
    style = props.style;
  return ___EmotionJSX("div", {
    className: "vk-rating-star-container"
  }, Array(stars).fill().map(function (el, ind) {
    return ___EmotionJSX("div", {
      key: "rating-star-".concat(ind),
      className: "vk-rating-star",
      style: style
    }, ___EmotionJSX("i", {
      className: ind >= rated ? 'far fa-star' : 'fas fa-star'
    }));
  }));
};
export default RatingWidget;