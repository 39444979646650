import React from 'react';
import { Link } from 'react-router-dom';
import { jsx as ___EmotionJSX } from "@emotion/react";
var headerDarkLogo = 'https://onelife.blob.core.windows.net/images/one_ecosystem_logo_dark_v2.png';
// const headerLightLogo = 'https://onelife.blob.core.windows.net/images/one_ecosystem_logo_light_v2.png';

var HeaderMobile = function HeaderMobile(props) {
  var onHeaderMobileToggle = props.onHeaderMobileToggle,
    onAsideMobileToggle = props.onAsideMobileToggle;
  return ___EmotionJSX("div", {
    id: "kt_header_mobile",
    className: "kt-header-mobile"
  }, ___EmotionJSX("div", {
    className: "kt-header-mobile__logo"
  }, ___EmotionJSX(Link, {
    to: "/"
  }, ___EmotionJSX("img", {
    alt: "logo",
    src: headerDarkLogo
  }))), ___EmotionJSX("div", {
    className: "kt-header-mobile__toolbar"
  }, ___EmotionJSX("button", {
    className: "kt-header-mobile__toggler kt-header-mobile__toggler--left",
    id: "kt_aside_mobile_toggler",
    onClick: onAsideMobileToggle
  }, ___EmotionJSX("span", null)), ___EmotionJSX("button", {
    onClick: onHeaderMobileToggle,
    className: "kt-header-mobile__topbar-toggler",
    id: "kt_header_mobile_topbar_toggler"
  }, ___EmotionJSX("i", {
    className: "flaticon-more"
  }))));
};
export default HeaderMobile;