function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/images/layout-svg-icons/icn-close.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/layout-svg-icons/icn-alert-warning.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DashboardMessageInformation = function DashboardMessageInformation(_ref) {
  var text = _ref.text,
    show = _ref.show,
    link = _ref.link,
    canHide = _ref.canHide,
    _ref$condensed = _ref.condensed,
    condensed = _ref$condensed === void 0 ? false : _ref$condensed;
  var _useState = useState(show !== null && show !== void 0 ? show : true),
    _useState2 = _slicedToArray(_useState, 2),
    showMessage = _useState2[0],
    setShowMessage = _useState2[1];
  return showMessage && ___EmotionJSX("div", {
    className: "dashboard-message-information-container ".concat(condensed ? '' : 'with-margins')
  }, (canHide !== undefined ? canHide : true) && ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: "dashboard-message-information-close-btn",
    onClick: function onClick() {
      return setShowMessage(false);
    }
  }, ___EmotionJSX(CloseIcon, null)), ___EmotionJSX("div", {
    className: "dashboard-message-information-error-icn"
  }, ___EmotionJSX(ErrorIcon, null)), ___EmotionJSX("div", {
    className: "dashboard-message-texts"
  }, ___EmotionJSX("div", {
    className: "dashboard-message-information-text"
  }, text), link && ___EmotionJSX("a", {
    className: "dashboard-message-link",
    target: "_blanc",
    href: link
  }, "here")));
};
export default DashboardMessageInformation;