import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { PortletWrapper, PortletHeader, PortletBody } from '../../../components/UI/Portlet/Portlet';
import ValuePercent from '../../../components/UI/Shared/ValuePercent';
import displayCurrencyByType from '../../../utils/currencyByType';
import { walletType } from '../../../enums';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MatchingCurrentBonus = function MatchingCurrentBonus(props) {
  var _bonuses$current, _currentBonus$totalBv;
  var bonuses = props.bonuses,
    hasFirstLevel = props.hasFirstLevel,
    hasSecondLevel = props.hasSecondLevel,
    hasThirdLevel = props.hasThirdLevel,
    hasForthLevel = props.hasForthLevel;
  var hasBonuses = !(bonuses !== null && bonuses !== void 0 && bonuses.isEmpty);
  var currentBonus = bonuses === null || bonuses === void 0 ? void 0 : (_bonuses$current = bonuses.current) === null || _bonuses$current === void 0 ? void 0 : _bonuses$current.matchingBonus;
  return ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-wrap"
  }, ___EmotionJSX(PortletWrapper, {
    pwStyle: "vk-dashboard-bonuses"
  }, ___EmotionJSX(PortletHeader, null, ___EmotionJSX(Grid, {
    container: true,
    alignItems: "flex-end"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-header-left-top-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading-secondary-light"
  }, "Current Matching Bonus"))), hasBonuses && ___EmotionJSX(Grid, {
    container: true,
    justify: "space-between",
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX(ValuePercent, {
    value: (_currentBonus$totalBv = currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.totalBv) !== null && _currentBonus$totalBv !== void 0 ? _currentBonus$totalBv : 0
  }))))), ___EmotionJSX(PortletBody, null, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bonuses-body-left-list-item-label"
  }, "1st Gen")), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-value-percent-wrap-bonus-cap"
  }, hasFirstLevel ? displayCurrencyByType(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.firstLevelBv, walletType.BV) : 'N/A')))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bonuses-body-left-list-item-label"
  }, "2nd Gen")), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-value-percent-wrap-bonus-cap"
  }, hasSecondLevel ? displayCurrencyByType(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.secondLevelBv, walletType.BV) : 'N/A')))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bonuses-body-left-list-item-label"
  }, "3rd Gen")), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-value-percent-wrap-bonus-cap"
  }, hasThirdLevel ? displayCurrencyByType(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.thirdLevelBv, walletType.BV) : 'N/A')))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bonuses-body-left-list-item-label"
  }, "4th Gen")), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-value-percent-wrap-bonus-cap"
  }, hasForthLevel ? displayCurrencyByType(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.fourthLevelBv, walletType.BV) : 'N/A')))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bonuses-body-left-list-item-label"
  }, "Total Bonus")), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX(ValuePercent, {
    value: currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.amount,
    sm: true
  })))), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bonuses-body-left-list-item-label"
  }, "Bonus to be paid")), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX(ValuePercent, {
    value: Math.min(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.amount, currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.bonusCap),
    sm: true
  }))))))))))));
};
MatchingCurrentBonus.propTypes = {
  bonuses: PropTypes.object,
  hasFirstLevel: PropTypes.bool,
  hasSecondLevel: PropTypes.bool,
  hasThirdLevel: PropTypes.bool,
  hasForthLevel: PropTypes.bool
};
export default connect(function (_ref) {
  var bonuses = _ref.bonuses;
  return {
    bonuses: bonuses
  };
})(MatchingCurrentBonus);