import "core-js/modules/es.date.to-string.js";
import moment from 'moment';
export var defaultDate = '0001-01-01T00:00:00';
export var validDate = function validDate(date) {
  return date === defaultDate ? null : date;
};
export var displayDate = function displayDate(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD/MM/YYYY';
  return validDate(date) && moment(date).format(format);
};
export var startOfTheDay = function startOfTheDay(inputDate) {
  return inputDate && moment(inputDate).utc().hours(0).minutes(0).seconds(0);
};
export var endOfTheDay = function endOfTheDay(inputDate) {
  return inputDate && moment(inputDate).utc().hours(23).minutes(59).seconds(59);
};
export var compareWithNow = function compareWithNow(inputDate) {
  var dateNow = moment.utc();
  var deadlineDate = moment.utc(inputDate);
  return deadlineDate.diff(dateNow, 'minutes');
};
export var addDays = function addDays(daysNum) {
  var startString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return startString ? moment(startString).add(daysNum, 'd') : moment().add(daysNum, 'd');
};
export var getNextMondayString = function getNextMondayString() {
  var dateNow = new Date();
  var nextMonday = new Date();
  nextMonday.setUTCHours(14, 0);

  // If today is not monday before 14:00
  if (dateNow.getUTCDay() !== 1 || dateNow.getUTCHours() >= 14) nextMonday.setUTCDate(nextMonday.getUTCDate() + (7 - nextMonday.getUTCDay() + 1));
  return nextMonday.toUTCString();
};
var getFirstWeekStartDate = function getFirstWeekStartDate(year) {
  var startOfTheFirstWeek = moment.utc("01/01/".concat(year));

  // If the first day of the year is not monday
  if (startOfTheFirstWeek.day() !== 1) {
    if (startOfTheFirstWeek.day() === 0) startOfTheFirstWeek.add(1, 'd');else if (startOfTheFirstWeek.day() > 3) startOfTheFirstWeek.add(7 - startOfTheFirstWeek.day() + 1, 'd');else startOfTheFirstWeek.subtract(startOfTheFirstWeek.day() - 1, 'd');
  }
  return startOfTheFirstWeek;
};
export var getWeek = function getWeek(date) {
  var dateNow = moment.utc(date || new Date());
  var startOfTheFirstWeek = getFirstWeekStartDate(dateNow.year());
  var days = Math.floor(dateNow - startOfTheFirstWeek) / (24 * 60 * 60 * 1000);
  var currentWeek = Math.ceil(days / 7);
  return currentWeek;
};
export var getDatesByTargetWeek = function getDatesByTargetWeek(weekNumber, year) {
  var startOfTheFirstWeek = getFirstWeekStartDate(year);
  var startOfTheWeek = moment.utc(startOfTheFirstWeek).add(weekNumber - 1, 'w');
  var endOfTheWeek = moment.utc(startOfTheWeek).add(6, 'd');
  return {
    startOfTheWeek: startOfTheWeek,
    endOfTheWeek: endOfTheWeek
  };
};