import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import LinkButton from '../../Button/LinkButton';
import { ReactComponent as CloseIcn } from '../../../../assets/images/layout-svg-icons/icn-close.svg';
import { ReactComponent as PaymentMethodIcn } from '../../../../assets/images/layout-svg-icons/payment-methods.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NoAvailablePaymentMethodModal = function NoAvailablePaymentMethodModal(_ref) {
  var show = _ref.show;
  var history = useHistory();
  var handleCloseModal = function handleCloseModal() {
    history.push('/shop/cart');
  };
  return ___EmotionJSX(Modal, {
    className: "no-available-payment-methods-modal",
    open: show,
    onClose: handleCloseModal,
    closeAfterTransition: true,
    BackdropComponent: Backdrop,
    BackdropProps: {
      timeout: 500
    }
  }, ___EmotionJSX(Fade, {
    "in": show
  }, ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "no-available-payment-methods-background"
  }), ___EmotionJSX("div", {
    className: "no-available-payment-methods-modal-body"
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: "no-available-payment-methods-modal-close-btn",
    onClick: handleCloseModal
  }, ___EmotionJSX(CloseIcn, null)), ___EmotionJSX(PaymentMethodIcn, {
    className: "no-available-payment-methods-modal-icon"
  }), ___EmotionJSX("h2", {
    className: "no-available-payment-methods-modal-warning"
  }, "Warning & Fail!"), ___EmotionJSX("p", {
    className: "vk-mb-24"
  }, "You don`t have available Payment Methods. Back to Shopping cart and remove any package."), ___EmotionJSX("div", {
    className: "close-withdrawal-modal-body-actions"
  }, ___EmotionJSX(LinkButton, {
    to: "/shop/cart",
    linkType: true
  }, "Cancel"))))));
};
NoAvailablePaymentMethodModal.propTypes = {
  show: PropTypes.bool
};
export default NoAvailablePaymentMethodModal;