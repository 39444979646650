import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Pagination } from '../Table';
import ReactSelect from '../ReactSelect';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TableFooterBar = function TableFooterBar() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    value = _ref.value,
    _ref$hasPageSize = _ref.hasPageSize,
    hasPageSize = _ref$hasPageSize === void 0 ? true : _ref$hasPageSize;
  return function (props) {
    var _options$find;
    var getData = props.getData,
      tableOptions = props.tableOptions;
    var options = [5, 10, 15].map(function (el) {
      return {
        label: String(el),
        value: el
      };
    });
    var handlePageChange = function handlePageChange(_ref2) {
      var value = _ref2.value;
      return getData({
        pageSize: value
      });
    };
    return ___EmotionJSX("div", {
      className: clsx('wallet-table-footer', !hasPageSize && 'vk-justify-end')
    }, hasPageSize && ___EmotionJSX(ReactSelect, {
      isDisabled: !tableOptions.current.totalResults,
      options: options,
      defaultValue: value ? (_options$find = options.find(function (el) {
        return el.value === value;
      })) !== null && _options$find !== void 0 ? _options$find : options[1] : options[1],
      onChange: handlePageChange,
      isSearchable: false
    }), ___EmotionJSX(Pagination, props));
  };
};
TableFooterBar.propTypes = {
  getData: PropTypes.func,
  tableOptions: PropTypes.object,
  hasPageSize: PropTypes.bool
};
export default TableFooterBar;