function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import starterLogo from '../../assets/images/bonuses/starter.png';
export var subscriptionActionTypes = {
  Fulfill: '[Fulfill] Initialize subscriptions',
  DeleteAll: '[Delete All] Erase subscriptions',
  Add: '[Add] Add subscription'
};
var initialState = {
  items: []
};
export var subscriptionReducer = persistReducer({
  storage: storage,
  key: 'subscriptions'
}, function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case subscriptionActionTypes.Fulfill:
      {
        var _action$payload$items = action.payload.items,
          items = _action$payload$items === void 0 ? [] : _action$payload$items;
        var all = items.map(function (el) {
          var _ref, _el$picThumbnailUrl;
          return _objectSpread(_objectSpread({}, el), {}, {
            logo: (_ref = (_el$picThumbnailUrl = el.picThumbnailUrl) !== null && _el$picThumbnailUrl !== void 0 ? _el$picThumbnailUrl : el.pictureUrl) !== null && _ref !== void 0 ? _ref : starterLogo
          });
        });
        return {
          items: all
        };
      }
    case subscriptionActionTypes.Add:
      {
        var _ref2, _item$picThumbnailUrl;
        var _action$payload$item = action.payload.item,
          item = _action$payload$item === void 0 ? {} : _action$payload$item;
        var newSubscription = _objectSpread(_objectSpread({}, item), {}, {
          logo: (_ref2 = (_item$picThumbnailUrl = item.picThumbnailUrl) !== null && _item$picThumbnailUrl !== void 0 ? _item$picThumbnailUrl : item.pictureUrl) !== null && _ref2 !== void 0 ? _ref2 : starterLogo
        });
        var _items = state.items.concat(newSubscription);
        return {
          items: _items
        };
      }
    case subscriptionActionTypes.DeleteAll:
      {
        return initialState;
      }
    default:
      return state;
  }
});
export var subscriptionActions = {
  fulfillSubscriptions: function fulfillSubscriptions(items) {
    return {
      type: subscriptionActionTypes.Fulfill,
      payload: {
        items: items
      }
    };
  },
  deleteSubscriptions: function deleteSubscriptions() {
    return {
      type: subscriptionActionTypes.DeleteAll
    };
  },
  addSubscription: function addSubscription(item) {
    return {
      type: subscriptionActionTypes.Add,
      payload: {
        item: item
      }
    };
  }
};