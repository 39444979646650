import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var videoData = [{
  id: 59,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S2 E5 (04.2024)',
  viewUrl: 'https://youtu.be/TOd6Bmz64ns'
}, {
  id: 58,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'THE FUTURE STARTS TODAY 2024, MALAYSIA, DAY 2',
  viewUrl: 'https://youtu.be/IdtZh1N_nNg'
}, {
  id: 57,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'THE FUTURE STARTS TODAY - DAY 3 - DS TRAINING AND ONE VITA WORKSHOP',
  viewUrl: 'https://youtu.be/8gTGWBXL5DY'
}, {
  id: 56,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'THE FUTURE STARTS TODAY 2024, DAY 1 - OPENING CEREMONY',
  viewUrl: 'https://youtu.be/NO33ET05S2o'
}, {
  id: 55,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'THE FUTURE STARTS TODAY 2024, MALAYSIA',
  viewUrl: 'https://youtu.be/yLx7KCqjVOY'
}, {
  id: 54,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S2 E4 (03.2024)',
  viewUrl: 'https://youtu.be/zHIsZvfQ0mc'
}, {
  id: 53,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM SPECIAL EDITION - DEALSHAKER - (02.2024)',
  viewUrl: 'https://youtu.be/2LXrt7cVGlc'
}, {
  id: 52,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S2 E3 (02.2024)',
  viewUrl: 'https://youtu.be/Vt05zYzxLIk'
}, {
  id: 51,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'THE FUTURE STARTS TODAY WORLD CONGRESS PROMO VIDEO',
  viewUrl: 'https://youtu.be/YzSx64HGwvQ'
}, {
  id: 50,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'BLOCKCHAIN VENTSISLAV ZLATKOV SPEECH (2023)',
  viewUrl: 'https://youtu.be/5Tg496gS7Gc'
}, {
  id: 49,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S2 E2 (01.2024)',
  viewUrl: 'https://youtu.be/NV-2dg36KQw'
}, {
  id: 48,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM CEO CHRISTMAS GREETING 2023 ENG',
  viewUrl: 'https://youtu.be/yMQ4UceWcTE'
}, {
  id: 47,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OE HEADQUARTERS GRAND OPENING (2023)',
  viewUrl: 'https://youtu.be/YOIsRKj1eAE'
}, {
  id: 46,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM - Exclusive Birthday Episode (11.2023)',
  viewUrl: 'https://youtu.be/KH0-gV_K-sk'
}, {
  id: 45,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM ASIA TOUR VIDEO 2 (2023)',
  viewUrl: 'https://youtu.be/JCz10ynglak'
}, {
  id: 44,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM ASIA TOUR (2023)',
  viewUrl: 'https://youtu.be/gM7KXSZ_zVo'
}, {
  id: 43,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S1 E8',
  viewUrl: 'https://youtu.be/OzcKiT6xdn0'
}, {
  id: 42,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OE SYSTEM OF NETWORKING Promo',
  viewUrl: 'https://youtu.be/CZKgiFvYyyw'
}, {
  id: 41,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S1 E7 (10.2023)',
  viewUrl: 'https://youtu.be/NxaZKjdMIV8'
}, {
  id: 40,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'DS CONVERSION POOL - CEO ANNOUNCEMENT (2023)',
  viewUrl: 'https://youtu.be/w-Gh03YYYow'
}, {
  id: 39,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S1 E6 (09.2023)',
  viewUrl: 'https://youtu.be/AvJlQ9QEdJ8'
}, {
  id: 38,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S1 E5 (08.2023)',
  viewUrl: 'https://youtu.be/9NVGzxFYsn4'
}, {
  id: 37,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S1 E4 (07.2023)',
  viewUrl: 'https://youtu.be/WFFy-erTnEg'
}, {
  id: 36,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'HOW TO TOP UP YOUR DIGITAL CASH WALLET',
  viewUrl: 'https://youtu.be/FQugTECYQ4g'
}, {
  id: 35,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'BLOCKCHAIN DEPLOYMENT PROCESS',
  viewUrl: 'https://youtu.be/V0qoWXbDAM8'
}, {
  id: 34,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S1 E3 (03.2023)',
  viewUrl: 'https://youtu.be/4MooaTEs0Xk'
}, {
  id: 33,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEXT LEVEL GLOBAL EVENT DAY 2 (2023)',
  viewUrl: 'https://youtu.be/o9Gi0LK_Rb0'
}, {
  id: 32,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEXT LEVEL GLOBAL EVENT DAY 1 (2023)',
  viewUrl: 'https://youtu.be/yNF3M0dy_Xc'
}, {
  id: 31,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM REFORM HELSINKI',
  viewUrl: 'https://youtu.be/qHnQuA3Dn_Q'
}, {
  id: 30,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE VOYAGE',
  viewUrl: 'https://youtu.be/j0tg0FowWp4'
}, {
  id: 29,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE VITA',
  viewUrl: 'https://youtu.be/FDcyHuyE4KU'
}, {
  id: 28,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'NEWSROOM S1 E2 (01.2023)',
  viewUrl: 'https://youtu.be/ocut-0dBsTc'
}, {
  id: 27,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM NEWSROOM S1 E1 (11.2022)',
  viewUrl: 'https://youtu.be/kkFT2ZvyCJg'
}, {
  id: 26,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'DIAMOND TRIP 2023 - VIETNAM',
  viewUrl: 'https://youtu.be/sR7A4h7ESbE'
}, {
  id: 25,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'REFORM - FINLAND EVENT (2022)',
  viewUrl: 'https://youtu.be/j8160Rx4X78'
}, {
  id: 24,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'THE ERA OF THE ONE (2022)',
  viewUrl: 'https://youtu.be/rzwIODCMtxo'
}, {
  id: 23,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'CRYPTO CENTER GRAND OPENING 2022',
  viewUrl: 'https://youtu.be/WsQT4nF_0RU'
}, {
  id: 22,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'STEP UP DIGITAL EVENT 2022',
  viewUrl: 'https://youtu.be/kYqU0uKbRuQ'
}, {
  id: 21,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM TEAM BUILDING GREECE 2022',
  viewUrl: 'https://youtu.be/cblp1ltd0B4'
}, {
  id: 20,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM TEAM BUILDING SUMMER 2022',
  viewUrl: 'https://youtu.be/s7Tpt8Tv7LQ'
}, {
  id: 19,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'CEO SPECIAL ANNOUNCEMENT 6TH JULY 2022',
  viewUrl: 'https://youtu.be/vtjgOZvQUkk'
}, {
  id: 18,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'WHITE PAPER FULL PRESENTATION (2022)',
  viewUrl: 'https://youtu.be/ALwev4EA7aY'
}, {
  id: 17,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'ONE ECOSYSTEM REVEALED HIGHLIGHTS (2022)',
  viewUrl: 'https://youtu.be/jXfNlqlbNYI'
}, {
  id: 16,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'BLOCKCHAIN TECHNOLOGY PRESENTATION (2022)',
  viewUrl: 'https://youtu.be/YoLU0HeHBT0'
}];