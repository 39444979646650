import "core-js/modules/es.array.map.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import defaultAvatarLogo from '../../../assets/images/icons/default-avatar.png';
import { Skeleton } from '../../../components/UI/Loader';
import { jsx as ___EmotionJSX } from "@emotion/react";
var renderCandidateData = function renderCandidateData(row) {
  var _row$candidatePicThum;
  return ___EmotionJSX("div", {
    className: "dashboard-leaders-candidate-name"
  }, ___EmotionJSX("div", {
    className: "dashboard-leaders-avatar"
  }, ___EmotionJSX("img", {
    alt: "Pic",
    src: (_row$candidatePicThum = row === null || row === void 0 ? void 0 : row.candidatePicThumbnailUrl) !== null && _row$candidatePicThum !== void 0 ? _row$candidatePicThum : defaultAvatarLogo
  })), ___EmotionJSX("div", null, row.candidateFirstName, " ", row.candidateLastName));
};
var DashboardLeadersSurveyResult = function DashboardLeadersSurveyResult(props) {
  var topCandidates = props.topCandidates;
  return ___EmotionJSX("div", {
    className: "dashboard-survey-results"
  }, ___EmotionJSX(Grid, {
    container: true,
    justify: "center"
  }, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    alignItems: "stretch",
    className: "dashboard-survey-results-header-row"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 2,
    sm: 1,
    md: 2,
    lg: 1
  }, ___EmotionJSX("div", null, "Rank")), ___EmotionJSX(Grid, {
    item: true,
    xs: 8,
    sm: 9,
    md: 8,
    lg: 9
  }, ___EmotionJSX("div", null, "Candidate Name")), ___EmotionJSX(Grid, {
    item: true,
    xs: 2
  }, ___EmotionJSX("div", null, "Votes")))), ___EmotionJSX(Grid, {
    container: true,
    justify: "center"
  }, topCandidates ? topCandidates.length > 0 ? topCandidates === null || topCandidates === void 0 ? void 0 : topCandidates.map(function (candidate, index) {
    return ___EmotionJSX(Grid, {
      container: true,
      item: true,
      alignItems: "center",
      className: "dashboard-survey-results-row",
      key: "leader-".concat(index)
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: 2,
      sm: 1,
      md: 2,
      lg: 1
    }, ___EmotionJSX("div", null, candidate.rank)), ___EmotionJSX(Grid, {
      item: true,
      xs: 8,
      sm: 9,
      md: 8,
      lg: 9
    }, renderCandidateData(candidate)), ___EmotionJSX(Grid, {
      item: true,
      xs: 2
    }, ___EmotionJSX("div", null, candidate.totalVotes)));
  }) : ___EmotionJSX("p", null, "There are no results for your region yet!") : ___EmotionJSX(Skeleton, {
    count: 3
  })));
};
export default DashboardLeadersSurveyResult;