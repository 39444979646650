function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { validationsRegexp } from '../../../utils/validations';
import Button from '../../../components/UI/Button/Button';
import SearchInput from '../../../components/UI/Input/SearchInput';
import ReactSelect from '../../../components/UI/ReactSelect';
import { optionsGiftCodeStatus, activationFeePackages } from './GiftCodeConfig';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MyGiftCodesFilterBar = function MyGiftCodesFilterBar(_ref) {
  var _optionsGiftCodeStatu;
  var getData = _ref.getData,
    allPackages = _ref.allPackages,
    onExport = _ref.onExport;
  var params = useRef({
    giftCodeStatus: (_optionsGiftCodeStatu = optionsGiftCodeStatus[1].value) !== null && _optionsGiftCodeStatu !== void 0 ? _optionsGiftCodeStatu : null,
    giftCode: null,
    packageName: null,
    withActivationFee: null
  });
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    isGiftCodeValid = _useState2[0],
    setIsGiftCodeValid = _useState2[1];
  var packagesOptions = [{
    value: null,
    label: 'Modules'
  }].concat(allPackages === null || allPackages === void 0 ? void 0 : allPackages.map(function (el) {
    return {
      label: el,
      value: el
    };
  }));
  var fetchResults = function fetchResults(options) {
    var isValid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : isGiftCodeValid;
    params.current = _objectSpread(_objectSpread({}, params.current), options);
    isValid && getData(params.current);
  };
  var handleChangeGiftCode = function handleChangeGiftCode(giftCode) {
    var isValid = (giftCode === null || giftCode === void 0 ? void 0 : giftCode.length) > 0 ? validationsRegexp.guid.test(giftCode) : true;
    setIsGiftCodeValid(isValid);
    fetchResults({
      giftCode: giftCode
    }, isValid);
  };
  return ___EmotionJSX("div", {
    className: "vk-shop-page-filters-giftCodes"
  }, ___EmotionJSX("div", {
    className: clsx('vk-shop-search-filter', !isGiftCodeValid && 'vk-shop-search-filter--error')
  }, ___EmotionJSX(SearchInput, {
    noMinLength: true,
    noDebounce: true,
    withClear: true,
    placeholder: "Search for Gift code...",
    handleChange: handleChangeGiftCode,
    onClear: handleChangeGiftCode
  }), !isGiftCodeValid && ___EmotionJSX("span", null, " The gift code is not valid!")), ___EmotionJSX("div", {
    className: "vk-shop-order-history-dropdown"
  }, ___EmotionJSX(ReactSelect, {
    isSearchable: true,
    options: packagesOptions,
    defaultValue: packagesOptions[0],
    onChange: function onChange(_ref2) {
      var packageName = _ref2.value;
      return fetchResults({
        packageName: packageName
      });
    }
  })), ___EmotionJSX("div", {
    className: "vk-shop-order-history-dropdown"
  }, ___EmotionJSX(ReactSelect, {
    options: activationFeePackages,
    defaultValue: activationFeePackages[0],
    onChange: function onChange(_ref3) {
      var withActivationFee = _ref3.value;
      return fetchResults({
        withActivationFee: withActivationFee
      });
    },
    isSearchable: false
  })), ___EmotionJSX("div", {
    className: "vk-shop-order-history-dropdown"
  }, ___EmotionJSX(ReactSelect, {
    options: optionsGiftCodeStatus,
    defaultValue: optionsGiftCodeStatus[1],
    onChange: function onChange(_ref4) {
      var giftCodeStatus = _ref4.value;
      return fetchResults({
        giftCodeStatus: giftCodeStatus
      });
    },
    isSearchable: false
  })), ___EmotionJSX("div", null, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    clicked: onExport,
    customClass: "export-button"
  }, "Export Table")));
};
MyGiftCodesFilterBar.propTypes = {
  getData: PropTypes.func,
  allPackages: PropTypes.array,
  onExport: PropTypes.func
};
export default MyGiftCodesFilterBar;