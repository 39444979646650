import api from "!../../../../../../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.75.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.75.0/node_modules/css-loader/dist/cjs.js!../../../../../../../node_modules/.pnpm/sass-loader@12.6.0_sass@1.32.0_webpack@5.75.0/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[2].use[2]!./styles.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};