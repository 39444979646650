import _isNil from "lodash/isNil";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.concat.js";
import React from 'react';
import PropTypes from 'prop-types';
import { NAItem } from '../../../components/UI/Table';
import defaultAvatarLogo from '../../../assets/images/icons/default-avatar.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CommunityTableUsernameCol = function CommunityTableUsernameCol(props) {
  var _ref;
  var data = props.data,
    packageColorsMap = props.packageColorsMap;
  var firstName = data.firstName,
    lastName = data.lastName,
    nickName = data.nickName,
    userName = data.userName,
    picThumbnailUrl = data.picThumbnailUrl,
    pictureUrl = data.pictureUrl,
    planName = data.planName;
  return !_isNil(data) && !_isNil(packageColorsMap) ? ___EmotionJSX("div", {
    className: "vk-network-table-username-col"
  }, ___EmotionJSX("div", {
    className: "vk-network-table-username-col-avatar",
    style: {
      borderColor: !_isNil(planName) ? packageColorsMap[planName === null || planName === void 0 ? void 0 : planName.trim()] : 'black'
    }
  }, ___EmotionJSX("img", {
    src: (_ref = picThumbnailUrl !== null && picThumbnailUrl !== void 0 ? picThumbnailUrl : pictureUrl) !== null && _ref !== void 0 ? _ref : defaultAvatarLogo,
    className: "vk-image-responsive",
    alt: "".concat(nickName, "-avatar")
  })), ___EmotionJSX("div", {
    className: "vk-network-table-username-col-name"
  }, ___EmotionJSX("h4", {
    className: "vk-network-table-username-col-name-headline"
  }, "".concat(firstName, " ").concat(lastName)), ___EmotionJSX("p", {
    className: "vk-network-table-username-col-name-nickname"
  }, userName))) : NAItem;
};
CommunityTableUsernameCol.propTypes = {
  data: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userName: PropTypes.string,
  nickName: PropTypes.string,
  picThumbnailUrl: PropTypes.string,
  pictureUrl: PropTypes.string,
  planName: PropTypes.string,
  packageColorsMap: PropTypes.any
};
export default CommunityTableUsernameCol;