import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as FrozenIcn } from '../../../../assets/images/layout-svg-icons/icn-frozen-snowflake.svg';
import { ReactComponent as TerminatedIcn } from '../../../../assets/images/layout-svg-icons/icn-terminated.svg';
import { ReactComponent as InactiveIcn } from '../../../../assets/images/layout-svg-icons/icn-inactive.svg';
import defaultAvatarLogo from '../../../../assets/images/icons/default-avatar.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AccountInfo = function AccountInfo(_ref) {
  var _nodeData$pictureUrl;
  var nodeData = _ref.nodeData,
    onClick = _ref.onClick,
    packageColor = _ref.packageColor,
    root = _ref.root;
  var isTerminated = nodeData.status === 'Terminated';
  var isFrozen = nodeData.status === 'Frozen';
  var isInactive = nodeData.status === 'Inactive';
  var isTopElement = !(nodeData !== null && nodeData !== void 0 && nodeData.parent);
  var isEmpty = !nodeData.id;
  var leg = nodeData.id === root.id ? 'you' : (nodeData === null || nodeData === void 0 ? void 0 : nodeData.path.charAt(root.path.length + 1)) === '1' ? 'left' : 'right';
  var handleClick = function handleClick(e) {
    e.stopPropagation();
    if (!isEmpty && !isTopElement) onClick(nodeData.id);
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    onClick: handleClick,
    className: "network-tree-avatar-account-container"
  }, ___EmotionJSX("div", {
    className: clsx('network-tree-avatar-account-picture', !nodeData.pictureUrl && 'network-tree-avatar-account-picture-default'),
    style: {
      borderColor: packageColor,
      backgroundImage: "url(".concat((_nodeData$pictureUrl = nodeData.pictureUrl) !== null && _nodeData$pictureUrl !== void 0 ? _nodeData$pictureUrl : defaultAvatarLogo, ")")
    }
  }, (isTerminated || isFrozen || isInactive) && !isEmpty && ___EmotionJSX("div", {
    className: "network-tree-avatar-account-status"
  }, isFrozen && ___EmotionJSX(FrozenIcn, null), isTerminated && ___EmotionJSX(TerminatedIcn, null), isInactive && ___EmotionJSX(InactiveIcn, null))), ___EmotionJSX("div", {
    className: "network-tree-avatar-account-nickname-wrap"
  }, ___EmotionJSX("p", {
    className: "network-tree-avatar-account-name"
  }, "".concat(nodeData.name, " ").concat(isTopElement ? "(".concat(leg, ")") : '')), ___EmotionJSX("p", {
    className: "network-tree-avatar-account-nickname"
  }, "".concat(nodeData.nickName, " ").concat(nodeData.rank && "(".concat(nodeData.rank, ")")))), ___EmotionJSX("p", {
    className: "network-tree-avatar-account-package"
  }, nodeData.planName.substring(0, 40)));
};
AccountInfo.propTypes = {
  nodeData: PropTypes.object,
  onClick: PropTypes.func,
  packageColor: PropTypes.string,
  root: PropTypes.object
};
export default AccountInfo;