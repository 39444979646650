import React from 'react';
import currencyByType from '../../../../utils/currencyByType';
import { walletType } from '../../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
var LevelBvRow = function LevelBvRow(props) {
  var levelBv = props.levelBv,
    hasBonusLevel = props.hasBonusLevel,
    percents = props.percents;
  return ___EmotionJSX("div", null, ___EmotionJSX("div", null, currencyByType(levelBv !== null && levelBv !== void 0 ? levelBv : 0, walletType.BV)), hasBonusLevel ? ___EmotionJSX("span", {
    className: "matching-bonus-subrow"
  }, percents, "%") : ___EmotionJSX("span", {
    className: "matching-bonus-subrow"
  }, "N/A"));
};
export default LevelBvRow;