import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '../../../components/UI/Loader';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Loading = function Loading() {
  return Array(10).fill().map(function (el, i) {
    return ___EmotionJSX(Grid, {
      item: true,
      xl: 3,
      lg: 4,
      sm: 6,
      xs: 12,
      key: i
    }, ___EmotionJSX("div", {
      className: "vk-shop-package-card"
    }, ___EmotionJSX("div", {
      className: "vk-shop-package-card-top"
    }, ___EmotionJSX(Grid, {
      container: true,
      spacing: 2,
      alignItems: "center"
    }, ___EmotionJSX(Grid, {
      item: true,
      sm: true
    }, ___EmotionJSX(Skeleton, {
      height: 24
    }), ___EmotionJSX(Skeleton, {
      width: 80,
      height: 24
    })), ___EmotionJSX(Grid, {
      item: true,
      sm: "auto"
    }, ___EmotionJSX(Skeleton, {
      width: 110,
      height: 110,
      circle: true
    }))))));
  });
};
export default Loading;