import _get from "lodash/get";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.is-array.js";
/* eslint-disable no-restricted-syntax */
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import MenuItemText from './MenuItemText';
// eslint-disable-next-line import/no-cycle
import MenuSubmenu from './MenuSubmenu';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MenuItem = function MenuItem(props) {
  var asideLeftLIRef = useRef();
  var item = props.item,
    currentUrl = props.currentUrl,
    parentItem = props.parentItem;
  var isDropdown = document.body.classList.contains('kt-aside-menu--dropdown');
  var submenuToggle = item.toggle === 'click' ? 'click' : _get(item, 'submenu.type') === 'tabs' ? 'tabs' : 'hover';
  var onMouseEnter = function onMouseEnter() {
    if (!isDropdown) return;
    if (item.submenu) {
      asideLeftLIRef.current.classList.add('kt-menu__item--hover');
      asideLeftLIRef.current.setAttribute('data-ktmenu-submenu-toggle', submenuToggle);
    }
  };
  var onMouseLeave = function onMouseLeave() {
    if (!isDropdown) return;
    if (item.submenu && submenuToggle === 'hover') {
      asideLeftLIRef.current.classList.remove('kt-menu__item--hover');
      asideLeftLIRef.current.removeAttribute('data-ktmenu-submenu-toggle');
    }
  };
  var isMenuItemActive = function isMenuItemActive(item) {
    if (item.submenu) return isMenuRootItemActive(item);
    if (!item.page) return false;
    return currentUrl.indexOf(item.page) !== -1;
  };
  var isMenuRootItemActive = function isMenuRootItemActive(item) {
    // eslint-disable-next-line curly
    var _iterator = _createForOfIteratorHelper(item.submenu),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var subItem = _step.value;
        if (isMenuItemActive(subItem)) return true;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return false;
  };
  var isActive = isMenuItemActive(item);
  return ___EmotionJSX("li", {
    role: "menuitem",
    ref: asideLeftLIRef,
    "aria-haspopup": "true",
    "data-placement": "right",
    "data-ktmenu-submenu-mode": item.mode,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    className: clsx('kt-menu__item', item.submenu && 'kt-menu__item--submenu', isActive && item.submenu && 'kt-menu__item--open kt-menu__item--here', isActive && !item.submenu && 'kt-menu__item--active kt-menu__item--here', item['icon-only'] && 'kt-menu__item--icon-only', item['custom-class']),
    "data-ktmenu-dropdown-toggle-class": item['dropdown-toggle-class']
  }, !item.submenu && ___EmotionJSX(Link, {
    to: "/".concat(item.page),
    className: "kt-menu__link kt-menu__toggle"
  }, ___EmotionJSX(MenuItemText, {
    item: item,
    parentItem: parentItem
  })), item.submenu &&
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  ___EmotionJSX("a", {
    className: "kt-menu__link kt-menu__toggle"
  }, ___EmotionJSX(MenuItemText, {
    item: item,
    parentItem: parentItem
  })), item.submenu && ___EmotionJSX("div", {
    className: "kt-menu__submenu"
  }, ___EmotionJSX("span", {
    className: "kt-menu__arrow"
  }), item['custom-class'] === 'kt-menu__item--submenu-fullheight' && ___EmotionJSX("div", {
    className: "kt-menu__wrapper"
  }, ___EmotionJSX(MenuSubmenu, {
    item: item,
    parentItem: item,
    currentUrl: currentUrl
  })), item['custom-class'] !== 'kt-menu__item--submenu-fullheight' && ___EmotionJSX(MenuSubmenu, {
    item: item,
    parentItem: item,
    currentUrl: currentUrl
  })));
};
export default MenuItem;