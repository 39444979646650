import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import React from 'react';
import PropTypes from 'prop-types';
import PaymentMethod from './PaymentMethod/PaymentMethodView';
import { paymentMethodsConfig, paymentMethodTypes } from '../../../enums/paymentMethods';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TransferPaymentMethodShop = function TransferPaymentMethodShop(_ref) {
  var methodsData = _ref.methodsData,
    onChange = _ref.onChange,
    onCollapse = _ref.onCollapse,
    checked = _ref.checked,
    collapse = _ref.collapse,
    cashBallance = _ref.cashBallance,
    coinBalance = _ref.coinBalance,
    reserveCashBalance = _ref.reserveCashBalance,
    bank50coin50Price = _ref.bank50coin50Price,
    bank25cash75Price = _ref.bank25cash75Price,
    hasReserveCashWallet = _ref.hasReserveCashWallet,
    bank75Cash25Price = _ref.bank75Cash25Price,
    bank75ReserveCash25Price = _ref.bank75ReserveCash25Price,
    bank75Coins25Price = _ref.bank75Coins25Price,
    cashPrice = _ref.cashPrice,
    bank50ReserveCash50Price = _ref.bank50ReserveCash50Price,
    configSettings = _ref.configSettings;
  var paymentMethods = (methodsData.visiblePaymentMethods || []).map(function (el) {
    return el.name;
  });
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "vk-shop-payment-methods-wrap"
  }, (paymentMethods || []).map(function (el) {
    var compProps = paymentMethodsConfig(methodsData, cashBallance === null || cashBallance === void 0 ? void 0 : cashBallance.toFixed(4), coinBalance === null || coinBalance === void 0 ? void 0 : coinBalance.toFixed(6), reserveCashBalance === null || reserveCashBalance === void 0 ? void 0 : reserveCashBalance.toFixed(4), cashPrice === null || cashPrice === void 0 ? void 0 : cashPrice.toFixed(4), bank50coin50Price === null || bank50coin50Price === void 0 ? void 0 : bank50coin50Price.toFixed(6), bank25cash75Price === null || bank25cash75Price === void 0 ? void 0 : bank25cash75Price.toFixed(4), bank75Cash25Price === null || bank75Cash25Price === void 0 ? void 0 : bank75Cash25Price.toFixed(4), bank75ReserveCash25Price === null || bank75ReserveCash25Price === void 0 ? void 0 : bank75ReserveCash25Price.toFixed(4), bank75Coins25Price === null || bank75Coins25Price === void 0 ? void 0 : bank75Coins25Price.toFixed(6), hasReserveCashWallet, bank50ReserveCash50Price === null || bank50ReserveCash50Price === void 0 ? void 0 : bank50ReserveCash50Price.toFixed(4))[el];
    return compProps && ___EmotionJSX(PaymentMethod, {
      key: el,
      collapse: collapse === paymentMethodTypes[el],
      checked: checked === paymentMethodTypes[el],
      onChange: onChange,
      onCollapse: onCollapse,
      paymentType: paymentMethodTypes[el],
      disabled: [],
      title: compProps.title,
      walletBalance: compProps.walletBalance,
      secondWalletBalance: compProps.secondWalletBalance,
      orderPrice: compProps.orderPrice,
      secondOrderPrice: compProps.secondOrderPrice,
      hasReserveCashWallet: compProps.hasReserveCashWallet,
      configSettings: configSettings
    });
  })));
};
TransferPaymentMethodShop.propTypes = {
  methodsData: PropTypes.object,
  onChange: PropTypes.func,
  onCollapse: PropTypes.func,
  checked: PropTypes.string,
  collapse: PropTypes.string,
  cashBallance: PropTypes.number,
  coinBalance: PropTypes.number,
  reserveCashBalance: PropTypes.number,
  hasReserveCashWallet: PropTypes.bool,
  bank75Cash25Price: PropTypes.number,
  bank75ReserveCash25Price: PropTypes.number,
  bank75Coins25Price: PropTypes.number,
  bank50coin50Price: PropTypes.number,
  bank25cash75Price: PropTypes.number,
  bank50ReserveCash50Price: PropTypes.number,
  cashPrice: PropTypes.number,
  packages: PropTypes.array,
  subscriptions: PropTypes.array,
  forTransfer: PropTypes.bool,
  configSettings: PropTypes.object
};
export default TransferPaymentMethodShop;