import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var WalletBalanceCard = function WalletBalanceCard(props) {
  var image = props.image,
    title = props.title,
    amount = props.amount;
  return ___EmotionJSX("div", {
    className: "wallets-blue-item"
  }, ___EmotionJSX("div", {
    className: "wallets-blue-item-image-wrapper"
  }, image), ___EmotionJSX("div", {
    className: "wallets-blue-item-text-wrapper"
  }, ___EmotionJSX("h5", {
    className: "wallets-blue-item-text-wrapper-title"
  }, title), ___EmotionJSX("h4", {
    className: "wallets-blue-item-text-wrapper-amount"
  }, amount)));
};
WalletBalanceCard.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default WalletBalanceCard;