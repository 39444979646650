import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Skeleton } from '../../../components/UI/Loader';
import { ReactComponent as RightIcn } from '../../../assets/images/layout-svg-icons/chevron-right100.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DashboardWalletsTab = function DashboardWalletsTab(props) {
  var onChange = props.onChange,
    wallet = props.wallet,
    isActive = props.isActive;
  return ___EmotionJSX(Grid, {
    item: true,
    xs: 6,
    sm: 4,
    lg: 12
  }, wallet ? ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: "vk-dashboard-wallets-tab-wrap ".concat(isActive ? 'active' : ''),
    onClick: onChange
  }, ___EmotionJSX("h4", {
    className: "vk-very-small-title"
  }, wallet.title), ___EmotionJSX("h4", {
    className: "vk-section-heading-light"
  }, wallet.balance), ___EmotionJSX("div", {
    className: "vk-dashboard-wallets-tab-icon"
  }, ___EmotionJSX(RightIcn, null)), ___EmotionJSX("div", {
    className: "vk-dashboard-wallets-tab-triangle-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-wallets-tab-triangle"
  }))) : ___EmotionJSX(Skeleton, {
    width: "180px",
    height: "92px",
    style: {
      marginBottom: '8px'
    }
  }));
};
DashboardWalletsTab.propTypes = {
  wallet: PropTypes.object,
  isActive: PropTypes.bool,
  onChange: PropTypes.func
};
export default DashboardWalletsTab;