import React from 'react';
import { Link } from 'react-router-dom';
import UserProfile from '../../../../partials/layout/UserProfile';
import ShoppingCart from '../../../../partials/layout/ShoppingCart';
import Messages from '../../../../partials/layout/Messages';
import { ReactComponent as AddIcon } from '../../../../assets/images/layout-svg-icons/topicn-add.svg';
import NewsletterButton from '../../../../partials/layout/NewsletterButton';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Topbar = function Topbar(props) {
  var isActive = props.isActive,
    hasAccounts = props.hasAccounts,
    cashWallet = props.cashWallet;
  var isNegativeCashBalance = (cashWallet === null || cashWallet === void 0 ? void 0 : cashWallet.balance) < 0;
  return ___EmotionJSX("div", {
    className: "kt-header__topbar"
  }, isActive && hasAccounts && ___EmotionJSX("div", {
    className: "vk-header-topbar-icons-wrapper"
  }, ___EmotionJSX("span", null, ___EmotionJSX(NewsletterButton, null)), ___EmotionJSX(Link, {
    to: "/profile/add-member"
  }, ___EmotionJSX(AddIcon, null)), ___EmotionJSX("span", null, ___EmotionJSX(Messages, null))), isActive && hasAccounts && !isNegativeCashBalance && ___EmotionJSX(ShoppingCart, null), ___EmotionJSX(UserProfile, {
    showAvatar: true,
    showHi: false,
    showBadge: false
  }));
};
export default Topbar;