import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var webinarsData = [{
  id: 1,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'State Of The Nation - OneCoin Webinar - July, 02 2015',
  viewUrl: 'https://www.youtube.com/watch?v=WfQjOdqpx94'
}, {
  id: 2,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'CoinVegas "PLAY AND WIN"',
  viewUrl: 'https://www.youtube.com/watch?v=mxn94xKHTew'
}, {
  id: 3,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'State Of The Nation - OneCoin Webinar - 30.07.2015',
  viewUrl: 'https://www.youtube.com/watch?v=uuzwmjJ9ctQ'
}, {
  id: 4,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'State Of The Nation - OneCoin Webinar - 06 August 2015',
  viewUrl: 'https://www.youtube.com/watch?v=ipEUz-Opy7s'
}, {
  id: 5,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'State Of The Nation - OneCoin Webinar - 14 August 2015',
  viewUrl: 'https://www.youtube.com/watch?v=w_Yn-n9cyfk'
}, {
  id: 6,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin - State Of Nation - Webinar - September 10th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=0-pIB0EgTtw'
}, {
  id: 7,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin - State Of Nation - Webinar - September 17th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=MTtVi4iDfHA'
}, {
  id: 8,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin - State Of Nation - Webinar - September 24th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=_x_7pvmjel4'
}, {
  id: 9,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin - State Of Nation - Webinar - October 1st, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=oOI1oP3XS8s'
}, {
  id: 10,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin - State Of Nation - Webinar - October 8th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=sG47lVwCA5Y'
}, {
  id: 11,
  type: RESOURCE_MEDIA_TYPES.YOUTUBE,
  title: 'OneCoin - State Of Nation - Webinar - October 15th, 2015',
  viewUrl: 'https://www.youtube.com/watch?v=NLTqAgGIMk0'
}];