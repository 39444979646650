import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SuccessIcn } from '../../../assets/images/layout-svg-icons/checked.svg';
import { ReactComponent as FailIcn } from '../../../assets/images/layout-svg-icons/cancel.svg';
import { orderItemTypes } from '../../../enums/orders';
import { paymentMethodTypes } from '../../../enums/paymentMethods';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderComplete = function OrderComplete(props) {
  var _props$location$state, _props$location, _props$location$state2, _props$location$state3, _props$location2, _props$location2$stat, _props$location3, _props$location3$stat;
  var status = (_props$location$state = props === null || props === void 0 ? void 0 : (_props$location = props.location) === null || _props$location === void 0 ? void 0 : (_props$location$state2 = _props$location.state) === null || _props$location$state2 === void 0 ? void 0 : _props$location$state2.status) !== null && _props$location$state !== void 0 ? _props$location$state : 1;
  var types = (_props$location$state3 = props === null || props === void 0 ? void 0 : (_props$location2 = props.location) === null || _props$location2 === void 0 ? void 0 : (_props$location2$stat = _props$location2.state) === null || _props$location2$stat === void 0 ? void 0 : _props$location2$stat.types) !== null && _props$location$state3 !== void 0 ? _props$location$state3 : [];
  var paymentMethod = props === null || props === void 0 ? void 0 : (_props$location3 = props.location) === null || _props$location3 === void 0 ? void 0 : (_props$location3$stat = _props$location3.state) === null || _props$location3$stat === void 0 ? void 0 : _props$location3$stat.payMethod;
  var title = types.length === 1 && types[0] === orderItemTypes.Subscription ? 'Congratulations!' : 'Your order was successful';
  var showCompletedOrderMessage = function showCompletedOrderMessage() {
    if (types.length > 1) {
      return ___EmotionJSX(React.Fragment, null, getCompletedPackageOrderMessage(), getCompletedSubscriptionOrderMessage(true));
    }
    if (types[0] === orderItemTypes.Package) return getCompletedPackageOrderMessage();
    if (types[0] === orderItemTypes.Subscription) return getCompletedSubscriptionOrderMessage();
  };
  var getCompletedPackageOrderMessage = function getCompletedPackageOrderMessage() {
    if (paymentMethod === paymentMethodTypes.CashWallet || paymentMethod === paymentMethodTypes.Cash75Coin25 || paymentMethod === paymentMethodTypes.Cash75ReserveCash25) {
      return ___EmotionJSX("p", null, "Please, note that when the transactions for the payment are completed the codes will be automatically marked as \"paid\". Thank you for your order!");
    }
    return ___EmotionJSX("p", null, "In order to complete your purchase please contact our Accounting Department via e-mail", ___EmotionJSX("a", {
      href: "mailto:invoice@onelifecorp.eu"
    }, ___EmotionJSX("b", {
      className: "order-bold-text"
    }, " invoice@onelifecorp.eu ")), "for further instructions about the payment process.");
  };
  var getCompletedSubscriptionOrderMessage = function getCompletedSubscriptionOrderMessage(hasPackageMessage) {
    if (hasPackageMessage === true) return ___EmotionJSX("p", null, "The subscription will be activated within 48 hours.");
    return ___EmotionJSX("p", null, "Your purchase has been made successfully and the subscription will be activated within 48 hours.");
  };
  var failedOrder = ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, "Please contact our support agent for more details at"), ___EmotionJSX("a", {
    href: "mailto:support@oneecosystem.eu"
  }, " support@oneecosystem.eu"));
  return ___EmotionJSX("div", {
    className: "vk-shop-order-complete"
  }, ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content"
  }, ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content-image"
  }, status === 1 ? ___EmotionJSX(SuccessIcn, null) : ___EmotionJSX(FailIcn, null)), ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, status === 1 ? title : 'Order fail!'), ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content-text"
  }, status === 1 ? showCompletedOrderMessage() : failedOrder), status === 1 && ___EmotionJSX(Link, {
    to: "/shop",
    className: "vk-link"
  }, "Back to Shop"))));
};
export default OrderComplete;