import React from 'react';
import PropTypes from 'prop-types';
import { accountRankInformation } from '../../../enums/account';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CommunityTableLeadershipCol = function CommunityTableLeadershipCol(props) {
  var _accountRankInformati, _accountRankInformati2;
  var rank = props.rank;
  return ___EmotionJSX("div", {
    className: "vk-network-table-package-col"
  }, ((_accountRankInformati = accountRankInformation[rank]) === null || _accountRankInformati === void 0 ? void 0 : _accountRankInformati.rankImage) && ___EmotionJSX("img", {
    className: "vk-network-table-package-col-image",
    src: (_accountRankInformati2 = accountRankInformation[rank]) === null || _accountRankInformati2 === void 0 ? void 0 : _accountRankInformati2.rankImage,
    alt: "package"
  }), ___EmotionJSX("div", {
    className: "vk-network-table-package-col-name"
  }, rank !== null && rank !== void 0 ? rank : 'N/A'));
};
CommunityTableLeadershipCol.propTypes = {
  rank: PropTypes.string
};
export default CommunityTableLeadershipCol;