import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _setAuthConfig;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { setAuthConfig, AUTH_EVENTS_TYPES } from '@oneecosystem/authenticate';
import App from './App';
import { trackGeoLocation } from './app/services/Config';
import { getReq, postReq, putReq, deleteReq, patchReq } from './app/services/makeRequest';
import './index.scss'; // Standard version
import 'react-dates/lib/css/_datepicker.css';
import './app/assets/icons/line-awesome/css/line-awesome.css';
import './app/assets/icons/flaticon/css/flaticon.css';
import './app/assets/icons/flaticon2/css/flaticon2.css';
import './app/assets/icons/backendIcons/style.css';
import { jsx as ___EmotionJSX } from "@emotion/react";
var clientId = 'OneLife';
setAuthConfig((_setAuthConfig = {
  identity: {
    domain: keycloakUrl,
    clientId: clientId
  },
  oneLifeDomain: apiUrl,
  requestAdapter: {
    get: getReq,
    post: postReq,
    put: putReq,
    patch: patchReq,
    "delete": deleteReq
  }
}, _defineProperty(_setAuthConfig, AUTH_EVENTS_TYPES.SET_TOKEN, function (token) {
  if (token !== null && token !== void 0 && token.access_token) axios.defaults.headers.common.Authorization = "Bearer ".concat(token.access_token);
}), _defineProperty(_setAuthConfig, AUTH_EVENTS_TYPES.LOGIN, trackGeoLocation), _defineProperty(_setAuthConfig, AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD, function () {
  trackGeoLocation();
  window.location = '/dashboard';
}), _defineProperty(_setAuthConfig, AUTH_EVENTS_TYPES.RENEW_SESSION, trackGeoLocation), _defineProperty(_setAuthConfig, AUTH_EVENTS_TYPES.LOGOUT, function () {
  window.location = '/';
}), _setAuthConfig));
ReactDOM.render(___EmotionJSX(App, null), document.getElementById('root'));