function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import _pick from "lodash/pick";
import _orderBy from "lodash/orderBy";
import _flatten from "lodash/flatten";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import queryString from 'query-string';
import { orderStatus } from '../../enums/orders';
export var createNewOrderModel = function createNewOrderModel(data) {
  return {
    accountId: data.id,
    paymentTypeId: data.paymentTypeId,
    packages: _flatten(data.packages.map(function (el) {
      return _toConsumableArray(Array(el.amount).fill({
        catalogueItemId: el.catalogueItemId,
        withActivationFee: el.mandatoryActivation || el.activationFee
      }));
    })),
    subscriptionPlans: data.subscriptionPlans.map(function (el) {
      return el.id;
    })
  };
};
export var getCatalogueItemsOrdersModel = function getCatalogueItemsOrdersModel(data) {
  return _objectSpread(_objectSpread({}, data), {}, {
    orders: _orderBy(data === null || data === void 0 ? void 0 : data.orders, ['id'], ['desc']).map(function (el) {
      return _objectSpread(_objectSpread({}, el), {}, {
        packages: el.packages.map(function (pack) {
          return _objectSpread(_objectSpread({}, pack), {}, {
            createdOn: el.createdOn
          });
        }),
        subscriptions: _flatten(el.subscriptions.map(function (catItem) {
          return catItem.subscriptionPlans.map(function (plan) {
            return _objectSpread(_objectSpread({}, plan), {}, {
              catalogueItemId: catItem.id
            });
          });
        }))
      });
    })
  });
};
export var getMyOrderHistoryParams = function getMyOrderHistoryParams(options) {
  var _options$status = options.status,
    status = _options$status === void 0 ? orderStatus.Pending : _options$status;
  var filterField = [];
  var filterValue = [];
  if (status) {
    filterField.push('status');
    filterValue.push(status);
  }
  return queryString.stringify(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};