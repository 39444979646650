import "core-js/modules/es.array.concat.js";
import qs from 'query-string';
import { getReq } from './makeRequest';
var baseUrl = "".concat(blockExplorerUrl);
export var getStats = function getStats() {
  return getReq("".concat(baseUrl, "/stats"), {
    transformRequest: function transformRequest(data, headers) {
      // eslint-disable-next-line no-param-reassign
      delete headers.common.Authorization;
    }
  });
};
export var getBlocks = function getBlocks(params) {
  return getReq("".concat(baseUrl, "/blocks/list"), {
    params: params
  });
};
export var getTransactions = function getTransactions(params) {
  return getReq("".concat(baseUrl, "/transactions/list"), {
    params: params
  });
};
export var getTransactionsByHash = function getTransactionsByHash(hash) {
  return getReq("".concat(baseUrl, "/transactions/search?").concat(qs.stringify(hash)));
};