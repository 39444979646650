import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var brandingMaterialsData = [{
  id: 1,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BRANDBOOK ONE ECOSYSTEM NETWORK',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Brandbook_ONE_ECOSYSTEM_NETWORK view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Brandbook_ONE_ECOSYSTEM_NETWORK download.pdf'
}, {
  id: 2,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'COMPILACIÓN BANNERS ROLL-UP ESPAÑOL',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Copilacio%CC%81n_compressed view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Copilacio%CC%81n_compressed download.pdf'
}, {
  id: 3,
  type: RESOURCE_MEDIA_TYPES.SVG,
  title: 'IMA LOGO VERTICAL DARK BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_dark background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_dark background download.svg'
}, {
  id: 4,
  type: RESOURCE_MEDIA_TYPES.SVG,
  title: 'IMA LOGO HORIZONTAL DARK BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_dark background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_dark background download.svg'
}, {
  id: 5,
  type: RESOURCE_MEDIA_TYPES.SVG,
  title: 'IMA LOGO HORIZONTAL  LIGHT BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_white background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_HORIZONTAL_white background download.svg'
}, {
  id: 6,
  type: RESOURCE_MEDIA_TYPES.SVG,
  title: 'IMA LOGO VERTICAL LIGHT BACKGROUND',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_white background view.svg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ONE ECOSYSTEM_IMA LOGO_white background download.svg'
}, {
  id: 7,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card back purple/blue',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 1 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 1 download.png'
}, {
  id: 8,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card back blue/green',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 2 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 2 download.png'
}, {
  id: 9,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card back orange/yellow',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 3 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 3 download.png'
}, {
  id: 10,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card back fucsia/orange',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 4 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Alt Back 4 download.png'
}, {
  id: 11,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card back Light',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back1 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back1 download.png'
}, {
  id: 12,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card back Dark ',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back2 view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Back2 download.png'
}, {
  id: 13,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card front Dark',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Dark view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Dark download.png'
}, {
  id: 14,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Business card front Light',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Light view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card Face_Light download.png'
}, {
  id: 15,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'EXAMPLE Business card front Dark',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/ЕXAMPLE___Business Card Face_Dark view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/ЕXAMPLE___Business Card Face_Dark download.png'
}, {
  id: 16,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'EXAMPLE Business card front Light',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/EXAMPLE___Business Card Face_Light view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/EXAMPLE___Business Card Face_Light download.png'
}, {
  id: 17,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 GALA NIGHT 1',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 1 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 1 download.pdf'
}, {
  id: 18,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 GALA NIGHT 2',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 2 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Gala Banner 2 download.pdf'
}, {
  id: 19,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 TRAINING 1',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 1 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 1 download.pdf'
}, {
  id: 20,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 TRAINING 2',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 2 view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/IMA_ Training Banner 2 download.pdf'
}, {
  id: 21,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 EDUCATION & LEARNING PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_PicOnly download.pdf'
}, {
  id: 22,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 EDUCATION & LEARNING TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Education&Learning_TextOnly download.pdf'
}, {
  id: 23,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 HONESTY & FAIRNESS PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty & Fairness_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty & Fairness_PicOnly download.pdf'
}, {
  id: 24,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 HONESTY & FAIRNESS TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty&Fairness_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Honesty&Fairness_TextOnly download.pdf'
}, {
  id: 25,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 PASSION & INTEGRITY PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_PicOnly download.pdf'
}, {
  id: 26,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 PASSION & INTEGRITY TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Passion&Integrity_TextOnly download.pdf'
}, {
  id: 27,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 STABILITY AND TRUST PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_PicOnly download.pdf'
}, {
  id: 28,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 STABILITY AND TRUST TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Stability&Trust_TextOnly download.pdf'
}, {
  id: 29,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 SYNERGY & TEAMWORK PICTURE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_PicOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_PicOnly download.pdf'
}, {
  id: 30,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Roll-up Banner 80X200 SYNERGY & TEAMWORK TEXT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_TextOnly view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/RB_Synergy&Teamwork_TextOnly download.pdf'
}, {
  id: 31,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Branded Envelope BACK',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_BACK_MASTER view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_BACK_MASTER download.png'
}, {
  id: 32,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Branded Envelope FRONT',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_FRONT_MASTER view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Envelope_FRONT_MASTER download.png'
}, {
  id: 33,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Branded Letterhead A4',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Letterhead_A4_MASTER.psd view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OE_IMA_Branded Letterhead_A4_MASTER.psd download.png'
}];