import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import React from 'react';
import { USER_ROLES, hasRole } from '@oneecosystem/authenticate';
import { Grid } from '@material-ui/core';
import { Table } from '../../../../../components/UI/Table';
import BackButton from '../../../../../components/UI/Button/BackButton';
import TableFooterBar from '../../../../../components/UI/Shared/TableFooterBar';
import mockTableRes from '../../../../../utils/mockTableRes';
import TextAndImage from '../../components/TextAndImage';
import ViewDocument from '../../components/ViewDocument';
import { imaToolsData, imaToolsDataAdminOnly } from '../../tabs/resourcesData';
import { mapResourceDataToViewModel } from '../../tabs/resourcesConfig';
import { jsx as ___EmotionJSX } from "@emotion/react";
var columnsDefinition = [{
  render: function render(row) {
    return ___EmotionJSX(TextAndImage, {
      row: row
    });
  }
}, {
  width: '120px',
  render: function render(row) {
    return ___EmotionJSX(ViewDocument, {
      row: row
    });
  }
}];
var dataKey = 'imaTools';
var mappedimaToolsData = mapResourceDataToViewModel(imaToolsData);
var mappedimaToolsDataAdminOnly = mapResourceDataToViewModel(imaToolsDataAdminOnly);
var ImaTools = function ImaTools() {
  var data = hasRole(USER_ROLES.ADMINISTRATOR) ? [].concat(_toConsumableArray(mappedimaToolsData), _toConsumableArray(mappedimaToolsDataAdminOnly)) : mappedimaToolsData;
  return ___EmotionJSX("div", {
    className: "vk-info-center-inner-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 3
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "title-and-back"
  }, ___EmotionJSX(BackButton, null), ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Ima Tools"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX("div", {
    className: "vk-info-center-table-overflow"
  }, ___EmotionJSX("div", {
    className: "vk-paper-box"
  }, ___EmotionJSX(Table, {
    getDataKey: dataKey,
    getDataMethod: mockTableRes(dataKey, data, 0),
    columns: columnsDefinition,
    hasPagination: true,
    pageSize: 10,
    footerBar: TableFooterBar()
  }))))));
};
export default ImaTools;