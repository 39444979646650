/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import { withRouter } from 'react-router-dom';
import MenuList from './MenuList';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AsideLeft = function AsideLeft(props) {
  var menuConfig = props.menuConfig,
    pathname = props.location.pathname;
  return ___EmotionJSX("div", {
    id: "kt_aside_menu",
    style: {
      maxHeight: '90vh',
      position: 'relative'
    },
    "data-ktmenu-vertical": "1",
    className: "kt-aside-menu"
  }, ___EmotionJSX("ul", {
    className: "kt-menu__nav"
  }, ___EmotionJSX(MenuList, {
    currentUrl: pathname,
    menuConfig: menuConfig
  })));
};
export default withRouter(AsideLeft);