import React from 'react';
import RatingWidget from '../../../components/UI/Shared/RatingWidget';
import { profileRankInformation, profileRankId, profileRank } from '../../../enums/profile';
import { jsx as ___EmotionJSX } from "@emotion/react";
var EagleNextRankInformation = function EagleNextRankInformation(props) {
  var _profileInfo$eagleRan, _profileRankInformati;
  var profileInfo = props.profileInfo;
  var eagleRank = (_profileInfo$eagleRan = profileInfo === null || profileInfo === void 0 ? void 0 : profileInfo.eagleRankId) !== null && _profileInfo$eagleRan !== void 0 ? _profileInfo$eagleRan : 1;
  var nextRank = profileRankInformation[(_profileRankInformati = profileRankInformation[profileRankId[eagleRank]]) === null || _profileRankInformati === void 0 ? void 0 : _profileRankInformati.nextRank];
  return ___EmotionJSX("div", {
    className: "recognition-wrapper-eagle-rank"
  }, ___EmotionJSX("div", {
    className: "recognition-next-eagle-rank"
  }, ___EmotionJSX("img", {
    src: nextRank === null || nextRank === void 0 ? void 0 : nextRank.rankImage,
    alt: "package",
    className: "next-eagle-wrap"
  }), ___EmotionJSX("div", null, ___EmotionJSX("h2", {
    className: "next-title"
  }, "Next Rank"), ___EmotionJSX("h2", {
    className: "next-name-eagle-rank"
  }, nextRank === null || nextRank === void 0 ? void 0 : nextRank.rankName))), ___EmotionJSX("div", {
    className: "recognition-rank-info-container"
  }, ___EmotionJSX("span", {
    className: "next-package"
  }, "You need:"), ___EmotionJSX(RatingWidget, {
    stars: (nextRank === null || nextRank === void 0 ? void 0 : nextRank.rankName) === profileRank.LifetimeEagle ? 12 : 6,
    rated: (nextRank === null || nextRank === void 0 ? void 0 : nextRank.rankName) === profileRank.LifetimeEagle ? 12 : 6
  })));
};
export default EagleNextRankInformation;