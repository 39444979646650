function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import currencyByType from '../utils/currencyByType';
import { walletType } from './wallets';
export var paymentMethodTypes = {
  BankDetails: 'BankDetails',
  PerfectMoney: 'PerfectMoney',
  ChinaUnionPay: 'ChinaUnionPay',
  CashWallet: 'CashWallet',
  Bank50Coins50: 'Bank50Coins50',
  Cash75Bank25: 'Cash75Bank25',
  Bank75Cash25: 'Bank75Cash25',
  Bank75ReserveCash25: 'Bank75ReserveCash25',
  Bank75Coins25: 'Bank75Coins25',
  System: 'System',
  Bitcoin: 'Btc',
  Usdt: 'Usdt',
  Cash75ReserveCash25: 'Cash75ReserveCash25',
  Cash75Coin25: 'Cash75Coin25',
  Bank50ReserveCash50: 'Bank50ReserveCash50',
  Cash50ReserveCash50: 'Cash50ReserveCash50',
  Cash50Coins50: 'Cash50Coins50'
};
export var PAYMENT_TYPE = {
  MOBILE_ACCESS: 'mobileAccess',
  XKYC: 'xkyc',
  VIP_DOCUMENTS: 'vipDocuments',
  PACKAGES: 'packages'
};
export var paymentMethodsConfig = function paymentMethodsConfig(methodsData, cashBallance, coinBalance, reserveCashBalance, cashPrice, bank50coin50Price, bank25cash75Price, bank75Cash25Price, bank75ReserveCash25Price, bank75Coins25Price, hasReserveCashWallet, bank50ReserveCash50Price) {
  var _objectSpread2;
  var findDisplayName = function findDisplayName(keyName) {
    var _methodsData$allPayme, _methodsData$allPayme2;
    return methodsData === null || methodsData === void 0 ? void 0 : (_methodsData$allPayme = methodsData.allPaymentMethods) === null || _methodsData$allPayme === void 0 ? void 0 : (_methodsData$allPayme2 = _methodsData$allPayme.find(function (el) {
      return el.name === keyName;
    })) === null || _methodsData$allPayme2 === void 0 ? void 0 : _methodsData$allPayme2.displayName;
  };
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread((_objectSpread2 = {}, _defineProperty(_objectSpread2, paymentMethodTypes.BankDetails, {
    title: findDisplayName(paymentMethodTypes.BankDetails)
  }), _defineProperty(_objectSpread2, paymentMethodTypes.CashWallet, {
    title: findDisplayName(paymentMethodTypes.CashWallet),
    walletBalance: currencyByType(cashBallance !== null && cashBallance !== void 0 ? cashBallance : 0, walletType.CASH),
    orderPrice: currencyByType(cashPrice || 0, walletType.CASH)
  }), _defineProperty(_objectSpread2, paymentMethodTypes.Bank50Coins50, {
    title: findDisplayName(paymentMethodTypes.Bank50Coins50),
    walletBalance: currencyByType(coinBalance !== null && coinBalance !== void 0 ? coinBalance : 0, walletType.COIN),
    orderPrice: currencyByType(bank50coin50Price || 0, walletType.COIN)
  }), _defineProperty(_objectSpread2, paymentMethodTypes.Cash75Bank25, {
    title: findDisplayName(paymentMethodTypes.Cash75Bank25),
    walletBalance: currencyByType(cashBallance !== null && cashBallance !== void 0 ? cashBallance : 0, walletType.CASH),
    orderPrice: currencyByType(bank25cash75Price || 0, walletType.CASH)
  }), _defineProperty(_objectSpread2, paymentMethodTypes.Bank75Cash25, {
    title: findDisplayName(paymentMethodTypes.Bank75Cash25),
    walletBalance: currencyByType(cashBallance !== null && cashBallance !== void 0 ? cashBallance : 0, walletType.CASH),
    orderPrice: currencyByType(bank75Cash25Price || 0, walletType.CASH)
  }), _objectSpread2), hasReserveCashWallet && _defineProperty({}, paymentMethodTypes.Bank75ReserveCash25, {
    title: findDisplayName(paymentMethodTypes.Bank75ReserveCash25),
    walletBalance: currencyByType(reserveCashBalance !== null && reserveCashBalance !== void 0 ? reserveCashBalance : 0, walletType.CASH),
    orderPrice: currencyByType(bank75ReserveCash25Price || 0, walletType.CASH)
  })), {}, _defineProperty({}, paymentMethodTypes.Bank75Coins25, {
    title: findDisplayName(paymentMethodTypes.Bank75Coins25),
    walletBalance: currencyByType(coinBalance !== null && coinBalance !== void 0 ? coinBalance : 0, walletType.COIN),
    orderPrice: currencyByType(bank75Coins25Price || 0, walletType.COIN)
  }), hasReserveCashWallet && _defineProperty({}, paymentMethodTypes.Cash75ReserveCash25, {
    title: findDisplayName(paymentMethodTypes.Cash75ReserveCash25),
    walletBalance: currencyByType(reserveCashBalance !== null && reserveCashBalance !== void 0 ? reserveCashBalance : 0, walletType.CASH),
    secondWalletBalance: currencyByType(cashBallance !== null && cashBallance !== void 0 ? cashBallance : 0, walletType.CASH),
    orderPrice: currencyByType(bank75ReserveCash25Price || 0, walletType.CASH),
    secondOrderPrice: currencyByType(bank25cash75Price || 0, walletType.CASH)
  })), {}, _defineProperty({}, paymentMethodTypes.Cash75Coin25, {
    title: findDisplayName(paymentMethodTypes.Cash75Coin25),
    walletBalance: currencyByType(coinBalance !== null && coinBalance !== void 0 ? coinBalance : 0, walletType.COIN),
    secondWalletBalance: currencyByType(cashBallance !== null && cashBallance !== void 0 ? cashBallance : 0, walletType.CASH),
    orderPrice: currencyByType(bank75Coins25Price || 0, walletType.COIN),
    secondOrderPrice: currencyByType(bank25cash75Price || 0, walletType.CASH)
  }), hasReserveCashWallet && _defineProperty({}, paymentMethodTypes.Bank50ReserveCash50, {
    title: findDisplayName(paymentMethodTypes.Bank50ReserveCash50),
    walletBalance: currencyByType(reserveCashBalance !== null && reserveCashBalance !== void 0 ? reserveCashBalance : 0, walletType.CASH),
    orderPrice: currencyByType(bank50ReserveCash50Price || 0, walletType.CASH)
  })), hasReserveCashWallet && _defineProperty({}, paymentMethodTypes.Cash50ReserveCash50, {
    title: findDisplayName(paymentMethodTypes.Cash50ReserveCash50),
    walletBalance: currencyByType(reserveCashBalance !== null && reserveCashBalance !== void 0 ? reserveCashBalance : 0, walletType.CASH),
    secondWalletBalance: currencyByType(cashBallance !== null && cashBallance !== void 0 ? cashBallance : 0, walletType.CASH),
    orderPrice: currencyByType(bank50ReserveCash50Price || 0, walletType.CASH),
    secondOrderPrice: currencyByType(bank50ReserveCash50Price || 0, walletType.CASH)
  })), {}, _defineProperty({}, paymentMethodTypes.Cash50Coins50, {
    title: findDisplayName(paymentMethodTypes.Cash50Coins50),
    walletBalance: currencyByType(coinBalance !== null && coinBalance !== void 0 ? coinBalance : 0, walletType.COIN),
    secondWalletBalance: currencyByType(cashBallance !== null && cashBallance !== void 0 ? cashBallance : 0, walletType.CASH),
    orderPrice: currencyByType(bank50coin50Price || 0, walletType.COIN),
    secondOrderPrice: currencyByType(bank50ReserveCash50Price || 0, walletType.CASH)
  }));
};