import queryString from 'query-string';
export var getCoinWalletTransferStatesModel = function getCoinWalletTransferStatesModel(data) {
  var mappedModel = data.coinV1ToV2 && {
    accounts: data.coinV1ToV2.walletStatuses,
    transferEligible: data.coinV1ToV2.isEligibleForTransfer,
    isTransferAllowedAfterCutoffDate: data.coinV1ToV2.isTransferAllowedAfterCutoffDate,
    multiWalletTransferAmounts: data.coinV1ToV2.multiWalletTransferAmounts
  };
  return mappedModel;
};
export var getAccountStatusesParams = function getAccountStatusesParams(options) {
  var check = options.check;
  return queryString.stringify({
    withEligibilityCheck: check
  }, {
    arrayFormat: 'index'
  });
};