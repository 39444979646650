import React from 'react';
import { dateTime } from './TableDataComponents';
import ColorBadge from '../../../components/UI/Shared/ColorBadge';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var withdrawalMethodsColumnsDefinition = [{
  name: 'Created Date',
  render: dateTime('createdOn'),
  width: '160px'
}, {
  name: 'Method Type',
  value: 'type',
  width: '110px'
}, {
  name: 'Previous Address',
  render: function render(_ref) {
    var _JSON$parse$addressNu, _JSON$parse;
    var previousWithdrawalMethodValue = _ref.previousWithdrawalMethodValue,
      NAItem = _ref.NAItem;
    return (_JSON$parse$addressNu = (_JSON$parse = JSON.parse(previousWithdrawalMethodValue)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.addressNumber) !== null && _JSON$parse$addressNu !== void 0 ? _JSON$parse$addressNu : NAItem;
  }
}, {
  name: 'Current Address',
  render: function render(_ref2) {
    var _JSON$parse$addressNu2, _JSON$parse2;
    var value = _ref2.value,
      NAItem = _ref2.NAItem;
    return (_JSON$parse$addressNu2 = (_JSON$parse2 = JSON.parse(value)) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.addressNumber) !== null && _JSON$parse$addressNu2 !== void 0 ? _JSON$parse$addressNu2 : NAItem;
  }
}, {
  name: 'Active',
  render: function render(row) {
    return ___EmotionJSX(ColorBadge, {
      type: row.active ? 'green' : 'red'
    }, row.active ? 'Yes' : 'No');
  },
  width: '70px'
}];