import React from 'react';
import { Grid } from '@material-ui/core';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { Table } from '../../../../../components/UI/Table';
import BackButton from '../../../../../components/UI/Button/BackButton';
import TableFooterBar from '../../../../../components/UI/Shared/TableFooterBar';
import mockTableRes from '../../../../../utils/mockTableRes';
import TextAndImage from '../../components/TextAndImage';
import ViewDocument from '../../components/ViewDocument';
import { productAndBusinessPresentationsData } from '../../tabs/resourcesData';
import { mapResourceDataToViewModel } from '../../tabs/resourcesConfig';
import { jsx as ___EmotionJSX } from "@emotion/react";
var columnsDefinition = [{
  render: function render(row) {
    return ___EmotionJSX(TextAndImage, {
      row: row
    });
  }
}, {
  width: '120px',
  render: function render(row) {
    return ___EmotionJSX(ViewDocument, {
      row: row
    });
  }
}];
var ProductAndBusinessPresentations = function ProductAndBusinessPresentations() {
  var isAdmin = hasRole(USER_ROLES.ADMINISTRATOR);
  var dataKey = 'productAndBusinessPresentations';
  var mappedData = mapResourceDataToViewModel(productAndBusinessPresentationsData, isAdmin);
  return ___EmotionJSX("div", {
    className: "vk-info-center-inner-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 3
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "title-and-back"
  }, ___EmotionJSX(BackButton, null), ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Product And Business Presentations"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX("div", {
    className: "vk-info-center-table-overflow"
  }, ___EmotionJSX("div", {
    className: "vk-paper-box"
  }, ___EmotionJSX(Table, {
    getDataKey: dataKey,
    getDataMethod: mockTableRes(dataKey, mappedData, 0),
    columns: columnsDefinition,
    hasPagination: true,
    pageSize: 10,
    footerBar: TableFooterBar()
  }))))));
};
export default ProductAndBusinessPresentations;