function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import hash from 'object-hash';
import { accountActions, subscriptionActions } from '../../../store';
import { NoResults } from '../../../components/UI/Shared/NoResults';
import SubscriptionCard from '../components/SubscriptionCard';
import LoadingSubscriptionCard from '../components/LoadingSubscriptionCard';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MySubscriptions = function MySubscriptions(props) {
  var mySubscriptions = props.subscriptions.items;
  var _useState = useState(mySubscriptions),
    _useState2 = _slicedToArray(_useState, 2),
    subscriptions = _useState2[0],
    setSubscriptions = _useState2[1];
  useEffect(function () {
    hash({
      mySubscriptions: mySubscriptions
    }) !== hash({
      mySubscriptions: subscriptions
    }) && setSubscriptions(mySubscriptions);
  }, [hash({
    mySubscriptions: mySubscriptions
  })]);
  return ___EmotionJSX("div", {
    className: "vk-shop-page"
  }, ___EmotionJSX("div", {
    className: "vk-shop-page-header"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "My Subscriptions")))), ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center"
  }, subscriptions ? subscriptions.length ? subscriptions.map(function (el, i) {
    return ___EmotionJSX(Grid, {
      item: true,
      xl: 3,
      lg: 4,
      sm: 6,
      xs: 12,
      key: "".concat(el.subscriptionCatalogueItemId, "-").concat(i)
    }, ___EmotionJSX(SubscriptionCard, {
      item: el
    }));
  }) : ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX(NoResults, {
    emptyMessage: "There are no subscriptions from this search"
  })) : ___EmotionJSX(LoadingSubscriptionCard, null)));
};
MySubscriptions.propTypes = {
  subscriptions: PropTypes.object,
  currentAccount: PropTypes.object,
  addSubscription: PropTypes.func,
  refreshCurrentWallets: PropTypes.func
};
export default connect(function (_ref) {
  var subscriptions = _ref.subscriptions,
    accounts = _ref.accounts;
  return {
    subscriptions: subscriptions,
    currentAccount: accounts === null || accounts === void 0 ? void 0 : accounts.current
  };
}, _objectSpread(_objectSpread({}, subscriptionActions), accountActions))(MySubscriptions);