import "core-js/modules/es.array.map.js";
import React from 'react';
import currencyByType from '../../../../utils/currencyByType';
import { walletType } from '../../../../enums/wallets';
import matchingBonusLogo from '../../../../assets/images/bonuses/imgmatchingbonus.jpg';
import tycoonLogo from '../../../../assets/images/bonuses/tycoon.png';
import starterLogo from '../../../../assets/images/bonuses/starter.png';
import traderLogo from '../../../../assets/images/bonuses/trader.png';
import proLogo from '../../../../assets/images/bonuses/pro.png';
import LevelBvRow from './LevelBvRow';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var jumbotron = {
  title: 'Matching Bonus',
  text: 'Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur. \n Maecenas sed diam eget risus varius blandit sit amet non magna. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur.',
  image: matchingBonusLogo,
  awardImage: '',
  whatTodos: [{
    title: 'At least Tradaing package',
    toDo: 'Invite new member',
    done: true
  }, {
    title: 'Sponsor at least 1 member with Trader package',
    toDo: 'Upgrade member package',
    done: false
  }]
};
export var packages = [{
  name: 'Tycoon',
  image: tycoonLogo,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui.',
  bonuses: [{
    generation: '1st Gen',
    percent: 10
  }, {
    generation: '2nd Gen',
    percent: 10
  }]
}, {
  name: 'Starter',
  image: starterLogo,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui.',
  bonuses: [{
    generation: '1st Gen',
    percent: 10
  }, {
    generation: '2nd Gen',
    percent: 10
  }, {
    generation: '3rd Gen',
    percent: 20
  }]
}, {
  name: 'Trader',
  image: traderLogo,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui.',
  bonuses: []
}, {
  name: 'Pro',
  image: proLogo,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed odio dui.',
  bonuses: [{
    generation: '1st Gen',
    percent: 10
  }, {
    generation: '2nd Gen',
    percent: 10
  }, {
    generation: '3rd Gen',
    percent: 20
  }, {
    generation: '4th Gen',
    percent: 25
  }]
}];
export var packagesArray = packages.map(function (el, ind) {
  return {
    data: el,
    id: ind
  };
});
export var columnsDefinition = [{
  name: 'Date',
  value: 'date',
  width: '120px',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: '1st Gen',
  render: function render(row) {
    var _row$packageInfoForRo;
    return ___EmotionJSX(LevelBvRow, {
      levelBv: row.firstLevelBv,
      hasBonusLevel: row === null || row === void 0 ? void 0 : (_row$packageInfoForRo = row.packageInfoForRound) === null || _row$packageInfoForRo === void 0 ? void 0 : _row$packageInfoForRo.hasMatch1Bonus,
      percents: 10
    });
  }
}, {
  name: '2nd Gen',
  render: function render(row) {
    var _row$packageInfoForRo2;
    return ___EmotionJSX(LevelBvRow, {
      levelBv: row.secondLevelBv,
      hasBonusLevel: row === null || row === void 0 ? void 0 : (_row$packageInfoForRo2 = row.packageInfoForRound) === null || _row$packageInfoForRo2 === void 0 ? void 0 : _row$packageInfoForRo2.hasMatch2Bonus,
      percents: 10
    });
  }
}, {
  name: '3rd Gen',
  render: function render(row) {
    var _row$packageInfoForRo3;
    return ___EmotionJSX(LevelBvRow, {
      levelBv: row.thirdLevelBv,
      hasBonusLevel: row === null || row === void 0 ? void 0 : (_row$packageInfoForRo3 = row.packageInfoForRound) === null || _row$packageInfoForRo3 === void 0 ? void 0 : _row$packageInfoForRo3.hasMatch3Bonus,
      percents: 20
    });
  }
}, {
  name: '4th Gen',
  render: function render(row) {
    var _row$packageInfoForRo4;
    return ___EmotionJSX(LevelBvRow, {
      levelBv: row.fourthLevelBv,
      hasBonusLevel: row === null || row === void 0 ? void 0 : (_row$packageInfoForRo4 = row.packageInfoForRound) === null || _row$packageInfoForRo4 === void 0 ? void 0 : _row$packageInfoForRo4.hasMatch4Bonus,
      percents: 25
    });
  }
}, {
  name: 'Sum',
  render: function render(row) {
    var _row$totalBv;
    return currencyByType((_row$totalBv = row === null || row === void 0 ? void 0 : row.totalBv) !== null && _row$totalBv !== void 0 ? _row$totalBv : 0, walletType.BV);
  }
}, {
  name: 'Bonus Cap',
  render: function render(row) {
    var _row$bonusCap;
    return currencyByType((_row$bonusCap = row === null || row === void 0 ? void 0 : row.bonusCap) !== null && _row$bonusCap !== void 0 ? _row$bonusCap : 0, walletType.CASH);
  }
}, {
  name: 'Total Bonus',
  width: '100px',
  render: function render(row) {
    var _row$amount;
    return currencyByType((_row$amount = row === null || row === void 0 ? void 0 : row.amount) !== null && _row$amount !== void 0 ? _row$amount : 0, walletType.CASH);
  }
}, {
  name: 'Paid Bonus',
  width: '100px',
  render: function render(row) {
    var _Math$min;
    return currencyByType((_Math$min = Math.min(row === null || row === void 0 ? void 0 : row.amount, row === null || row === void 0 ? void 0 : row.bonusCap)) !== null && _Math$min !== void 0 ? _Math$min : 0, walletType.CASH);
  }
}
// {
//   name: 'Details',
//   render: row => (
//     <Link to={`/bonus/matching/details/${row.id}`} className="vk-link">
//       Details
//     </Link>
//   ),
//   width: '120px',
// },
];