import React from 'react';
import { ReactComponent as EmptyIcon } from '../../../assets/images/layout-svg-icons/icn-empty.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoResults = function NoResults(_ref) {
  var emptyMessage = _ref.emptyMessage;
  return ___EmotionJSX("section", {
    className: "table-data__no-results__container"
  }, ___EmotionJSX(EmptyIcon, {
    className: "table-data__no-results__icon"
  }), ___EmotionJSX("p", {
    className: "table-data__no-results__subtitle"
  }, emptyMessage));
};
export var Error = function Error() {
  return ___EmotionJSX("section", {
    className: "table-data__error__container"
  }, ___EmotionJSX("div", {
    className: "table-data__error__icon"
  }, ___EmotionJSX("i", {
    className: "fa fa-exclamation la-3x"
  }), ___EmotionJSX("i", {
    className: "la la-exclamation-circle la-3x"
  })), ___EmotionJSX("h2", {
    className: "table-data__error__title"
  }, "Request failure."), ___EmotionJSX("p", {
    className: "table-data__error__subtitle"
  }, "There was problem getting the results.", ___EmotionJSX("br", null), "Please try again later."));
};