import React from 'react';
import BackButton from '../../../../../components/UI/Button/BackButton';
import CollapseItem from '../../components/CollapseGroup';
import { tokensAndSplitsData } from '../../tabs/faqsConfig';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TokensAndSplits = function TokensAndSplits() {
  return ___EmotionJSX("div", {
    className: "vk-info-center-inner-wrap"
  }, ___EmotionJSX("div", {
    className: "title-and-back"
  }, ___EmotionJSX(BackButton, null), ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Promotional Tokens & Splits")), ___EmotionJSX("div", {
    className: "collapse-group-paper"
  }, ___EmotionJSX(CollapseItem, {
    items: tokensAndSplitsData
  })));
};
export default TokensAndSplits;