function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { getColumnsDefinition } from '../components/TableDataComponents';
import BackButton from '../../../components/UI/Button/BackButton';
import { Table } from '../../../components/UI/Table';
import TableFooterBar from '../../../components/UI/Shared/TableFooterBar';
import TablesFilterBar from '../components/TablesFilterBar';
import { walletTransactionTitle, walletType } from '../../../enums/wallets';
import { getTransactionsByWallet } from '../../../services/Wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
var WalletTransHistoryArchive = function WalletTransHistoryArchive(props) {
  var wallets = props.wallets,
    currentWalletType = props.currentWalletType;
  var currentWallet = (wallets === null || wallets === void 0 ? void 0 : wallets.find(function (el) {
    return el.walletType === currentWalletType;
  })) || {};
  var showTable = currentWalletType !== walletType.TRADING && currentWalletType !== walletType.TOKEN || !!(currentWallet !== null && currentWallet !== void 0 && currentWallet.id);
  return ___EmotionJSX("div", {
    className: "vk-wallets-transaction-history-wraper"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 4,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true
  }, ___EmotionJSX(BackButton, null)), ___EmotionJSX(Grid, {
    item: true,
    md: true
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, walletTransactionTitle[currentWalletType], " Archive"))), showTable && ___EmotionJSX("div", {
    className: "vk-paper-box"
  }, currentWallet && currentWallet.id && ___EmotionJSX(Table, {
    getDataMethod: getTransactionsByWallet(currentWallet.id, true),
    getDataKey: "transactions",
    columns: getColumnsDefinition(currentWalletType),
    pageSize: 10,
    hasPagination: true,
    filterBar: function filterBar(options) {
      return TablesFilterBar(_objectSpread(_objectSpread({}, options), {}, {
        fromArchive: true
      }));
    },
    footerBar: TableFooterBar(),
    emptyMessage: "No Transactions to Show"
  })));
};
var mapProps = function mapProps(_ref) {
  var _accounts$current;
  var accounts = _ref.accounts;
  return {
    wallets: accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.wallets
  };
};
export default connect(mapProps)(WalletTransHistoryArchive);