import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CommunityTableSponsorCol = function CommunityTableSponsorCol(props) {
  var sponsorFullName = props.sponsorFullName,
    uplinkId = props.uplinkId;
  return ___EmotionJSX("div", {
    className: "vk-network-table-sponsor-col"
  }, ___EmotionJSX("h4", {
    className: "vk-network-table-sponsor-col-name"
  }, sponsorFullName), ___EmotionJSX("p", {
    className: "vk-network-table-sponsor-col-id"
  }, "(#", uplinkId, ")"));
};
CommunityTableSponsorCol.propTypes = {
  sponsorFullName: PropTypes.string,
  uplinkId: PropTypes.number
};
export default CommunityTableSponsorCol;