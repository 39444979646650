import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import _min from "lodash/min";
import _isNil from "lodash/isNil";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import hash from 'object-hash';
import { Grid } from '@material-ui/core';
import { PortletWrapper, PortletHeader, PortletBody } from '../../../components/UI/Portlet/Portlet';
import { Skeleton } from '../../../components/UI/Loader';
import ReactSelect from '../../../components/UI/ReactSelect';
import ValuePercent from '../../../components/UI/Shared/ValuePercent';
import { ReactComponent as BonusTimerIcn } from '../../../assets/images/layout-svg-icons/bonus-timer.svg';
import TimerCountdown from '../inner/TimerCountdown';
import { getNextMondayString } from '../../../utils/moment';
import BonusesRows from '../inner/BonusesRows';
import { jsx as ___EmotionJSX } from "@emotion/react";
var mapLabels = {
  0: 'This Week',
  1: 'Previous Week'
};
var DashboardBonuses = function DashboardBonuses(props) {
  var _currentBonus$network, _currentBonus$directB, _currentBonus$matchin, _currentBonus$startup;
  var bonuses = props.bonuses,
    user = props.user;
  var allBonuses = [bonuses === null || bonuses === void 0 ? void 0 : bonuses.current].concat(_toConsumableArray(bonuses === null || bonuses === void 0 ? void 0 : bonuses.history));
  var options = allBonuses.map(function (el, ind) {
    var _mapLabels$ind;
    return {
      label: (_mapLabels$ind = mapLabels[ind]) !== null && _mapLabels$ind !== void 0 ? _mapLabels$ind : el.targetPeriodName,
      value: ind
    };
  });
  var _useState = useState(options[0]),
    _useState2 = _slicedToArray(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var hasBonuses = !(bonuses !== null && bonuses !== void 0 && bonuses.isEmpty);
  var currentBonus = allBonuses[selected === null || selected === void 0 ? void 0 : selected.value];
  var isCurrentRound = (selected === null || selected === void 0 ? void 0 : selected.label) === mapLabels[0];
  var chineseProfile = user.country === 'China';
  useEffect(function () {
    setSelected(options[0]);
  }, [hash({
    bonuses: bonuses
  })]);
  var getBonusRows = [!chineseProfile && {
    title: 'Network Bonus',
    value: currentBonus === null || currentBonus === void 0 ? void 0 : (_currentBonus$network = currentBonus.networkBonus) === null || _currentBonus$network === void 0 ? void 0 : _currentBonus$network.amount,
    emptyMessage: 'N/A'
  }, {
    title: 'Direct Sale Bonus',
    value: currentBonus === null || currentBonus === void 0 ? void 0 : (_currentBonus$directB = currentBonus.directBonus) === null || _currentBonus$directB === void 0 ? void 0 : _currentBonus$directB.amount,
    emptyMessage: 'N/A'
  }, !chineseProfile && {
    title: 'Matching Bonus',
    value: currentBonus === null || currentBonus === void 0 ? void 0 : (_currentBonus$matchin = currentBonus.matchingBonus) === null || _currentBonus$matchin === void 0 ? void 0 : _currentBonus$matchin.amount,
    emptyMessage: 'N/A'
  }, {
    title: 'Startup Bonus',
    value: currentBonus === null || currentBonus === void 0 ? void 0 : (_currentBonus$startup = currentBonus.startupBonus) === null || _currentBonus$startup === void 0 ? void 0 : _currentBonus$startup.amount,
    emptyMessage: 'N/A'
  }, !isCurrentRound && {
    title: 'Bonus Cap',
    value: currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.bonusCap,
    emptyMessage: '€ 0'
  }, {
    title: 'Total Bonus',
    value: currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.total,
    emptyMessage: '€ 0'
  }, {
    title: isCurrentRound ? 'Bonus to be Paid' : 'Paid Bonus',
    value: isCurrentRound ? currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.total : _min([currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.total, currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.bonusCap]),
    emptyMessage: '€ 0'
  }].filter(function (el) {
    return el;
  });
  return ___EmotionJSX(PortletWrapper, {
    pwStyle: "vk-dashboard-bonuses"
  }, ___EmotionJSX(PortletHeader, null, ___EmotionJSX(Grid, {
    container: true,
    alignItems: "flex-end"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-header-left-top-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading-secondary-light"
  }, "My Bonuses"))), hasBonuses && ___EmotionJSX(Grid, {
    container: true,
    justify: "space-between",
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, !_isNil(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.total) ? ___EmotionJSX(ValuePercent, {
    value: currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.total
  }) : ___EmotionJSX(Skeleton, {
    height: 30,
    width: 90
  })), allBonuses.length > 1 && ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-header-select-wrap"
  }, ___EmotionJSX(ReactSelect, {
    options: options,
    onChange: setSelected,
    defaultValue: options[0],
    isSearchable: false
  })))))), ___EmotionJSX(PortletBody, null, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bonuses-body-left-title"
  }, "All Bonuses"), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-left-list-wrap"
  }, ___EmotionJSX(BonusesRows, {
    rows: getBonusRows,
    hasBonuses: hasBonuses
  }))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-wrap"
  }, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "Next Payment Round"), ___EmotionJSX(TimerCountdown, {
    expirationDate: getNextMondayString()
  }), ___EmotionJSX("div", {
    className: "vk-dashboard-bonuses-body-right-icon-wrap"
  }, ___EmotionJSX(BonusTimerIcn, null))))))));
};
export default connect(function (_ref) {
  var bonuses = _ref.bonuses,
    user = _ref.user;
  return {
    bonuses: bonuses,
    user: user
  };
})(DashboardBonuses);