function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import moment from 'moment';
export var SUBSCRIPTIONS_FILTER_FIELDS = {
  ACTIVE: 'active',
  CATALOGUE_ITEM_ACTIVE: 'catItemActive',
  CATALOGUE_ITEM_VISIBLE: 'catItemVisible',
  VENDOR: 'vendor'
};
export var getAllActiveSubscriptionsModel = function getAllActiveSubscriptionsModel(data) {
  return (data === null || data === void 0 ? void 0 : data.catalogueItems) && {
    catalogueItems: (data.catalogueItems.filter(function (el) {
      return el.active && el.visible;
    }) || []).map(function (el) {
      return _objectSpread(_objectSpread({}, el), {}, {
        subscriptionPlans: el.subscriptionPlans.filter(function (plan) {
          return plan.active;
        }).map(function (plan) {
          return _objectSpread(_objectSpread({}, plan), {}, {
            catalogueItemId: el.id
          });
        })
      });
    }),
    total: data.total
  };
};
export var getAccountGroupedSubscriptions = function getAccountGroupedSubscriptions(accountSubscriptions) {
  var groupedByCatItem = [];
  var processedVendorNames = [];
  var processedSubscriptionCatItemsIds = [];
  var addToProcessedSubscriptions = function addToProcessedSubscriptions(subscription) {
    processedVendorNames.push(subscription.vendor);
    processedSubscriptionCatItemsIds.push(subscription.subscriptionCatalogueItemId);
  };
  var getMappedSubscription = function getMappedSubscription(subscription) {
    var mappedSubscription = mapAccountSubscriptionToGroupedItem(subscription);
    mappedSubscription.totalPrice = subscription.price;
    mappedSubscription.activeSubscriptionPlans.push(subscription);
    return mappedSubscription;
  };
  accountSubscriptions.forEach(function (s) {
    var isFirsVendor = processedVendorNames.indexOf(s.vendor) === -1;
    var isFirstCatItem = processedSubscriptionCatItemsIds.indexOf(s.subscriptionCatalogueItemId) === -1;
    if (isFirsVendor) {
      addToProcessedSubscriptions(s);
      var mappedSubscription = getMappedSubscription(s);
      groupedByCatItem.push(mappedSubscription);
    } else if (isFirstCatItem) {
      processedSubscriptionCatItemsIds.push(s.subscriptionCatalogueItemId);
      var _mappedSubscription = getMappedSubscription(s);
      groupedByCatItem.push(_mappedSubscription);
    } else {
      var processedSubscription = groupedByCatItem.find(function (processed) {
        return processed.subscriptionCatalogueItemId === s.subscriptionCatalogueItemId;
      });
      processedSubscription && processedSubscription.activeSubscriptionPlans.push(s);
    }
  });
  var result = groupedByCatItem.map(addTotalsToAccountSubscription);
  return result;
};

// PRIVATE
var mapAccountSubscriptionToGroupedItem = function mapAccountSubscriptionToGroupedItem(subscription) {
  if (!subscription) return {};
  var subscriptionCatalogueItemId = subscription.subscriptionCatalogueItemId,
    vendor = subscription.vendor,
    picThumbnailUrl = subscription.picThumbnailUrl,
    pictureUrl = subscription.pictureUrl;
  var mappedResult = {
    subscriptionCatalogueItemId: subscriptionCatalogueItemId,
    vendor: vendor,
    picThumbnailUrl: picThumbnailUrl,
    pictureUrl: pictureUrl,
    totalValidUntil: null,
    validFrom: null,
    totalDuration: null,
    activeSubscriptionPlans: []
  };
  return mappedResult;
};
var addTotalsToAccountSubscription = function addTotalsToAccountSubscription(s) {
  var mappedSubscription = _objectSpread({}, s);
  var totalPrice = s.activeSubscriptionPlans.length === 1 ? s.activeSubscriptionPlans[0].price : s.activeSubscriptionPlans.map(function (p) {
    return p.price;
  }).reduce(function (acc, curr) {
    return acc + curr;
  }, 0);
  var totalDuration = s.activeSubscriptionPlans.length === 1 ? s.activeSubscriptionPlans[0].duration : s.activeSubscriptionPlans.map(function (p) {
    return p.duration;
  }).reduce(function (prev, curr) {
    return prev + curr;
  }, 0);
  mappedSubscription.totalPrice = totalPrice;
  mappedSubscription.totalDuration = totalDuration;
  var lastPlan = s.activeSubscriptionPlans[s.activeSubscriptionPlans.length - 1];
  mappedSubscription.totalValidUntil = getValidUntil(lastPlan.validFrom, lastPlan.duration);
  mappedSubscription.validFrom = s.activeSubscriptionPlans[0].validFrom;
  return mappedSubscription;
};
var getValidUntil = function getValidUntil(fromDate, duration) {
  var validUntil = moment(fromDate).add(duration, 'months');
  return validUntil.toString();
};