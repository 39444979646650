import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import _omit from "lodash/omit";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.array.is-array.js";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TextInput from './TextInput';
import Button from '../Button/Button';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ActionInput = function ActionInput(_ref) {
  var inputProps = _ref.inputProps,
    buttonProps = _ref.buttonProps,
    className = _ref.className,
    onClick = _ref.onClick;
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    inputValue = _useState2[0],
    setInputValue = _useState2[1];
  useEffect(function () {
    setInputValue(inputProps === null || inputProps === void 0 ? void 0 : inputProps.value);
  }, [inputProps === null || inputProps === void 0 ? void 0 : inputProps.value]);
  var handleInputChange = function handleInputChange(_ref2) {
    var value = _ref2.target.value;
    setInputValue(value);
    _isFunction(inputProps === null || inputProps === void 0 ? void 0 : inputProps.handleChange) && inputProps.handleChange(value);
  };
  return ___EmotionJSX("div", {
    className: clsx('vk-link-input-wrapper', className)
  }, ___EmotionJSX(TextInput, _extends({
    className: "action-input",
    value: inputValue,
    handleChange: handleInputChange
  }, _omit(inputProps, ['value', 'handleChange']))), ___EmotionJSX(Button, _extends({
    btnType: "vk-outline-btn",
    btnSize: "vk-btn-size-l",
    clicked: function clicked() {
      return onClick(inputValue);
    },
    customClass: "vk-link-input-btn"
  }, buttonProps), buttonProps.children));
};
ActionInput.propTypes = {
  inputProps: PropTypes.object,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  copy: PropTypes.bool
};
export default ActionInput;