import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import React from 'react';
import PropTypes from 'prop-types';
import { div } from 'exact-math';
import Button from '../../../components/UI/Button/Button';
import TimerCountdown from '../inner/TimerCountdown';
import { getInformation } from '../../../utils/mockTransferInformation';
import { transferPanelType, transferStatuses } from '../../../enums/transferCoinWallet';
import { ReactComponent as PaymentPendingIcn } from '../../../assets/images/layout-svg-icons/img-megaphone-illy.svg';
import { ReactComponent as TransferPendingIcn } from '../../../assets/images/layout-svg-icons/img-transfer-illy.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DashboardTransferProcessPanel = function DashboardTransferProcessPanel(props) {
  var type = props.type,
    accounts = props.accounts,
    currentAccount = props.currentAccount,
    showCountdown = props.showCountdown,
    totalCashFee = props.totalCashFee,
    singleCashFee = props.singleCashFee,
    expirationDate = props.expirationDate,
    onSubmit = props.onSubmit,
    onExpire = props.onExpire,
    cashWalletBalance = props.cashWalletBalance;
  var messageParams = {
    accounts: accounts,
    count: accounts.length,
    currentFee: singleCashFee,
    allFee: totalCashFee
  };
  var transferPanelInformation = getInformation(type, messageParams);
  var handleSubmitForAll = function handleSubmitForAll() {
    return onSubmit(true);
  };
  var handleSubmitForCurrent = function handleSubmitForCurrent() {
    return onSubmit(false);
  };
  var notCurrentAccounts = accounts.filter(function (a) {
    return !a.isCurrent;
  });
  var paymentFailedAccounts = accounts.filter(function (a) {
    return a.transferStatus === transferStatuses.INITIATED_RETRY;
  });
  var currentAccountPayable = (currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.transferStatus) === transferStatuses.NONE || (currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.transferStatus) === transferStatuses.INITIATED_RETRY;
  var isTransferCurrentAccountDisabled = (currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.transferStatus) === transferStatuses.PENDING || (currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.transferStatus) === transferStatuses.ERROR;
  var hasCash = cashWalletBalance >= div(singleCashFee || 0, 2);
  var hasCashForAll = cashWalletBalance >= div(totalCashFee || 0, 2);
  var getNoCashMessage = function getNoCashMessage() {
    var message = '';
    if (!hasCashForAll && notCurrentAccounts.length >= 1) message = 'You do not have enough balance into cash wallet to start deployment for all accounts';
    if (!hasCash && currentAccountPayable) message = 'You do not have enough balance into cash wallet to start deployment';
    return message;
  };
  var paymentActions = ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "dashboard-migrations-information-buttons-container"
  }, notCurrentAccounts.length >= 1 && hasCashForAll && ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    clicked: handleSubmitForAll
  }, transferPanelInformation.primaryBtnText), currentAccountPayable && hasCash && ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-m",
    clicked: handleSubmitForCurrent
  }, transferPanelInformation.secondaryBtnText), (currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.transferStatus) === transferStatuses.INITIATED && ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-m",
    clicked: handleSubmitForCurrent,
    disabled: true
  }, "Pay For Current Account Is In Progress...")), (!hasCash || !hasCashForAll) && ___EmotionJSX("b", null, getNoCashMessage()));
  var transferActions = ___EmotionJSX("div", {
    className: "dashboard-migrations-information-buttons-container"
  }, notCurrentAccounts.length >= 1 && ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    clicked: handleSubmitForAll
  }, transferPanelInformation.primaryBtnText), (currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.transferStatus) === transferStatuses.PAID && ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-m",
    clicked: handleSubmitForCurrent
  }, transferPanelInformation.secondaryBtnText), isTransferCurrentAccountDisabled && ___EmotionJSX(Button, {
    btnType: "vk-secondary-btn",
    btnSize: "vk-btn-size-m",
    clicked: handleSubmitForCurrent,
    disabled: true
  }, "Deployment Of Current Account", ' ', (currentAccount === null || currentAccount === void 0 ? void 0 : currentAccount.transferStatus) === transferStatuses.PENDING ? 'Is In Progress...' : 'Failed'));
  return ___EmotionJSX(React.Fragment, null, paymentFailedAccounts.length > 0 && ___EmotionJSX("div", {
    className: "dashboard-migrations payment-failed"
  }, ___EmotionJSX("div", {
    className: "dashboard-migrations-information"
  }, ___EmotionJSX("h3", null, "Failed payments"), ___EmotionJSX("p", null, "Payment for ".concat(paymentFailedAccounts.length === 1 ? 'account' : 'accounts', " #").concat(paymentFailedAccounts.map(function (el) {
    return el.accountId;
  }).join(', #'), " failed. You can try again with the payment options below."), ___EmotionJSX("br", null), "\xA0"))), ___EmotionJSX("div", {
    className: "dashboard-migrations"
  }, ___EmotionJSX("div", {
    className: "dashboard-migrations-information"
  }, ___EmotionJSX("h3", null, transferPanelInformation.title), ___EmotionJSX("p", null, transferPanelInformation.description), type === transferPanelType.PAYMENT ? paymentActions : transferActions), ___EmotionJSX("div", {
    className: "dashboard-migration-countdown"
  }, showCountdown && ___EmotionJSX("div", null, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "Deployment ends in"), ___EmotionJSX(TimerCountdown, {
    expirationDate: expirationDate,
    onExpire: onExpire
  })), ___EmotionJSX("div", {
    className: transferPanelInformation.iconClassName
  }, type === transferPanelType.PAYMENT ? ___EmotionJSX(PaymentPendingIcn, null) : ___EmotionJSX(TransferPendingIcn, null)))));
};
DashboardTransferProcessPanel.propTypes = {
  type: PropTypes.string,
  accounts: PropTypes.array,
  currentAccount: PropTypes.object,
  showCountdown: PropTypes.bool,
  totalCashFee: PropTypes.number,
  singleCashFee: PropTypes.number,
  expirationDate: PropTypes.string,
  onSubmit: PropTypes.func,
  onExpire: PropTypes.func,
  cashWalletBalance: PropTypes.number
};
export default DashboardTransferProcessPanel;