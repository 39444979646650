function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _isFunction from "lodash/isFunction";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { validationsRegexp } from 'utils/validations';
import ReactSelect from '../ReactSelect';
import countryList from '../../../enums/countries';
import { countryPhoneCodeOption, countryPhoneCodeSingleValue } from '../Shared/countrySelectOptions';
import { ReactComponent as ErrorIcn } from '../../../assets/images/layout-svg-icons/icn-alert-warning.svg';
import { ReactComponent as SuccessIcn } from '../../../assets/images/layout-svg-icons/icn-sm-checkmark-tick.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var countryListOptions = countryList.map(function (el) {
  return _objectSpread(_objectSpread({}, el), {}, {
    value: "".concat(el.value, "/").concat(el.phoneCode)
  });
});
var PhoneInput = function PhoneInput(props) {
  var _selectRef$current, _countryListOptions$f, _value$split$;
  var type = props.type,
    label = props.label,
    name = props.name,
    placeholder = props.placeholder,
    value = props.value,
    error = props.error,
    errorMsg = props.errorMsg,
    success = props.success,
    handleBlur = props.handleBlur,
    handleChange = props.handleChange,
    min = props.min,
    max = props.max,
    disabled = props.disabled;
  var selectRef = useRef();
  var selectStyles = _objectSpread(_objectSpread({}, (_selectRef$current = selectRef.current) === null || _selectRef$current === void 0 ? void 0 : _selectRef$current.colorStyles), {}, {
    option: function option() {
      var _selectRef$current2, _selectRef$current2$c;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return _objectSpread(_objectSpread({}, (_selectRef$current2 = selectRef.current) === null || _selectRef$current2 === void 0 ? void 0 : (_selectRef$current2$c = _selectRef$current2.colorStyles).option.apply(_selectRef$current2$c, args)), {}, {
        paddingLeft: 8,
        paddingRight: 8
      });
    }
  });
  var selectValue = (_countryListOptions$f = countryListOptions.find(function (el) {
    return el.phoneCode === value.split(' ')[0];
  })) !== null && _countryListOptions$f !== void 0 ? _countryListOptions$f : null;
  var inputValue = value.indexOf(' ') === -1 || !selectValue ? value : (_value$split$ = value.split(' ')[1]) !== null && _value$split$ !== void 0 ? _value$split$ : '';
  var _useState = useState(selectValue),
    _useState2 = _slicedToArray(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var _useState3 = useState(inputValue),
    _useState4 = _slicedToArray(_useState3, 2),
    inputVal = _useState4[0],
    setInputVal = _useState4[1];
  useEffect(function () {
    (selectValue === null || selectValue === void 0 ? void 0 : selectValue.phoneCode) !== (selected === null || selected === void 0 ? void 0 : selected.phoneCode) && setSelected(selectValue);
    inputValue !== inputVal && setInputVal(inputValue);
  }, [selectValue, inputValue]);
  var inputClasses = ['vk-form-control'];
  var formGroupClasses = ['vk-form-group vk-form-select'];
  if (error) {
    inputClasses.push('vk-invalid-input');
    formGroupClasses.push('vk-invalid-group');
  }
  if (success) {
    inputClasses.push('vk-valid-input');
    formGroupClasses.push('vk-valid-group');
  }
  var handleSelect = function handleSelect(selectedVal) {
    setSelected(selectedVal);
    var event = {
      target: {
        name: name,
        value: inputVal ? "".concat(selectedVal.phoneCode, " ").concat(inputVal) : ''
      }
    };
    _isFunction(handleChange) && handleChange(event);
  };
  var handleTextChange = function handleTextChange(_ref) {
    var _ref$target = _ref.target,
      name = _ref$target.name,
      value = _ref$target.value;
    if (!validationsRegexp.onlyNumbers.test(value)) return;
    setInputVal(value);
    var event = {
      target: {
        name: name,
        value: "".concat(selected.phoneCode, " ").concat(value)
      }
    };
    _isFunction(handleChange) && handleChange(event);
  };
  return ___EmotionJSX("div", {
    className: formGroupClasses.join(' ')
  }, label && ___EmotionJSX("label", {
    className: "vk-form-label"
  }, label), ___EmotionJSX(Grid, {
    container: true,
    spacing: 0
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-phone-code-select-wrap"
  }, ___EmotionJSX(ReactSelect, {
    ref: selectRef,
    options: countryListOptions,
    components: {
      Option: countryPhoneCodeOption,
      SingleValue: countryPhoneCodeSingleValue
    },
    isDisabled: disabled,
    placeholder: "Code",
    value: selected,
    onChange: function onChange(value) {
      return handleSelect(value);
    },
    defaultValue: {
      label: '',
      value: ''
    },
    styles: selectStyles
  }))), ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("input", {
    placeholder: placeholder,
    className: inputClasses.join(' '),
    type: type,
    name: name,
    onBlur: handleBlur,
    disabled: !selected || disabled,
    onChange: handleTextChange,
    value: inputVal,
    min: min,
    max: max
  }))), ___EmotionJSX("div", {
    className: "vk-input-validation-message vk-ivm-error"
  }, ___EmotionJSX("i", null, ___EmotionJSX(ErrorIcn, null)), ___EmotionJSX("span", null, errorMsg)), ___EmotionJSX("div", {
    className: "vk-input-validation-message vk-ivm-success"
  }, ___EmotionJSX("i", null, ___EmotionJSX(SuccessIcn, null)), ___EmotionJSX("span", null, "".concat(label, " is correct"))));
};
PhoneInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.any,
  value: PropTypes.any,
  success: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  async: PropTypes.bool,
  dynamicValue: PropTypes.bool,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number
};
export default PhoneInput;