import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _mapIcons, _mapMessages;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import succeededIcon from '../../../assets/images/icons/icn-succeeded.svg';
import failedIcon from '../../../assets/images/icons/icn-failed.svg';
import waitingIcon from '../../../assets/images/icons/icn-waiting.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var STATUS_TYPES = {
  WAIT: 'waiting',
  FAIL: 'failed',
  SUCCESS: 'succeeded'
};
var mapIcons = (_mapIcons = {}, _defineProperty(_mapIcons, STATUS_TYPES.SUCCESS, succeededIcon), _defineProperty(_mapIcons, STATUS_TYPES.FAIL, failedIcon), _defineProperty(_mapIcons, STATUS_TYPES.WAIT, waitingIcon), _mapIcons);
var mapMessages = (_mapMessages = {}, _defineProperty(_mapMessages, STATUS_TYPES.SUCCESS, 'Success'), _defineProperty(_mapMessages, STATUS_TYPES.FAIL, 'Something went wrong.'), _defineProperty(_mapMessages, STATUS_TYPES.WAIT, 'Waiting for confirmation.'), _mapMessages);
var StatusIndicator = function StatusIndicator(_ref) {
  var onSuccess = _ref.onSuccess,
    onFail = _ref.onFail,
    status = _ref.status,
    message = _ref.message,
    successButtonText = _ref.successButtonText,
    failureButtonText = _ref.failureButtonText;
  return ___EmotionJSX("div", {
    className: "vk-status-indicator-container"
  }, ___EmotionJSX("div", {
    className: "vk-status-indicator-content"
  }, ___EmotionJSX("div", {
    className: "vk-status-indicator-icon"
  }, ___EmotionJSX("img", {
    src: mapIcons[status],
    width: 136,
    alt: "icon"
  })), ___EmotionJSX("h4", null, mapMessages[status]), message && ___EmotionJSX("p", null, message)), status !== STATUS_TYPES.WAIT && ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    clicked: status === STATUS_TYPES.SUCCESS ? onSuccess : onFail
  }, status === STATUS_TYPES.SUCCESS ? successButtonText : failureButtonText));
};
StatusIndicator.propTypes = {
  status: PropTypes.string,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  message: PropTypes.string,
  successButtonText: PropTypes.string,
  failureButtonText: PropTypes.string
};
export default StatusIndicator;