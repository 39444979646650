import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import luckyWheelBackgroundLogo from '../../../assets/images/bonuses/lucky-wheel-background.png';
import luckyWheelPointerLogo from '../../../assets/images/bonuses/lucky-wheel-pointer.png';
import luckyWheelGoldPlateLogo from '../../../assets/images/bonuses/lucky-wheel-gold-plate.png';
import luckyWheelSectorsLogo from '../../../assets/images/bonuses/lucky-wheel-sectors.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var LuckyWheel = function LuckyWheel(_ref) {
  var winningSegment = _ref.winningSegment,
    onFinished = _ref.onFinished;
  var sectorsRef = useRef(null);
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    rotateAngel = _useState2[0],
    setRotateAngel = _useState2[1];
  useEffect(function () {
    sectorsRef.current.addEventListener('transitionend', onFinished);
    return function () {
      sectorsRef.current.removeEventListener('transitionend', onFinished);
    };
  }, []);
  var calculateRotateAngel = function calculateRotateAngel() {
    if (rotateAngel) return;
    var randomAngel = Math.floor(Math.random() * 80 + 5);
    setRotateAngel(4680 + winningSegment * 90 + randomAngel);
  };
  return ___EmotionJSX("div", {
    className: "vk-lucky-wheel-container",
    style: {
      backgroundImage: "url(".concat(luckyWheelBackgroundLogo, ")")
    }
  }, ___EmotionJSX("div", {
    className: "vk-lucky-wheel-content"
  }, ___EmotionJSX("img", {
    className: "vk-lucky-wheel-pointer",
    src: luckyWheelPointerLogo,
    alt: "Wheel Pointer"
  }), ___EmotionJSX("img", {
    className: "vk-lucky-wheel-gold-plate",
    src: luckyWheelGoldPlateLogo,
    alt: "Wheel Gold Plate"
  }), ___EmotionJSX("img", {
    ref: sectorsRef,
    className: "vk-lucky-wheel-sectors",
    src: luckyWheelSectorsLogo,
    alt: "Wheel Sectors",
    onClick: calculateRotateAngel,
    style: {
      transform: "rotate(".concat(rotateAngel !== null && rotateAngel !== void 0 ? rotateAngel : 0, "deg)"),
      cursor: !rotateAngel ? 'pointer' : 'not-allowed'
    }
  })));
};
export default LuckyWheel;