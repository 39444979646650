import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
export var bonusesActionTypes = {
  FulfillBonuses: '[Fulfill] Initialize earned account bonuses',
  Delete: '[Delete] Erase earned bonuses'
};
var initialState = {
  current: {
    total: null,
    startup: null,
    direct: null,
    network: null,
    matching: null
  },
  history: []
};
export var bonusesReducer = persistReducer({
  storage: storage,
  key: 'bonuses'
}, function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case bonusesActionTypes.FulfillBonuses:
      {
        var _action$payload$bonus = action.payload.bonuses,
          bonuses = _action$payload$bonus === void 0 ? {} : _action$payload$bonus;
        return bonuses;
      }
    case bonusesActionTypes.Delete:
      {
        return initialState;
      }
    default:
      return state;
  }
});
export var bonusesActions = {
  fulfillBonuses: function fulfillBonuses(bonuses) {
    return {
      type: bonusesActionTypes.FulfillBonuses,
      payload: {
        bonuses: bonuses
      }
    };
  },
  deleteBonuses: function deleteBonuses() {
    return {
      type: bonusesActionTypes.Delete
    };
  }
};