import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import currencyByType from '../../../utils/currencyByType';
import { walletType } from '../../../enums/wallets';
export var getColumnsDefinition = function getColumnsDefinition() {
  return [{
    name: 'Date',
    value: 'date',
    width: '150px',
    dateTimeFormat: 'DD/MM/YYYY'
  }, {
    name: 'Name',
    value: 'name'
  }, {
    name: 'Nickname',
    value: 'nickName'
  }, {
    name: 'Phone number',
    value: 'phone'
  }, {
    name: 'Account number',
    value: 'downlinkId'
  }, {
    name: 'Module',
    value: 'accountPackage'
  }, {
    name: 'Total BV',
    render: function render(row) {
      var _row$amount;
      return currencyByType((_row$amount = row.amount) !== null && _row$amount !== void 0 ? _row$amount : 0, walletType.BV);
    }
  }].filter(function (el) {
    return el;
  });
};