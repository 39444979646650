import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.date.to-string.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import starterLogo from '../../../assets/images/logos/one-life-logо.png';
import { ReactComponent as DetailsIcn } from '../../../assets/images/layout-svg-icons/three-dots.svg';
import { pluralizeMonths } from '../../../utils/monthYearCalculator';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SubscriptionCard = function SubscriptionCard(props) {
  var _ref, _item$picThumbnailUrl;
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item;
  var totalDuration = item.totalDuration,
    vendor = item.vendor,
    validFrom = item.validFrom,
    totalValidUntil = item.totalValidUntil;
  var durationLabel = "".concat(totalDuration % 12 === 0 ? totalDuration / 12 : totalDuration, " ").concat(pluralizeMonths(totalDuration));
  return ___EmotionJSX("div", {
    className: "subscription-card"
  }, ___EmotionJSX("div", {
    className: "subscription-card-top"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 12
  }, ___EmotionJSX("img", {
    src: (_ref = (_item$picThumbnailUrl = item === null || item === void 0 ? void 0 : item.picThumbnailUrl) !== null && _item$picThumbnailUrl !== void 0 ? _item$picThumbnailUrl : item === null || item === void 0 ? void 0 : item.pictureUrl) !== null && _ref !== void 0 ? _ref : starterLogo,
    className: "vk-image-responsive subscription-image",
    alt: "img-subscription-card"
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "image-bottom"
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 10,
    className: "subscription-item"
  }, ___EmotionJSX("h3", {
    className: "subscription-heading vk-page-heading"
  }, vendor), ___EmotionJSX("br", null), ___EmotionJSX("div", {
    className: "subscription-duration vk-page-heading"
  }, durationLabel), ___EmotionJSX("div", {
    className: "subscription-date vk-page-heading"
  }, "Active from: ".concat(new Date(validFrom).toLocaleDateString()), ___EmotionJSX("br", null), "Active until: ".concat(new Date(totalValidUntil).toLocaleDateString()))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 10,
    className: "subscription-item"
  }, ___EmotionJSX(Link, {
    to: {
      pathname: '/shop/all-subscriptions',
      state: {
        vendor: vendor
      }
    }
  }, ___EmotionJSX("div", {
    className: "subscription-actions vk-page-heading"
  }, ___EmotionJSX(DetailsIcn, null)))))));
};
SubscriptionCard.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func,
  cashWallet: PropTypes.object
};
export default SubscriptionCard;