import React from 'react';
import networkBonusExample from '../../../assets/images/bonuses/network-bonus-example.png';
import networkBonusFullExample from '../../../assets/images/bonuses/network-bonus-full-example.png';
import afterNetworkBonusExample from '../../../assets/images/bonuses/after-network-bonus-example.png';
import afterBonusCapExample from '../../../assets/images/bonuses/after-bonus-cap-example.png';
import afterNetworkBonusFullExample from '../../../assets/images/bonuses/after-network-bonus-full-example.png';
import powerLegIcon from '../../../assets/images/bonuses/power-leg-icon.png';
import weakerLegIcon from '../../../assets/images/bonuses/weaker-leg-icon.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkBonusInfo = function NetworkBonusInfo() {
  return ___EmotionJSX("div", null, ___EmotionJSX("h1", {
    className: "bonus-help-modal-title"
  }, "NETWORK BONUS"), ___EmotionJSX("div", {
    className: "bonus-help-modal-chapter"
  }, ___EmotionJSX("h2", null, "Requirements"), ___EmotionJSX("div", {
    className: "bonus-help-modal-description"
  }, ___EmotionJSX("p", null, "An IMA receives 10% of the Business Volume accumulated by side of his network structure containing less BVs."), ___EmotionJSX("p", null, "Each IMA who have redeemed at least a Starter Educational Module is eligible for Network Bonus.")), ___EmotionJSX("div", {
    className: "network-help-modal-container"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-example-box"
  }, ___EmotionJSX("span", null, "Example:"), ___EmotionJSX("div", {
    className: "bonus-help-modal-example-text-box"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: networkBonusExample,
    className: "bonus-help-modal-example-image"
  }), ___EmotionJSX("div", {
    className: "bonus-help-modal-example-text"
  }, ___EmotionJSX("div", null, "2000 BV ", ___EmotionJSX("span", null, '<'), " 10000 BV"), ___EmotionJSX("div", null, ___EmotionJSX("span", null, "BONUS: \u20AC200")), ___EmotionJSX("div", null, "(10% of WL value)")))), ___EmotionJSX("img", {
    alt: "bonus_example",
    src: networkBonusFullExample,
    className: "bonus-help-modal-image"
  }))), ___EmotionJSX("div", {
    className: "bonus-help-modal-chapter"
  }, ___EmotionJSX("h2", null, "After Network Bonus"), ___EmotionJSX("div", {
    className: "bonus-help-modal-description"
  }, ___EmotionJSX("p", null, "After Network Bonus is generated, WL value is substracted from PL value (leg with higher generation of BVs) and the result forms the new PL value. WL value is set to 0.")), ___EmotionJSX("div", {
    className: "network-help-modal-container"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-example-box"
  }, ___EmotionJSX("span", null, "Example:"), ___EmotionJSX("div", {
    className: "bonus-help-modal-example-text-box"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: networkBonusExample,
    className: "bonus-help-modal-example-image"
  }), ___EmotionJSX("div", {
    className: "bonus-help-modal-example-text"
  }, ___EmotionJSX("h4", null, "10000 BV - 2000 BV = 8000 BV")), ___EmotionJSX("img", {
    alt: "bonus_example",
    src: afterNetworkBonusExample,
    className: "bonus-help-modal-example-image"
  }))), ___EmotionJSX("img", {
    alt: "bonus_example",
    src: afterNetworkBonusFullExample,
    className: "bonus-help-modal-image"
  }), ___EmotionJSX("div", {
    className: "bonus-help-modal-bottom-box"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-leg-icon-container"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: powerLegIcon,
    className: "bonus-help-modal-leg-icon"
  }), ___EmotionJSX("p", null, "VALUE")), ___EmotionJSX("div", {
    className: "bonus-help-modal-dashes-container"
  }, ___EmotionJSX("div", null)), ___EmotionJSX("div", {
    className: "bonus-help-modal-leg-icon-container"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: weakerLegIcon,
    className: "bonus-help-modal-leg-icon"
  }), ___EmotionJSX("p", null, "VALUE")), ___EmotionJSX("div", {
    className: "bonus-help-modal-dashes-container"
  }, ___EmotionJSX("div", null), ___EmotionJSX("div", null)), ___EmotionJSX("div", {
    className: "bonus-help-modal-leg-icon-container"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: powerLegIcon,
    className: "bonus-help-modal-leg-icon"
  }), ___EmotionJSX("p", null, "NEW VALUE")))), ___EmotionJSX("br", null), ___EmotionJSX("div", {
    className: "bonus-help-modal-description"
  }, ___EmotionJSX("p", null, "The last redeemed module has it's limitations for the bonus capacity which cannot be exceeded. If the bonus cap is hitted, the paid bonus will be equal to the bonus cap. In this case, WL and PL values are both set to 0.")), ___EmotionJSX("div", {
    className: "network-help-modal-container"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-example-box"
  }, ___EmotionJSX("span", null, "Example:"), ___EmotionJSX("div", {
    className: "bonus-help-modal-example-text-box"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: networkBonusExample,
    className: "bonus-help-modal-example-image"
  }), ___EmotionJSX("div", {
    className: "bonus-help-modal-example-text"
  }, ___EmotionJSX("div", null, ___EmotionJSX("div", null, "Bonus: \u20AC200"), ___EmotionJSX("div", null, "Bonus Cap: \u20AC150"), ___EmotionJSX("div", null, "\u20AC150 ", ___EmotionJSX("span", null, '<'), " \u20AC200")), ___EmotionJSX("div", null, ___EmotionJSX("span", null, "BONUS: \u20AC150"))), ___EmotionJSX("img", {
    alt: "bonus_example",
    src: afterBonusCapExample,
    className: "bonus-help-modal-example-image"
  }))))));
};
export default NetworkBonusInfo;