import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import _isNil from "lodash/isNil";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import hash from 'object-hash';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { PortletWrapper, PortletHeader, PortletBody } from '../../../components/UI/Portlet/Portlet';
import Button from '../../../components/UI/Button/Button';
import { Skeleton } from '../../../components/UI/Loader';
import ReactSelect from '../../../components/UI/ReactSelect';
import ValuePercent from '../../../components/UI/Shared/ValuePercent';
import { walletType } from '../../../enums/wallets';
import { ReactComponent as LeftSideIcn } from '../../../assets/images/layout-svg-icons/icn-leftleg.svg';
import { ReactComponent as RightSideIcn } from '../../../assets/images/layout-svg-icons/icn-rightleg.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var mapLabels = {
  0: 'This Week',
  1: 'Previous Week'
};
var DashboardBvPoints = function DashboardBvPoints(props) {
  var _allBonuses$selected$, _bonusRound$amount, _bonusRound$leftBv, _bonusRound$rightBv, _bonusRound$leftAffil, _bonusRound$rightAffi;
  var bonuses = props.bonuses;
  var allBonuses = [bonuses === null || bonuses === void 0 ? void 0 : bonuses.current].concat(_toConsumableArray(bonuses === null || bonuses === void 0 ? void 0 : bonuses.history));
  var options = allBonuses.map(function (el, ind) {
    var _mapLabels$ind;
    return {
      label: (_mapLabels$ind = mapLabels[ind]) !== null && _mapLabels$ind !== void 0 ? _mapLabels$ind : el.targetPeriodName,
      value: ind
    };
  });
  var _useState = useState(options[0]),
    _useState2 = _slicedToArray(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var hasBonuses = !(bonuses !== null && bonuses !== void 0 && bonuses.isEmpty);
  var bonusRound = (_allBonuses$selected$ = allBonuses[selected === null || selected === void 0 ? void 0 : selected.value]) === null || _allBonuses$selected$ === void 0 ? void 0 : _allBonuses$selected$.networkBonus;
  useEffect(function () {
    setSelected(options[0]);
  }, [hash({
    bonuses: bonuses
  })]);
  return ___EmotionJSX(PortletWrapper, {
    pwStyle: "vk-dashboard-bv-points"
  }, ___EmotionJSX(PortletHeader, null, ___EmotionJSX(Grid, {
    container: true,
    alignItems: "flex-end"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-header-left-top-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading-secondary-light"
  }, "Network BV"))), hasBonuses && ___EmotionJSX(Grid, {
    container: true,
    justify: "space-between",
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, !_isNil(bonusRound) ? ___EmotionJSX(ValuePercent, {
    value: (_bonusRound$amount = bonusRound.amount) !== null && _bonusRound$amount !== void 0 ? _bonusRound$amount : 0,
    currency: walletType.BV
  }) : ___EmotionJSX(Skeleton, {
    width: 70,
    height: 30
  })), allBonuses.length > 1 && ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-header-select-wrap"
  }, ___EmotionJSX(ReactSelect, {
    options: options,
    onChange: setSelected,
    isSearchable: false,
    defaultValue: options[0]
  })))))), ___EmotionJSX(PortletBody, null, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-body"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 6
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-body-point-side"
  }, ___EmotionJSX("h4", {
    className: "vk-dashboard-bv-points-body-point-side-title"
  }, "LEFT"), ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-body-point-side-amount-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-mr-8"
  }, ___EmotionJSX(LeftSideIcn, null)), hasBonuses ? !_isNil(bonusRound) ? ___EmotionJSX("h4", {
    className: "vk-dashboard-bv-points-body-point-side-amount"
  }, (_bonusRound$leftBv = bonusRound.leftBv) !== null && _bonusRound$leftBv !== void 0 ? _bonusRound$leftBv : 0, " BVP") : ___EmotionJSX(Skeleton, {
    height: 21,
    width: 60
  }) : 'N/A'))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 6
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-body-point-side vk-dashboard-bv-points-right"
  }, ___EmotionJSX("h4", {
    className: "vk-dashboard-bv-points-body-point-side-title"
  }, "RIGHT"), ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-body-point-side-amount-wrap"
  }, hasBonuses ? !_isNil(bonusRound) ? ___EmotionJSX("h4", {
    className: "vk-dashboard-bv-points-body-point-side-amount"
  }, (_bonusRound$rightBv = bonusRound.rightBv) !== null && _bonusRound$rightBv !== void 0 ? _bonusRound$rightBv : 0, " BVP") : ___EmotionJSX(Skeleton, {
    height: 21,
    width: 60
  }) : 'N/A', ___EmotionJSX("div", {
    className: "vk-ml-8"
  }, ___EmotionJSX(RightSideIcn, null))))), ___EmotionJSX(Grid, {
    item: true,
    xs: 6
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-body-point-label-value-wrap"
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bv-points-body-point-label"
  }, "Left ", ___EmotionJSX("br", null), "Sponsored"), hasBonuses ? !_isNil(bonusRound) ? ___EmotionJSX("h4", {
    className: "vk-dashboard-bv-points-body-point-value"
  }, (_bonusRound$leftAffil = bonusRound.leftAffiliates) !== null && _bonusRound$leftAffil !== void 0 ? _bonusRound$leftAffil : 0) : ___EmotionJSX(Skeleton, {
    height: 21,
    width: 60
  }) : 'N/A')), ___EmotionJSX(Grid, {
    item: true,
    xs: 6
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-body-point-label-value-wrap"
  }, ___EmotionJSX("p", {
    className: "vk-dashboard-bv-points-body-point-label"
  }, "Right ", ___EmotionJSX("br", null), "Sponsored"), hasBonuses ? !_isNil(bonusRound) ? ___EmotionJSX("h4", {
    className: "vk-dashboard-bv-points-body-point-value"
  }, (_bonusRound$rightAffi = bonusRound.rightAffiliates) !== null && _bonusRound$rightAffi !== void 0 ? _bonusRound$rightAffi : 0) : ___EmotionJSX(Skeleton, {
    height: 21,
    width: 60
  }) : 'N/A'))), ___EmotionJSX(Grid, {
    item: true,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-bv-points-action"
  }, ___EmotionJSX(Link, {
    to: "/profile/add-member"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    link: true
  }, "Add a Member")))))));
};
DashboardBvPoints.propTypes = {
  bonuses: PropTypes.object
};
export default connect(function (_ref) {
  var bonuses = _ref.bonuses;
  return {
    bonuses: bonuses
  };
})(DashboardBvPoints);