import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _profileRankInformati;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import eagleIcon from '../assets/images/rank/eagle.png';
import bronzeIcon from '../assets/images/rank/bronze.png';
import silverIcon from '../assets/images/rank/silver.png';
import goldenIcon from '../assets/images/rank/golden.png';
import platinumIcon from '../assets/images/rank/platinum.png';
import lifetimeIcon from '../assets/images/rank/lifetime.png';
export var ProfileStatus = {
  New: 'New',
  Pending: 'Pending',
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated'
};
export var profileRank = {
  Eagle: 'Eagle',
  BronzeEagle: 'Bronze Eagle',
  SilverEagle: 'Silver Eagle',
  GoldEagle: 'Gold Eagle',
  PlatinumEagle: 'Platinum Eagle',
  LifetimeEagle: 'Lifetime Eagle'
};
export var profileRankId = {
  1: profileRank.Eagle,
  2: profileRank.BronzeEagle,
  3: profileRank.SilverEagle,
  4: profileRank.GoldEagle,
  5: profileRank.PlatinumEagle,
  6: profileRank.LifetimeEagle
};
export var profileRankInformation = (_profileRankInformati = {}, _defineProperty(_profileRankInformati, profileRank.Eagle, {
  rankName: profileRank.Eagle,
  rankImage: eagleIcon,
  nextRank: profileRank.BronzeEagle
}), _defineProperty(_profileRankInformati, profileRank.BronzeEagle, {
  rankName: profileRank.BronzeEagle,
  rankImage: bronzeIcon,
  nextRank: profileRank.SilverEagle
}), _defineProperty(_profileRankInformati, profileRank.SilverEagle, {
  rankName: profileRank.SilverEagle,
  rankImage: silverIcon,
  nextRank: profileRank.GoldEagle
}), _defineProperty(_profileRankInformati, profileRank.GoldEagle, {
  rankName: profileRank.GoldEagle,
  rankImage: goldenIcon,
  nextRank: profileRank.PlatinumEagle
}), _defineProperty(_profileRankInformati, profileRank.PlatinumEagle, {
  rankName: profileRank.PlatinumEagle,
  rankImage: platinumIcon,
  nextRank: profileRank.LifetimeEagle
}), _defineProperty(_profileRankInformati, profileRank.LifetimeEagle, {
  rankName: profileRank.LifetimeEagle,
  rankImage: lifetimeIcon,
  nextRank: profileRank.LifetimeEagle
}), _profileRankInformati);
export var identityProviderRedirectUrlPath = {
  Sts: 'Account/ForgotPassword',
  Keycloak: 'login-actions/reset-credentials?client_id=OneLife'
};