import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainTabs from '../../components/UI/Shared/MainTabs';
import Leadership from './tabs/Leadership';
import Eagle from './tabs/Eagle';
import { jsx as ___EmotionJSX } from "@emotion/react";
var RecognitionPage = function RecognitionPage(props) {
  var path = props.match.path;
  var tabs = [{
    label: 'Leadership',
    route: "".concat(path, "/leadership")
  }, {
    label: 'Eagle',
    route: "".concat(path, "/eagle")
  }];
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MainTabs, {
    tabs: tabs
  }), ___EmotionJSX(Switch, null, ___EmotionJSX(Route, {
    path: "".concat(path, "/leadership"),
    component: Leadership
  }), ___EmotionJSX(Route, {
    path: "".concat(path, "/eagle"),
    component: Eagle
  }), ___EmotionJSX(Redirect, {
    to: tabs[0].route
  })));
};
export default RecognitionPage;