import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainTabs from '../../components/UI/Shared/MainTabs';
import Network from './tabs/Network';
import DirectSale from './tabs/DirectSale';
import Matching from './tabs/Matching';
import StartUp from './tabs/StartUp';
import NetworkBonusDetails from './inner/NetworkBonusDetails';
import { jsx as ___EmotionJSX } from "@emotion/react";
var BonusPage = function BonusPage(props) {
  var user = props.user;
  var chineseProfile = user.country === 'China';
  var path = props.match.path;
  var tabs = [!chineseProfile && {
    label: 'Network Bonus',
    route: "".concat(path, "/network")
  }, {
    label: 'Direct Sale Bonus',
    route: "".concat(path, "/direct-sale")
  }, !chineseProfile && {
    label: 'Matching Bonus',
    route: "".concat(path, "/matching")
  }, {
    label: 'Startup Bonus',
    route: "".concat(path, "/start-up")
  }].filter(Boolean);
  return ___EmotionJSX(Fragment, null, ___EmotionJSX(MainTabs, {
    tabs: tabs
  }), ___EmotionJSX(Switch, null, !chineseProfile && ___EmotionJSX(Route, {
    path: "".concat(path, "/network"),
    exact: true,
    component: Network
  }), !chineseProfile && ___EmotionJSX(Route, {
    path: "".concat(path, "/network/details/:id"),
    component: NetworkBonusDetails
  }), ___EmotionJSX(Route, {
    path: "".concat(path, "/direct-sale"),
    component: DirectSale
  }), !chineseProfile && ___EmotionJSX(Route, {
    exact: true,
    path: "".concat(path, "/matching"),
    component: Matching
  }), ___EmotionJSX(Route, {
    path: "".concat(path, "/start-up"),
    component: StartUp
  }), ___EmotionJSX(Redirect, {
    to: tabs[0].route
  })));
};
export default connect(function (_ref) {
  var user = _ref.user;
  return {
    user: user
  };
})(BonusPage);