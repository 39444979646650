import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableNameCol = function NetworkTableNameCol(props) {
  var id = props.id,
    name = props.name,
    nickName = props.nickName,
    userAvatar = props.userAvatar,
    packageColor = props.packageColor,
    level = props.level,
    downlinksCount = props.downlinksCount,
    toggleExpand = props.toggleExpand,
    isExpanded = props.isExpanded;
  return ___EmotionJSX("div", {
    className: "vk-network-table-name-col depth-level-".concat(level)
  }, level < 4 && !!downlinksCount ? ___EmotionJSX("span", {
    role: "button",
    tabIndex: "0",
    onClick: toggleExpand,
    className: clsx('vk-network-table-name-col-icon', !isExpanded && downlinksCount && 'vk-network-table-name-col-icon--collapsed', isExpanded && 'vk-network-table-name-col-icon--expanded')
  }, isExpanded ? '-' : '+') : ___EmotionJSX("span", {
    className: "vk-network-table-name-col-icon--holder"
  }), ___EmotionJSX("div", {
    className: "vk-network-table-name-col-avatar",
    style: {
      borderColor: packageColor
    }
  }, ___EmotionJSX("img", {
    src: userAvatar,
    className: "vk-image-responsive",
    alt: "".concat(name, "-avatar")
  })), ___EmotionJSX("div", {
    className: "vk-network-table-name-col-name"
  }, ___EmotionJSX("h4", {
    className: "vk-network-table-name-col-name-headline"
  }, name), ___EmotionJSX("pre", null, "#", id, " ", nickName)));
};
NetworkTableNameCol.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  nickName: PropTypes.string,
  level: PropTypes.string,
  userAvatar: PropTypes.string,
  packageColor: PropTypes.string,
  downlinksCount: PropTypes.number,
  toggleExpand: PropTypes.func,
  isExpanded: PropTypes.bool
};
export default NetworkTableNameCol;