import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import getCurrencyValue from '../../../utils/currencyByType';
import { walletType } from '../../../enums/wallets';
import { ReactComponent as RightLegIcn } from '../../../assets/images/layout-svg-icons/icn-rightleg.svg';
import { ReactComponent as LeftLegIcn } from '../../../assets/images/layout-svg-icons/icn-leftleg.svg';
import { ReactComponent as LinkArrowIcn } from '../../../assets/images/layout-svg-icons/link-arrow-right.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var BonusBvPointsTable = function BonusBvPointsTable(props) {
  var _content$totalBv, _content$leftBvBase, _content$rightBvBase, _content$leftBv, _content$rightBv, _content$leftBvBase2, _content$leftBv2, _content$rightBvBase2, _content$rightBv2, _content$leftAffiliat, _content$rightAffilia, _content$bonusCap, _content$amount, _Math$min, _content$packageInfoF;
  var content = props.content,
    isCurrentRound = props.isCurrentRound,
    hideDetailsButton = props.hideDetailsButton;
  return ___EmotionJSX("div", {
    className: "vk-bv-points-table-wrap"
  }, ___EmotionJSX("table", {
    className: "vk-bv-points-table"
  }, ___EmotionJSX("thead", null, ___EmotionJSX("tr", null, ___EmotionJSX("th", null, ___EmotionJSX("span", null, "BV Points")), ___EmotionJSX("th", {
    colSpan: 2,
    className: "vk-bv-points-table-leg-th"
  }, ___EmotionJSX("div", {
    className: "vk-bv-points-table-left-leg-arrow"
  }, ___EmotionJSX(LeftLegIcn, null)), ___EmotionJSX("span", null, getCurrencyValue((_content$totalBv = content === null || content === void 0 ? void 0 : content.totalBv) !== null && _content$totalBv !== void 0 ? _content$totalBv : 0, walletType.BV)), ___EmotionJSX("div", {
    className: "vk-bv-points-table-right-leg-arrow"
  }, ___EmotionJSX(RightLegIcn, null))))), ___EmotionJSX("tbody", null, ___EmotionJSX("tr", null, ___EmotionJSX("td", {
    className: "vk-bv-points-table-label-td"
  }, ___EmotionJSX("span", null, "Base")), ___EmotionJSX("td", {
    className: "vk-bv-points-table-left-td"
  }, ___EmotionJSX("span", null, getCurrencyValue((_content$leftBvBase = content === null || content === void 0 ? void 0 : content.leftBvBase) !== null && _content$leftBvBase !== void 0 ? _content$leftBvBase : 0, walletType.BV))), ___EmotionJSX("td", {
    className: "vk-bv-points-table-right-td"
  }, ___EmotionJSX("span", null, getCurrencyValue((_content$rightBvBase = content === null || content === void 0 ? void 0 : content.rightBvBase) !== null && _content$rightBvBase !== void 0 ? _content$rightBvBase : 0, walletType.BV)))), ___EmotionJSX("tr", null, ___EmotionJSX("td", {
    className: "vk-bv-points-table-label-td"
  }, ___EmotionJSX("span", null, "New")), ___EmotionJSX("td", {
    className: "vk-bv-points-table-left-td"
  }, ___EmotionJSX("span", null, getCurrencyValue((_content$leftBv = content === null || content === void 0 ? void 0 : content.leftBv) !== null && _content$leftBv !== void 0 ? _content$leftBv : 0, walletType.BV))), ___EmotionJSX("td", {
    className: "vk-bv-points-table-right-td"
  }, ___EmotionJSX("span", null, getCurrencyValue((_content$rightBv = content === null || content === void 0 ? void 0 : content.rightBv) !== null && _content$rightBv !== void 0 ? _content$rightBv : 0, walletType.BV)))), ___EmotionJSX("tr", null, ___EmotionJSX("td", {
    className: "vk-bv-points-table-label-td"
  }, ___EmotionJSX("span", null, "Result")), ___EmotionJSX("td", {
    className: "vk-bv-points-table-left-td"
  }, ___EmotionJSX("span", null, getCurrencyValue(((_content$leftBvBase2 = content === null || content === void 0 ? void 0 : content.leftBvBase) !== null && _content$leftBvBase2 !== void 0 ? _content$leftBvBase2 : 0) + ((_content$leftBv2 = content === null || content === void 0 ? void 0 : content.leftBv) !== null && _content$leftBv2 !== void 0 ? _content$leftBv2 : 0), walletType.BV))), ___EmotionJSX("td", {
    className: "vk-bv-points-table-right-td"
  }, ___EmotionJSX("span", null, getCurrencyValue(((_content$rightBvBase2 = content === null || content === void 0 ? void 0 : content.rightBvBase) !== null && _content$rightBvBase2 !== void 0 ? _content$rightBvBase2 : 0) + ((_content$rightBv2 = content === null || content === void 0 ? void 0 : content.rightBv) !== null && _content$rightBv2 !== void 0 ? _content$rightBv2 : 0), walletType.BV)))), ___EmotionJSX("tr", null, ___EmotionJSX("td", {
    className: "vk-bv-points-table-label-td"
  }, ___EmotionJSX("span", null, "People")), ___EmotionJSX("td", {
    className: "vk-bv-points-table-left-td "
  }, ___EmotionJSX("span", null, (_content$leftAffiliat = content === null || content === void 0 ? void 0 : content.leftAffiliates) !== null && _content$leftAffiliat !== void 0 ? _content$leftAffiliat : 0)), ___EmotionJSX("td", {
    className: "vk-bv-points-table-right-td"
  }, ___EmotionJSX("span", null, (_content$rightAffilia = content === null || content === void 0 ? void 0 : content.rightAffiliates) !== null && _content$rightAffilia !== void 0 ? _content$rightAffilia : 0))), !isCurrentRound && ___EmotionJSX("tr", null, ___EmotionJSX("td", {
    className: "vk-bv-points-table-label-td"
  }, ___EmotionJSX("span", null, "Bonus Cap")), ___EmotionJSX("td", {
    className: "bonus-row"
  }, ___EmotionJSX("span", null, getCurrencyValue((_content$bonusCap = content === null || content === void 0 ? void 0 : content.bonusCap) !== null && _content$bonusCap !== void 0 ? _content$bonusCap : 0, walletType.CASH))), ___EmotionJSX("td", {
    className: "vk-bv-points-table-right-td"
  })), ___EmotionJSX("tr", null, ___EmotionJSX("td", {
    className: "vk-bv-points-table-label-td"
  }, ___EmotionJSX("span", null, "Total Bonus")), ___EmotionJSX("td", {
    className: "bonus-row"
  }, ___EmotionJSX("span", null, getCurrencyValue((_content$amount = content === null || content === void 0 ? void 0 : content.amount) !== null && _content$amount !== void 0 ? _content$amount : 0, walletType.CASH))), ___EmotionJSX("td", {
    className: "vk-bv-points-table-right-td"
  })), ___EmotionJSX("tr", null, ___EmotionJSX("td", {
    className: "vk-bv-points-table-label-td"
  }, ___EmotionJSX("span", null, isCurrentRound ? 'Bonus to be paid' : 'Paid Bonus')), ___EmotionJSX("td", {
    className: "bonus-row"
  }, ___EmotionJSX("span", null, getCurrencyValue((_Math$min = Math.min(content === null || content === void 0 ? void 0 : content.amount, content === null || content === void 0 ? void 0 : content.bonusCap)) !== null && _Math$min !== void 0 ? _Math$min : 0, walletType.CASH))), ___EmotionJSX("td", {
    className: "vk-bv-points-table-right-td"
  })))), !(content !== null && content !== void 0 && (_content$packageInfoF = content.packageInfoForRound) !== null && _content$packageInfoF !== void 0 && _content$packageInfoF.hasNetworkBonus) && ___EmotionJSX("div", {
    className: "bv-points-package-info"
  }, isCurrentRound ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, "Your current package does not allow network bonus!"), ___EmotionJSX("p", null, "If you want to get network bonus in this round, you should", ___EmotionJSX(Link, {
    to: "/shop/all-packages",
    className: "vk-link vk-link-lg"
  }, " upgrade"), " your package.")) : ___EmotionJSX("p", null, "Your redeemed package in this round did not allow network bonus!")), (content === null || content === void 0 ? void 0 : content.id) && !hideDetailsButton && ___EmotionJSX("div", {
    className: "vk-bv-points-table-link"
  }, ___EmotionJSX(Link, {
    to: "/bonus/network/details/".concat(content.id),
    className: "vk-link vk-link-sm"
  }, "Details", ___EmotionJSX(LinkArrowIcn, null))));
};
BonusBvPointsTable.propTypes = {
  content: PropTypes.any,
  isCurrentRound: PropTypes.bool,
  hideDetailsButton: PropTypes.bool
};
export default BonusBvPointsTable;