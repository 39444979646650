import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ProfileAvatar from '../components/ProfileAvatar';
import ProfilePlanSponsor from '../components/ProfilePlanSponsor';
import ProfileInviteMember from '../components/ProfileInviteMember';
import ProfileInfo from '../components/ProfileInfo';
import ProfileMemberPlacement from '../components/ProfileMemberPlacement';
import { useUser } from '../../../hooks/useUser';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Overview = function Overview() {
  var _useUser = useUser(),
    refreshUserData = _useUser.refreshUserData;
  useEffect(function () {
    refreshUserData();
  }, []);
  return ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    className: "vk-profile-overview"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 7
  }, ___EmotionJSX("div", {
    className: "vk-profile-overview-avatar-wrap"
  }, ___EmotionJSX(ProfileAvatar, {
    topButtons: false,
    statusVisible: true
  }))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 5
  }, ___EmotionJSX(ProfilePlanSponsor, null)), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 7
  }, ___EmotionJSX("p", {
    className: "hint-info vk-mb-40"
  }, "Please be informed that if you want to edit your personal information you should upload a ", ___EmotionJSX("b", null, "NEW KYC documents"), " with the correct details. All profile information is update after both KYC documents are Approved."), ___EmotionJSX(ProfileInfo, null)), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 5
  }, ___EmotionJSX(ProfileMemberPlacement, null), ___EmotionJSX(ProfileInviteMember, null)));
};
export default Overview;