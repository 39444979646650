function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { add, mul } from 'exact-math';
import uuid from '../../utils/uuid';
import { orderItemTypes } from '../../enums';
export var shoppingActionTypes = {
  Add: '[Add] Add package to cart',
  Remove: '[Remove] Remove package from cart',
  Edit: '[Edit] Edit package into cart',
  Delete: '[Delete] Erase shopping cart'
};
var initialState = {
  total: 0,
  items: []
};
var FEE_AMOUNT = 30;
var getPrice = function getPrice(item) {
  var _item$amount, _item$price;
  return item.type === orderItemTypes.Package ? mul((_item$amount = item === null || item === void 0 ? void 0 : item.amount) !== null && _item$amount !== void 0 ? _item$amount : 0, add((_item$price = item === null || item === void 0 ? void 0 : item.price) !== null && _item$price !== void 0 ? _item$price : 0, item !== null && item !== void 0 && item.activationFee || item !== null && item !== void 0 && item.mandatoryActivation ? FEE_AMOUNT : 0)) : item.price;
};
export var shoppingReducer = persistReducer({
  storage: storage,
  key: 'shopping-cart'
}, function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case shoppingActionTypes.Add:
      {
        var _state$total;
        var _action$payload$item = action.payload.item,
          item = _action$payload$item === void 0 ? {} : _action$payload$item;
        var match = state.items.find(function (el) {
          return el.type === orderItemTypes.Package ? el.catalogueItemId === item.catalogueItemId && el.activationFee === item.activationFee : false;
        });
        return {
          total: add((_state$total = state === null || state === void 0 ? void 0 : state.total) !== null && _state$total !== void 0 ? _state$total : 0, getPrice(item)),
          items: match ? state.items.map(function (el) {
            var _el$amount, _item$amount2;
            return el.type === orderItemTypes.Package && el.catalogueItemId === item.catalogueItemId ? _objectSpread(_objectSpread({}, el), {}, {
              amount: add((_el$amount = el === null || el === void 0 ? void 0 : el.amount) !== null && _el$amount !== void 0 ? _el$amount : 0, (_item$amount2 = item === null || item === void 0 ? void 0 : item.amount) !== null && _item$amount2 !== void 0 ? _item$amount2 : 0)
            }) : el;
          }) : state.items.concat(_objectSpread(_objectSpread({}, item), {}, {
            uuid: uuid()
          }))
        };
      }
    case shoppingActionTypes.Edit:
      {
        var _action$payload$item2 = action.payload.item,
          _item = _action$payload$item2 === void 0 ? {} : _action$payload$item2;
        var newItems = state.items.map(function (el) {
          return el.uuid === _item.uuid ? _objectSpread(_objectSpread({}, el), _item) : el;
        });
        return {
          total: newItems.reduce(function (acc, cur) {
            return add(acc, getPrice(cur));
          }, 0),
          items: newItems
        };
      }
    case shoppingActionTypes.Remove:
      {
        var id = action.payload.id;
        var _newItems = state.items.filter(function (el) {
          return el.uuid !== id;
        });
        return {
          total: _newItems.reduce(function (acc, cur) {
            return add(acc, getPrice(cur));
          }, 0),
          items: _newItems
        };
      }
    case shoppingActionTypes.Delete:
      {
        return initialState;
      }
    default:
      return state;
  }
});
export var shoppingActions = {
  addToShoppingCart: function addToShoppingCart(item) {
    return {
      type: shoppingActionTypes.Add,
      payload: {
        item: item
      }
    };
  },
  removeFromShoppingCart: function removeFromShoppingCart(id) {
    return {
      type: shoppingActionTypes.Remove,
      payload: {
        id: id
      }
    };
  },
  editItemInShoppingCart: function editItemInShoppingCart(item) {
    return {
      type: shoppingActionTypes.Edit,
      payload: {
        item: item
      }
    };
  },
  deleteShoppingCart: function deleteShoppingCart() {
    return {
      type: shoppingActionTypes.Delete
    };
  }
};