import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var productAndBusinessPresentationsData = [{
  id: 38,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Arabic',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ar.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ar.pdf'
}, {
  id: 37,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT German',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-de.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-de.pdf'
}, {
  id: 36,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT English',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-en.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-en.pdf'
}, {
  id: 35,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Spanish',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-es.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-es.pdf'
}, {
  id: 34,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Hungarian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-hu.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-hu.pdf'
}, {
  id: 33,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Italian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-it.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-it.pdf'
}, {
  id: 32,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Japanese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ja.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ja.pdf'
}, {
  id: 31,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Korean',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ko.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ko.pdf'
}, {
  id: 30,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Portuguese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-pt.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-pt.pdf'
}, {
  id: 29,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Romanian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ro.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ro.pdf'
}, {
  id: 28,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Chinese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-zh.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-zh.pdf'
}, {
  id: 27,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Vietnamese',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-vi.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-vi.pdf'
}, {
  id: 26,
  "public": true,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'BLOCKCHAIN DEPLOYMENT Russian',
  viewUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ru.pdf',
  downloadUrl: 'https://oneecosystem.blob.core.windows.net/landing/presentations/deployment-tutorials/BLOCKCHAIN-DEPLOYMENT-ru.pdf'
}, {
  id: 25,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Take Charge Of The Financial Revolution',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/white-paper-2022-view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/white-paper-2022-download.pdf'
}, {
  id: 24,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem Business Presentation 2019',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation 2019 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation 2019 - downwoad.pdf'
}, {
  id: 23,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Compliance Regulations In The One Ecosystem Re-VISION',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance Regulations In The One Ecosystem Re-VISION - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance Regulations In The One Ecosystem Re-VISION - download.pdf'
}, {
  id: 22,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: "One Ecosystem And DealShaker's Role In It",
  viewUrl: "https://onelife.blob.core.windows.net/help-faq/One Ecosystem And DealShaker's Role In It - view.pdf",
  downloadUrl: "https://onelife.blob.core.windows.net/help-faq/One Ecosystem And DealShaker's Role In It - download.pdf"
}, {
  id: 21,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneForex Business Presentation Part 2',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 2 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 2 - download.pdf'
}, {
  id: 20,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneForex Business Presentation Part 1',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 1 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneForex Business Presentation Part 1 - download.pdf'
}, {
  id: 19,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneEcosystem Business Presentation_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Business Presentation_ENG - download.pdf'
}, {
  id: 18,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Compliance_Workshop_May 2017',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance_Workshop_May 2017 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Compliance_Workshop_May 2017 - download.pdf'
}, {
  id: 17,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Conducting Events - Rules And Compliance Policy',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Conducting Events - Rules And Compliance Policy - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Conducting Events - Rules And Compliance Policy - download.pdf'
}, {
  id: 6,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'How To Present The OneEcosystem Opportunity - Introduction To Compliance',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Present The OneLife Opportunity - Introduction To Compliance - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Present The OneLife Opportunity - Introduction To Compliance - download.pdf'
}, {
  id: 15,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'DealShaker Merchants Introduction_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_Chinese - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_Chinese - download.pdf'
}, {
  id: 14,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'DealShaker Bonus Plan Presentation_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_ENG - download.pdf'
}, {
  id: 13,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'DealShaker Bonus Plan Presentation_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_Chinese - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Bonus Plan Presentation_Chinese - download.pdf'
}, {
  id: 12,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'DealShaker: Detailed Merchant Guide_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Detailed Merchant Guide_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Detailed Merchant Guide_ENG - download.pdf'
}, {
  id: 11,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'DealShaker Merchants Introduction_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker Merchants Introduction_ENG - download.pdf'
}, {
  id: 10,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Tips For Merchants_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Tips For Merchants_Chinese - view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Tips For Merchants_Chinese - download.png'
}, {
  id: 9,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'DealShaker_Merchant Guide Presentation_Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker_Merchant Guide Presentation_Chinese - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/DealShaker_Merchant Guide Presentation_Chinese - download.pdf'
}, {
  id: 8,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'MAB Product Presentation, Jan 2017_RUS',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Jan 2017_RUS - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Jan 2017_RUS - download.pdf'
}, {
  id: 7,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'MAB Product Presentation, Dec 2016_CN',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_CN - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_CN - download.pdf'
}, {
  id: 6,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'MAB Product Presentation, Dec 2016_ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_ENG - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/MAB Product Presentation, Dec 2016_ENG - download.pdf'
}, {
  id: 5,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneCoin_The_Owner',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin_The_Owner - view .pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoin_The_Owner - download.pdf'
}, {
  id: 4,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'How_To_Start_Your_Business',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How_To_Start_Your_Business - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How_To_Start_Your_Business - download.pdf'
}, {
  id: 3,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Status_of_the_Network_London_11_June_2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Status_of_the_Network_London_11_June_2016 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Status_of_the_Network_London_11_June_2016 - download.pdf'
}, {
  id: 2,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'OneCoinCloud_January2016_update',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoinCloud_January2016_update - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneCoinCloud_January2016_update - download.pdf'
}, {
  id: 1,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'New_Blockchain_OneCoin_London_11_June_2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/New_Blockchain_OneCoin_London_11_June_2016 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/New_Blockchain_OneCoin_London_11_June_2016 - download.pdf'
}, {
  id: 0,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'CoinRush_OneLife_Network_London_11_June_2016',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/CoinRush_OneLife_Network_London_11_June_2016 - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/CoinRush_OneLife_Network_London_11_June_2016 - download.pdf'
}];