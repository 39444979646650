import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { sub } from 'exact-math';
import { accountActions } from '../../../store';
import WalletBalanceCard from '../components/WalletBalanceCard';
import LatestTrans from '../../../components/UI/Shared/LatestTrans';
import NoWallets from '../../../components/UI/Shared/NoWallets';
import { walletType } from '../../../enums/wallets';
import currencyByType from '../../../utils/currencyByType';
import { ReactComponent as BalanceIcn } from '../../../assets/images/layout-svg-icons/icn-wallet-freecoins.svg';
import { ReactComponent as InMiningIcn } from '../../../assets/images/layout-svg-icons/icn-wallet-inmining.svg';
import { ReactComponent as BlockedIcn } from '../../../assets/images/layout-svg-icons/icn-wallet-inescrow.svg';
import { ReactComponent as AvailableIcn } from '../../../assets/images/layout-svg-icons/Available-Coins-Icon.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MyCoinLegacy = function MyCoinLegacy(props) {
  var _coinLegacyWallet$bal, _coinLegacyWallet$blo;
  var coinLegacyWallet = props.coinLegacyWallet,
    loadLatestTransactions = props.loadLatestTransactions;
  useEffect(function () {
    !coinLegacyWallet.latestTransactionsLoaded && loadLatestTransactions(coinLegacyWallet.id);
  }, [coinLegacyWallet.id]);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "vk-wallets-balance-wraper"
  }, ___EmotionJSX("div", {
    className: "vk-wallets-balance-header-wrap-action-container"
  }, ___EmotionJSX("div", {
    className: "vk-tab-heading vk-pb-8"
  }, "ONE (deprecated) Wallet")), !(coinLegacyWallet !== null && coinLegacyWallet !== void 0 && coinLegacyWallet.id) && ___EmotionJSX(NoWallets, null), !!(coinLegacyWallet !== null && coinLegacyWallet !== void 0 && coinLegacyWallet.id) && ___EmotionJSX("div", {
    className: "wallets-blue-container"
  }, ___EmotionJSX(WalletBalanceCard, {
    title: "Total Balance",
    amount: currencyByType(coinLegacyWallet.balance, walletType.COINLEGACY),
    image: ___EmotionJSX(BalanceIcn, null)
  }), ___EmotionJSX(WalletBalanceCard, {
    title: "Available Coins",
    amount: currencyByType(sub((_coinLegacyWallet$bal = coinLegacyWallet === null || coinLegacyWallet === void 0 ? void 0 : coinLegacyWallet.balance) !== null && _coinLegacyWallet$bal !== void 0 ? _coinLegacyWallet$bal : 0, (_coinLegacyWallet$blo = coinLegacyWallet === null || coinLegacyWallet === void 0 ? void 0 : coinLegacyWallet.blockedBalance) !== null && _coinLegacyWallet$blo !== void 0 ? _coinLegacyWallet$blo : 0), walletType.COINLEGACY),
    image: ___EmotionJSX(AvailableIcn, null)
  }), ___EmotionJSX(WalletBalanceCard, {
    title: "Blocked Coins",
    amount: currencyByType(coinLegacyWallet.blockedBalance, walletType.COINLEGACY),
    image: ___EmotionJSX(BlockedIcn, null)
  }), ___EmotionJSX(WalletBalanceCard, {
    title: "Coins in Mining",
    amount: currencyByType(coinLegacyWallet.pendingBalance, walletType.COINLEGACY),
    image: ___EmotionJSX(InMiningIcn, null)
  })), !!(coinLegacyWallet !== null && coinLegacyWallet !== void 0 && coinLegacyWallet.id) && ___EmotionJSX(Grid, {
    container: true,
    spacing: 5
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 12
  }, ___EmotionJSX(LatestTrans, {
    latestTransactions: coinLegacyWallet === null || coinLegacyWallet === void 0 ? void 0 : coinLegacyWallet.transactions,
    walletType: walletType.COINLEGACY,
    link: "/wallet/coin-legacy-transaction-history"
  })))));
};
var mapProps = function mapProps(_ref) {
  var _accounts$current;
  var accounts = _ref.accounts;
  return {
    coinLegacyWallet: accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.wallets.find(function (el) {
      return el.walletType === walletType.COINLEGACY;
    })
  };
};
export default connect(mapProps, accountActions)(MyCoinLegacy);