import "core-js/modules/es.array.map.js";
import React from 'react';
import { USER_ROLES, hasRole } from '@oneecosystem/authenticate';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { getResourcesCardData } from './resourcesConfig';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Resources = function Resources() {
  return ___EmotionJSX("div", {
    className: "vk-info-center-main-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 4
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Resources")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 4
  }, getResourcesCardData(hasRole(USER_ROLES.ADMINISTRATOR)).map(function (el) {
    return ___EmotionJSX(Grid, {
      item: true,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      key: el.id
    }, ___EmotionJSX("div", {
      className: "containerResources"
    }, ___EmotionJSX(Link, {
      to: el.url,
      className: "cardContainerResources"
    }, ___EmotionJSX("div", {
      className: "wrapResources"
    }, el.image && ___EmotionJSX("img", {
      className: "vk-image-responsive imageResources",
      src: el.image,
      alt: "img-information-center-resources"
    }), ___EmotionJSX("h3", {
      className: "title"
    }, el.title)))));
  })))));
};
export default Resources;