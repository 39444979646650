import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { softLogout } from '@oneecosystem/authenticate';
import { checkStatus } from '../../utils/checkStatus';
import { ReactComponent as LogOutIcn } from '../../assets/images/layout-svg-icons/icn-signout.svg';
import defaultAvatarLogo from '../../assets/images/icons/default-avatar.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var UserProfile = function UserProfile(props) {
  var _user$picThumbnailUrl;
  var user = props.user,
    accountsLength = props.accountsLength;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    dropdownOpened = _useState2[0],
    setDropdownOpened = _useState2[1];
  var isActive = checkStatus(user === null || user === void 0 ? void 0 : user.status) && !!accountsLength;
  var showDropdown = function showDropdown() {
    return setDropdownOpened(true);
  };
  var hideDropdown = function hideDropdown() {
    var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    event.stopPropagation();
    var currentTarget = event.currentTarget,
      relatedTarget = event.relatedTarget;
    if (currentTarget.contains(relatedTarget)) return;
    setDropdownOpened(false);
  };
  var handleLogout = function handleLogout() {
    localStorage.clear();
    softLogout();
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: "vk-header-avatar-wrapper",
    onClick: showDropdown,
    onBlur: hideDropdown
  }, ___EmotionJSX(NavLink, {
    to: "/profile",
    onClick: function onClick(event) {
      return event.preventDefault();
    }
  }, ___EmotionJSX("div", {
    className: "vk-header-avatar-inner"
  }, ___EmotionJSX("img", {
    alt: "Pic",
    src: (_user$picThumbnailUrl = user.picThumbnailUrl) !== null && _user$picThumbnailUrl !== void 0 ? _user$picThumbnailUrl : defaultAvatarLogo
  }))), dropdownOpened && ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-wrap"
  }, isActive && ___EmotionJSX(Link, {
    to: "/profile/overview",
    onClick: hideDropdown
  }, ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-view-profile-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-view-profile-name"
  }, user.fullname), ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-view-profile-label"
  }, "View profile"))), isActive && ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-link-wrap"
  }, ___EmotionJSX(NavLink, {
    to: "/profile/payment-details",
    onClick: hideDropdown
  }, ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-link-item"
  }, "Payment Details")), ___EmotionJSX(NavLink, {
    to: "/profile/kyc",
    onClick: hideDropdown
  }, ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-link-item"
  }, "KYC")), ___EmotionJSX(NavLink, {
    to: "/profile/password",
    onClick: hideDropdown
  }, ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-link-item"
  }, "Password")), ___EmotionJSX(NavLink, {
    to: "/profile/accounts",
    onClick: hideDropdown
  }, ___EmotionJSX("div", {
    className: "vk-header-avatar-dropdown-link-item"
  }, "Accounts (", accountsLength, ")"))), ___EmotionJSX("div", {
    onClick: handleLogout,
    tabIndex: "0",
    role: "button",
    className: "vk-header-avatar-dropdown-footer-wrap"
  }, ___EmotionJSX(LogOutIcn, {
    className: "vk-header-avatar-dropdown-footer-icon"
  }), ___EmotionJSX("span", {
    className: "vk-header-avatar-dropdown-footer-text"
  }, "Sign out"))));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var _accounts$items;
  var user = _ref.user,
    accounts = _ref.accounts;
  return {
    user: user,
    accountsLength: accounts === null || accounts === void 0 ? void 0 : (_accounts$items = accounts.items) === null || _accounts$items === void 0 ? void 0 : _accounts$items.length
  };
};
export default connect(mapStateToProps)(UserProfile);