import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import ValuePercent from '../../../components/UI/Shared/ValuePercent';
import { Skeleton } from '../../../components/UI/Loader';
import { jsx as ___EmotionJSX } from "@emotion/react";
var BonusesRows = function BonusesRows(props) {
  var rows = props.rows,
    hasBonuses = props.hasBonuses;
  return rows.map(function (row) {
    return ___EmotionJSX("div", {
      className: "vk-dashboard-bonuses-body-left-list-item",
      key: row.title
    }, ___EmotionJSX(Grid, {
      container: true,
      spacing: 1,
      alignItems: "center"
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: true
    }, ___EmotionJSX("p", {
      className: "vk-dashboard-bonuses-body-left-list-item-label"
    }, row.title)), ___EmotionJSX(Grid, {
      item: true,
      sm: "auto"
    }, hasBonuses ? !_isNil(row.value) ? ___EmotionJSX(ValuePercent, {
      value: row.value,
      sm: true
    }) : ___EmotionJSX(Skeleton, {
      height: 21,
      width: 45,
      style: {
        margin: 0
      }
    }) : row.emptyMessage)));
  });
};
export default BonusesRows;