import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import RecognitionNextRankInfo from '../components/RecognitionNextRankInfo';
import RecognitionRankInformation from '../components/RecognitionRankInformation';
import LeadershipTable from '../components/LeadershipTable';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Leadership = function Leadership(_ref) {
  var currentAccount = _ref.currentAccount,
    currentPackage = _ref.currentPackage;
  return ___EmotionJSX("div", {
    className: "vk-container vk-pt-32"
  }, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    spacing: 5
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "vk-pb-40"
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Leadership Rank"), ___EmotionJSX("hr", null), ___EmotionJSX("p", null, "Leadership program is based on personal organization BVs. NOT downline BVs and calculated on monthly basis. If your position maxes out - the BVs on both side are reset to 0."))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 5
  }, ___EmotionJSX(RecognitionRankInformation, {
    currentAccount: currentAccount
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 7
  }, ___EmotionJSX(RecognitionNextRankInfo, {
    currentAccount: currentAccount,
    currentPackage: currentPackage
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 12
  }, ___EmotionJSX(LeadershipTable, {
    currentAccount: currentAccount
  }))));
};
var mapStateProps = function mapStateProps(_ref2) {
  var accounts = _ref2.accounts,
    packages = _ref2.packages;
  return {
    currentAccount: accounts.current,
    currentPackage: packages === null || packages === void 0 ? void 0 : packages.current
  };
};
export default connect(mapStateProps)(Leadership);