import _isNil from "lodash/isNil";
import "core-js/modules/es.date.now.js";
import "core-js/modules/es.date.to-string.js";
export var removeStorage = function removeStorage(key) {
  try {
    localStorage.removeItem(key);
    localStorage.removeItem("".concat(key, "_expiresIn"));
  } catch (e) {
    return false;
  }
  return true;
};
export var getStorage = function getStorage(key) {
  var now = Date.now();
  var expiresIn = localStorage.getItem("".concat(key, "_expiresIn"));
  if (_isNil(expiresIn)) expiresIn = 0;
  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    removeStorage(key);
    return null;
  }
  try {
    var value = localStorage.getItem(key);
    return value;
  } catch (e) {
    return null;
  }
};
export var setStorage = function setStorage(key, value, expires) {
  // eslint-disable-next-line no-param-reassign
  if (_isNil(expires)) expires = 24 * 60 * 60;
  var now = Date.now();
  var schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem("".concat(key, "_expiresIn"), schedule);
  } catch (e) {
    return false;
  }
  return true;
};