function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _orderBy from "lodash/orderBy";
import _isPlainObject from "lodash/isPlainObject";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import ColorBadge from './ColorBadge';
import currencyByType from '../../../utils/currencyByType';
import { transactionStatus, transactionType } from '../../../enums/wallets';
import { ReactComponent as EmptyIcon } from '../../../assets/images/layout-svg-icons/icn-empty.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var LatestTrans = function LatestTrans(props) {
  var _mapTransactionStatus;
  var latestTransactions = props.latestTransactions,
    link = props.link,
    customStyle = props.customStyle,
    walletType = props.walletType;
  var mapTransactionStatus = (_mapTransactionStatus = {}, _defineProperty(_mapTransactionStatus, transactionStatus.Pending, {
    text: 'Pending',
    color: 'blue'
  }), _defineProperty(_mapTransactionStatus, transactionStatus.Completed, {
    text: 'Completed',
    color: 'green'
  }), _defineProperty(_mapTransactionStatus, transactionStatus.Rejected, {
    text: 'Rejected',
    color: 'red'
  }), _defineProperty(_mapTransactionStatus, transactionStatus.Blocked, {
    text: 'Blocked',
    color: 'orange'
  }), _mapTransactionStatus);
  var renderDescription = function renderDescription(desc) {
    return (desc === null || desc === void 0 ? void 0 : desc.length) && desc.map(function (el) {
      var date = moment(el.createdOn).format('DD/MM/YYYY-HH:mm:ss').split('-');
      return ___EmotionJSX("div", {
        key: el.createdOn,
        className: "vk-transaction-description-wrapper"
      }, ___EmotionJSX("span", null, date[0], ___EmotionJSX("br", null), date[1]), ___EmotionJSX("span", null, el.description));
    });
  };
  var renderTransactionBox = function renderTransactionBox(data) {
    var _mapTransactionStatus2, _mapTransactionStatus3;
    if (!_isPlainObject(data)) return null;
    var createdOn = data.createdOn,
      id = data.id,
      description = data.description,
      type = data.type,
      amount = data.amount,
      userReason = data.userReason,
      completionStatus = data.completionStatus;
    var day = moment(createdOn).date();
    var month = moment(createdOn).format('MMM');
    return ___EmotionJSX("div", {
      className: "vk-latest-trans-box-item",
      key: id
    }, ___EmotionJSX(Grid, {
      container: true,
      spacing: 4,
      alignItems: "center"
    }, ___EmotionJSX(Grid, {
      item: true
    }, ___EmotionJSX("div", {
      className: "vk-latest-trans-box-item-date-wraper"
    }, ___EmotionJSX("span", {
      className: "vk-latest-trans-box-item-date-day"
    }, day), ___EmotionJSX("span", {
      className: "vk-latest-trans-box-item-date-month"
    }, month))), ___EmotionJSX(Grid, {
      item: true,
      sm: true
    }, ___EmotionJSX("span", {
      className: "vk-latest-trans-box-item-text-wraper"
    }, userReason || renderDescription(description) || 'No information provided')), ___EmotionJSX(Grid, {
      item: true
    }, ___EmotionJSX("div", {
      className: "vk-latest-trans-box-container"
    }, ___EmotionJSX("p", {
      className: clsx('vk-latest-trans-box-item-amount-wraper', type === transactionType.Outbound && 'vk-latest-trans-box-item-amount-wraper--red')
    }, type === transactionType.Inbound ? '+ ' : '- ', currencyByType(amount, walletType)), ___EmotionJSX(ColorBadge, {
      type: (_mapTransactionStatus2 = mapTransactionStatus[completionStatus]) === null || _mapTransactionStatus2 === void 0 ? void 0 : _mapTransactionStatus2.color
    }, (_mapTransactionStatus3 = mapTransactionStatus[completionStatus]) === null || _mapTransactionStatus3 === void 0 ? void 0 : _mapTransactionStatus3.text)))));
  };
  return ___EmotionJSX("div", {
    className: "vk-latest-trans-box-wraper ".concat(customStyle)
  }, ___EmotionJSX("div", {
    className: "vk-latest-trans-box-header"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 2,
    alignItems: "center",
    justify: "space-between"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "Latest Transactions")), ___EmotionJSX(Grid, {
    item: true,
    xs: "auto"
  }, !!(latestTransactions !== null && latestTransactions !== void 0 && latestTransactions.length) && ___EmotionJSX(Link, {
    to: link
  }, ___EmotionJSX("div", {
    className: "vk-link vk-link-sm"
  }, "View Full History"))))), latestTransactions !== null && latestTransactions !== void 0 && latestTransactions.length ? ___EmotionJSX("div", {
    className: "vk-latest-trans-box-body"
  }, _orderBy(latestTransactions, 'id', 'desc').slice(0, 5).map(function (latestTransaction) {
    return renderTransactionBox(latestTransaction);
  })) : ___EmotionJSX("div", {
    className: "vk-no-latest-trans-box"
  }, ___EmotionJSX(EmptyIcon, {
    className: "vk-no-latest-trans-icon"
  }), ___EmotionJSX("p", null, "No transactions in this wallet.")));
};
LatestTrans.propTypes = {
  customStyle: PropTypes.string,
  latestTransactions: PropTypes.any,
  link: PropTypes.string,
  walletType: PropTypes.string
};
export default LatestTrans;