import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { rootReducer, rootSaga } from './rootDuck';
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
var sagaMiddleware = createSagaMiddleware();
var store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export var persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export default store;