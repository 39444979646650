function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { showInfo } from '../../Notifications';
import { checkStatus } from '../../../../utils/checkStatus';
import { copyToClipboard } from '../../../../utils/copyText';
import { ReactComponent as QuestionOutlineIcon } from '../../../../assets/images/layout-svg-icons/icn-question-outline.svg';
import { ReactComponent as EducationIcon } from '../../../../assets/images/layout-svg-icons/icn-education.svg';
import { ReactComponent as InviteLinkIcon } from '../../../../assets/images/menu-icons/icn-invate-link.svg';
// import { ReactComponent as AnalyticsIcon } from '../../../../assets/images/layout-svg-icons/icn-analytics.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/images/layout-svg-icons/icn-questionmark.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AsideFooter = function AsideFooter(props) {
  var user = props.user;
  var _useState = useState({
      selectorShown: false,
      clicked: false
    }),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  var clicked = state.clicked,
    selectorShown = state.selectorShown;
  var isActive = checkStatus(user === null || user === void 0 ? void 0 : user.status);
  useEffect(function () {
    var options = [
    // {
    //   id: '1',
    //   name: 'Tools & Analysis',
    //   icon: () => <AnalyticsIcon />
    // },
    {
      id: '2',
      name: 'Information Center',
      icon: function icon() {
        return ___EmotionJSX(EducationIcon, null);
      },
      url: '/information-center'
    }, {
      id: '3',
      name: 'Support',
      action: showChatForm,
      icon: function icon() {
        return ___EmotionJSX(QuestionOutlineIcon, null);
      }
    }];
    setState(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, {
        options: options
      });
    });
  }, []);
  var renderHelpList = function renderHelpList() {
    var options = state.options;
    if (options) {
      var items = options.map(function (option) {
        var baseMenuItem = ___EmotionJSX("div", {
          key: option.id,
          className: "vk-help-button-popper-item",
          role: "button",
          tabIndex: 0,
          onClick: function onClick() {
            return onSelection(option.action);
          }
        }, option.icon(), ___EmotionJSX("span", null, option.name));
        return option.url ? ___EmotionJSX(Link, {
          to: option.url,
          key: option.id
        }, baseMenuItem) : baseMenuItem;
      });
      return items;
    }
  };
  var showChatForm = function showChatForm() {
    window.FreshworksWidget('open', 'ticketForm');
    window.FreshworksWidget('hide', 'launcher');
  };
  var toggleSelectorShown = function toggleSelectorShown() {
    var selectorShown = state.selectorShown;
    setState(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, {
        selectorShown: !selectorShown,
        clicked: false
      });
    });
  };
  var onSelection = function onSelection(action) {
    _isFunction(action) && action();
    toggleSelectorShown();
    setState(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, {
        clicked: true
      });
    });
  };
  var copyInviteLink = function copyInviteLink() {
    copyToClipboard("".concat(window.location.origin, "/auth/register?sponsorAccountId=").concat(props === null || props === void 0 ? void 0 : props.currentAccountId));
    showInfo({
      title: 'Copy to clipboard'
    });
  };
  return ___EmotionJSX("div", {
    className: "vk-aside-footer-wrapper"
  }, ___EmotionJSX("div", {
    className: "vk-two-cols-aside-footer"
  }, isActive && ___EmotionJSX(Grid, {
    container: true,
    spacing: 1,
    className: "vk-mb-8"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("div", {
    className: "vk-invite-link-button",
    role: "button",
    tabIndex: 0,
    onClick: copyInviteLink
  }, ___EmotionJSX(InviteLinkIcon, null), ___EmotionJSX("span", {
    className: "vk-invite-link-button-text"
  }, "Invite Link"))), ___EmotionJSX(Grid, {
    item: true,
    xs: true
  }, ___EmotionJSX("div", {
    className: "vk-help-button-wrapper"
  }, ___EmotionJSX("div", {
    className: "vk-help-button",
    role: "button",
    tabIndex: 0,
    onClick: function onClick() {
      return toggleSelectorShown();
    }
  }, ___EmotionJSX("div", {
    className: "vk-help-button-icon-wraper"
  }, ___EmotionJSX(QuestionMarkIcon, null)), ___EmotionJSX("span", {
    className: "vk-help-button-label"
  }, "Help")), ___EmotionJSX("div", {
    className: "vk-help-button-popper-wrapper ".concat(selectorShown ? 'popShow' : ''),
    onMouseLeave: function onMouseLeave() {
      return !clicked ? toggleSelectorShown() : null;
    }
  }, ___EmotionJSX("div", {
    className: "vk-help-button-popper-inner"
  }, ___EmotionJSX("div", {
    className: "vk-help-button-popper-list-wrapper"
  }, ___EmotionJSX("div", {
    className: "vk-help-button-popper-list-header"
  }, ___EmotionJSX("span", null, "Help & Support")), ___EmotionJSX("div", {
    className: "vk-help-button-popper-list-body"
  }, renderHelpList())))))))));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var _accounts$current;
  var packages = _ref.packages,
    accounts = _ref.accounts,
    user = _ref.user;
  return {
    currentPackage: packages === null || packages === void 0 ? void 0 : packages.current,
    currentAccountId: accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.id,
    user: user
  };
};
export default connect(mapStateToProps)(AsideFooter);