import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '../../../components/UI/Loader';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Loading = function Loading() {
  return ___EmotionJSX(Grid, {
    container: true,
    direction: "row",
    alignItems: "stretch",
    className: "subscriptions-container"
  }, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    spacing: 2,
    xs: 12,
    alignItems: "stretch",
    justify: "space-evenly"
  }, Array(4).fill().map(function (el, i) {
    return ___EmotionJSX(Grid, {
      item: true,
      sm: 3,
      className: "subscriptions-container-item",
      key: "loading-state-skeleton-".concat(i)
    }, ___EmotionJSX(Skeleton, {
      height: 18
    }), ___EmotionJSX(Skeleton, {
      width: 80,
      height: 18
    }), ___EmotionJSX(Skeleton, {
      width: 80,
      height: 18
    }), ___EmotionJSX(Skeleton, {
      height: 24
    }), ___EmotionJSX(Skeleton, {
      height: 18
    }), ___EmotionJSX(Skeleton, {
      height: 18
    }), ___EmotionJSX(Skeleton, {
      height: 18
    }));
  })));
};
export default Loading;