import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '../../../components/UI/Loader';
import { jsx as ___EmotionJSX } from "@emotion/react";
var LoadingPackage = function LoadingPackage() {
  return Array(12).fill().map(function (el, i) {
    return ___EmotionJSX(Grid, {
      item: true,
      xl: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      key: i
    }, ___EmotionJSX("div", {
      className: "wallet-gas-shop-package-card"
    }, ___EmotionJSX("div", {
      className: "wallet-gas-shop-package-card-top"
    }, ___EmotionJSX(Grid, {
      container: true,
      spacing: 2,
      alignItems: "center"
    }, ___EmotionJSX(Grid, {
      item: true,
      sm: true
    }, ___EmotionJSX(Skeleton, {
      height: 16
    }), ___EmotionJSX(Skeleton, {
      width: 60,
      height: 16
    })), ___EmotionJSX(Grid, {
      item: true,
      sm: "auto"
    }, ___EmotionJSX(Skeleton, {
      width: 50,
      height: 50,
      circle: true
    }))))));
  });
};
export default LoadingPackage;