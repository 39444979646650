import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { currencyType } from '../../../enums/wallets';
import BackButton from '../../../components/UI/Button/BackButton';
import { ReactComponent as RightIcn } from '../../../assets/images/layout-svg-icons/chevron-right100.svg';
import { ReactComponent as CopyIcn } from '../../../assets/images/layout-svg-icons/copiar.svg';
import noImageLogo from '../../../assets/images/icons/no-image.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TransactionDetails = function TransactionDetails() {
  return ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX(BackButton, null)), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Transaction Details"))), ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-header-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 3,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("img", {
    src: noImageLogo,
    alt: "onecoin-logo",
    className: "vk-image-responsive vk-crypto-transaction-details-panel-img"
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("h1", {
    className: "vk-crypto-transaction-hash-title"
  }, "f6ee8cc45b1cc59202a51d4f44d16aa4a1b3c1ab44d38df46b97d5bac3686e2d", ___EmotionJSX(CopyIcn, null))))), ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-body-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    justify: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-body-label-value-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, "AMOUNT", ___EmotionJSX("br", null), " TRANSACTED"), ___EmotionJSX("p", null, "6.46224148 ", currencyType.COIN))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-body-label-value-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, "BLOCK", ___EmotionJSX("br", null), " HEIGHT"), ___EmotionJSX("p", null, "635870"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-body-label-value-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, "FEE"), ___EmotionJSX("p", null, "0 ", ___EmotionJSX("br", null), " ", currencyType.COIN))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-body-label-value-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, "CONFIRMATIONS"), ___EmotionJSX("p", null, "1"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-body-label-value-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, "SIZE"), ___EmotionJSX("p", null, "377"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-body-label-value-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, "DATE"), ___EmotionJSX("p", null, "22/06/20 - 15:43:52", ___EmotionJSX("br", null), " UTC"))))), ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 6
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-head"
  }, ___EmotionJSX("p", null, "Hash")), ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-value"
  }, ___EmotionJSX(Link, {
    to: "/crypto/transaction-details",
    className: "vk-link"
  }, "oxvfkeityolivf4y60odon6gh1bpd90j7lt6w2udj4sdw1wg56m76b5bo8oc")))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-head"
  }, ___EmotionJSX("p", null, "Amount Transacted")), ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-value"
  }, ___EmotionJSX("p", null, "6.46224148 ", currencyType.COIN)))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 3
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-head"
  }, ___EmotionJSX("p", null, "Date")), ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-table-col-value"
  }, ___EmotionJSX("p", null, ___EmotionJSX("small", null, "14 minutes ago"), ___EmotionJSX("br", null), ___EmotionJSX("span", null, "22/06/20 - 15:43:52 UTC"))))))), ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-from-to-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 4
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-from-to-col-wrap"
  }, ___EmotionJSX("p", {
    className: "vk-crypto-transaction-details-panel-from-to-col-label"
  }, "From"), ___EmotionJSX("p", null, "Block Reward"))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-from-to-arrow-wrap"
  }, ___EmotionJSX(RightIcn, null))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-crypto-transaction-details-panel-from-to-col-wrap"
  }, ___EmotionJSX("p", {
    className: "vk-crypto-transaction-details-panel-from-to-col-label"
  }, "To"), ___EmotionJSX("p", null, ___EmotionJSX(Link, {
    to: "/crypto/transaction-details",
    className: "vk-link vk-link-sm"
  }, "oxvfkeityolivf4y60odon"), ' ', "6.46224148 ", currencyType.COIN, " ( 61,324.31 \u20AC )"), ___EmotionJSX("p", null, "OP_RETURN Unable to decode output address 0 ", currencyType.COIN, " ( 0.00 \u20AC )"), ___EmotionJSX("p", null, "OP_RETURN Unable to decode output address 0 ", currencyType.COIN, " ( 0.00 \u20AC )"), ___EmotionJSX("p", null, "OP_RETURN Unable to decode output address 0 ", currencyType.COIN, " ( 0.00 \u20AC )"), ___EmotionJSX("br", null), ___EmotionJSX("p", null, "Fee: ", ___EmotionJSX("span", null, "0 ( 0 \u20AC )")), ___EmotionJSX("p", null, "Total value: ", ___EmotionJSX("span", null, "6.46224148 ", currencyType.COIN))))))));
};
export default TransactionDetails;