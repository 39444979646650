import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import React, { Fragment } from 'react';
import { Redirect, Route, Switch, matchPath } from 'react-router-dom';
import MainTabs from '../../components/UI/Shared/MainTabs';
import LatestBlocks from './tabs/LatestBlocks';
import LatestMinedTransactions from './tabs/LatestMinedTransactions';
// import UnconfirmedTransactions from './tabs/UnconfirmedTransactions';
import BlockDetails from './inner/BlockDetails';
import TransactionDetails from './inner/TransactionDetails';
import CryptoHeaderData from './components/CryptoHeaderData';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CryptoPage = function CryptoPage(props) {
  var location = props.location;
  var path = props.match.path;
  var tabs = [{
    label: 'Latest Blocks',
    route: "".concat(path, "/latest-blocks")
  }, {
    label: 'Latest Mined Transactions',
    route: "".concat(path, "/latest-mined-transactions")
  }
  // {
  //   label: 'Unconfirmed Transactions',
  //   route: `${path}/unconfirmed-transactions`,
  // },
  ];

  var showHeader = tabs.find(function (_ref) {
    var route = _ref.route;
    return matchPath(location.pathname, route);
  });
  return ___EmotionJSX(Fragment, null, showHeader && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "vk-crypto-header-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX(CryptoHeaderData, null))), ___EmotionJSX(MainTabs, {
    tabs: tabs
  })), ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX(Switch, null, ___EmotionJSX(Route, {
    path: "".concat(path, "/latest-blocks"),
    component: LatestBlocks
  }), ___EmotionJSX(Route, {
    path: "".concat(path, "/latest-mined-transactions"),
    component: LatestMinedTransactions
  }), ___EmotionJSX(Route, {
    path: "".concat(path, "/block-details"),
    component: BlockDetails
  }), ___EmotionJSX(Route, {
    path: "".concat(path, "/transaction-details"),
    component: TransactionDetails
  }), ___EmotionJSX(Redirect, {
    to: tabs[0].route
  }))));
};
export default CryptoPage;