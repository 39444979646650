function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { PAYMENT_TYPE } from '../../../enums/paymentMethods';
import { ReactComponent as SuccessIcn } from '../../../assets/images/layout-svg-icons/checked.svg';
import { ReactComponent as FailIcn } from '../../../assets/images/layout-svg-icons/cancel.svg';
import googlePlayBadgeLogo from '../../../assets/images/logos/google-play-badge.png';
import appStoreBadgeSoonLogo from '../../../assets/images/logos/app-store-badge-soon.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var reasonMap = function reasonMap(user) {
  return _defineProperty({}, PAYMENT_TYPE.MOBILE_ACCESS, {
    component: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h4", {
      className: "vk-mb-24"
    }, "Your subscription is successfully activated! You have access to the OneApp now. More instructions was sent to you email address ", user.email, ", please check your email and read how to install the app."), ___EmotionJSX("div", {
      className: "vk-shop-order-complete-content-store"
    }, ___EmotionJSX("a", {
      target: "_blank",
      rel: "noopener noreferrer",
      href: "https://onelife.blob.core.windows.net/help-faq/android-oneecosystem-v1.2.0.apk"
    }, ___EmotionJSX("img", {
      src: googlePlayBadgeLogo,
      alt: "google-play-logo",
      className: "vk-image-responsive"
    })), ___EmotionJSX("img", {
      src: appStoreBadgeSoonLogo,
      alt: "app-store-logo",
      className: "vk-image-responsive"
    }))),
    linkTo: '/profile/overview',
    linkText: 'Back to Profile'
  });
};
var PaymentComplete = function PaymentComplete(_ref2) {
  var location = _ref2.location,
    history = _ref2.history,
    user = _ref2.user;
  var _qs$parse = qs.parse(location.search),
    status = _qs$parse.status,
    reason = _qs$parse.reason;
  if (!status || !reason) return history.replace('/');
  var isSuccessful = status !== '3';
  var content = reasonMap(user)[reason];
  return ___EmotionJSX("div", {
    className: "vk-shop-order-complete"
  }, ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content"
  }, ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content-image"
  }, isSuccessful ? ___EmotionJSX(SuccessIcn, null) : ___EmotionJSX(FailIcn, null)), ___EmotionJSX("h3", {
    className: "vk-page-heading-balance"
  }, isSuccessful ? 'Your payment was completed successfully!' : 'Payment failed!'), ___EmotionJSX("div", {
    className: "vk-shop-order-complete-content-text"
  }, isSuccessful ? content === null || content === void 0 ? void 0 : content.component : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, "Please contact our support agent for more details at"), ___EmotionJSX("a", {
    href: "mailto:support@oneecosystem.eu"
  }, " support@oneecosystem.eu"), ___EmotionJSX("p", null, "Error code: ", ___EmotionJSX("b", null, status)))), (content === null || content === void 0 ? void 0 : content.linkTo) && ___EmotionJSX(Link, {
    to: content.linkTo,
    className: "vk-link"
  }, content === null || content === void 0 ? void 0 : content.linkText))));
};
PaymentComplete.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object
};
var mapStateToProps = function mapStateToProps(_ref3) {
  var user = _ref3.user;
  return {
    user: user
  };
};
export default connect(mapStateToProps)(PaymentComplete);