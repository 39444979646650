import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShowDate = function ShowDate(_ref) {
  var date = _ref.date;
  var day = moment(date).format('DD');
  var month = moment(date).format('MMM');
  return ___EmotionJSX("div", null, ___EmotionJSX("p", null, day), ___EmotionJSX("p", null, month));
};
ShowDate.propTypes = {
  date: PropTypes.string
};