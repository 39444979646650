import React from 'react';
import startupBonusExample from '../../../assets/images/bonuses/startup-bonus-example.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var StartUpBonusInfo = function StartUpBonusInfo() {
  return ___EmotionJSX("div", null, ___EmotionJSX("h1", {
    className: "bonus-help-modal-title"
  }, "START-UP BONUS"), ___EmotionJSX("div", {
    className: "bonus-help-modal-chapter"
  }, ___EmotionJSX("h2", null, "Requirements"), ___EmotionJSX("div", {
    className: "bonus-help-modal-description"
  }, ___EmotionJSX("p", null, "Start-Up Bonus is applicable within the first 30 calendar days after using their first Educational module. An IMA receives 10% of the Business Volume above 5500 points accumulated in his account by accounts who were registered using his invitation link."), ___EmotionJSX("p", null, "The Start-Up bonus is being paid entirely in the IMAS Trading wallet.")), ___EmotionJSX("img", {
    alt: "bonus_example",
    src: startupBonusExample,
    className: "bonus-help-modal-image"
  }), ___EmotionJSX("div", {
    className: "direct-startup-help-modal-example-container"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-example-box"
  }, ___EmotionJSX("span", null, "Example:"), ___EmotionJSX("h4", null, "5500 BV DS x 10% = 550 EUR")), ___EmotionJSX("div", {
    className: "bonus-help-modal-bottom-box"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-bottom-box-text"
  }, ___EmotionJSX("span", null, "DS")), ___EmotionJSX("div", {
    className: "bonus-help-modal-bottom-box-description"
  }, ___EmotionJSX("p", null, "- Directly Sponsored"), ___EmotionJSX("p", null, "- Direct/Personal Sales"))))));
};
export default StartUpBonusInfo;