import _fromPairs from "lodash/fromPairs";
import "core-js/modules/es.array.map.js";
import { paymentMethodTypes } from '../../enums/paymentMethods';
export var myPaymentMethodsModel = function myPaymentMethodsModel(data) {
  var resMethods = _fromPairs(data === null || data === void 0 ? void 0 : data.withdrawalMethods.map(function (el) {
    var _paymentMethodTypes$e;
    return [(_paymentMethodTypes$e = paymentMethodTypes[el.type]) !== null && _paymentMethodTypes$e !== void 0 ? _paymentMethodTypes$e : el.type, JSON.parse(el.value)];
  }));
  return resMethods;
};
export var updatePaymentMethodsModel = function updatePaymentMethodsModel(type, value) {
  return {
    type: type,
    value: JSON.stringify(value)
  };
};