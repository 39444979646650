import _startCase from "lodash/startCase";
import "core-js/modules/es.function.name.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { accountRank, accountRankInformation } from '../../../enums/account';
import { jsx as ___EmotionJSX } from "@emotion/react";
var RankInformation = function RankInformation(props) {
  var _accountRankInformati;
  var packages = props.packages,
    leaderRank = props.leaderRank,
    topRank = props.topRank;
  var currentPackage = packages.current.logo;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h1", {
    className: "your-rank-info-header"
  }, "Your Rank Information"), ___EmotionJSX("div", {
    className: "rank-info-wrapper"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 3
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 6
  }, ___EmotionJSX("div", {
    className: "current-rank"
  }, currentPackage ? ___EmotionJSX("img", {
    src: currentPackage,
    alt: "package",
    className: "current-package-wrap"
  }) : null, ___EmotionJSX("div", null, ___EmotionJSX("h2", {
    className: "current-package"
  }, "Current Module"), ___EmotionJSX("h2", {
    className: "name-package"
  }, packages.current.name), ___EmotionJSX(Link, {
    to: "/shop/all-packages",
    className: "rank-info-view-all-package"
  }, "View all modules")))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 6
  }, ___EmotionJSX("div", {
    className: "current-rank"
  }, leaderRank !== accountRank.Rookie && ___EmotionJSX("img", {
    src: (_accountRankInformati = accountRankInformation[leaderRank]) === null || _accountRankInformati === void 0 ? void 0 : _accountRankInformati.rankImage,
    alt: "package",
    className: "current-package-wrap"
  }), ___EmotionJSX("div", null, ___EmotionJSX("h2", {
    className: "current-package"
  }, "Top rank"), ___EmotionJSX("h2", {
    className: "name-package"
  }, topRank === accountRank.Rookie ? 'N/A' : _startCase(topRank)), ___EmotionJSX("h2", {
    className: "current-package"
  }, "Current rank"), ___EmotionJSX("h2", {
    className: "name-package"
  }, leaderRank === accountRank.Rookie ? 'N/A' : leaderRank)))))));
};
var mapStateProps = function mapStateProps(_ref) {
  var packages = _ref.packages,
    accounts = _ref.accounts;
  return {
    packages: packages,
    leaderRank: accounts.current.rank,
    topRank: accounts.current.topRank
  };
};
export default connect(mapStateProps)(RankInformation);