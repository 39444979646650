import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
import _get from "lodash/get";
import _isFunction from "lodash/isFunction";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NAItem = ___EmotionJSX("div", {
  className: "table-data__cell__no-value"
});
export var rowClassName = 'table-data__row';
export var columnClassName = 'table-data__cell';
export var displayValue = function displayValue(column, row) {
  var _value;
  var value = _get(row, column.value);
  value = value && column.mapValue ? column.mapValue(value.toLowerCase()) : value;
  value = value && column.dateTimeFormat ? moment(value).format(column.dateTimeFormat) : value;
  if (!_isNil(value)) value = String(value);
  return (_value = value) !== null && _value !== void 0 && _value.length ? value : NAItem;
};
export var columnStyles = function columnStyles(_ref) {
  var width = _ref.width;
  return _objectSpread({}, width && {
    flex: "0 0 ".concat(width)
  });
};
var TableBody = function TableBody(props) {
  var data = props.data,
    columns = props.columns;
  return ___EmotionJSX("div", {
    className: "table-data__body"
  }, data.map(function (row, i) {
    return _isFunction(row.render) ? row.render({
      row: row,
      NAItem: NAItem,
      columns: columns,
      rowClassName: rowClassName,
      columnClassName: columnClassName,
      columnStyles: columnStyles,
      displayValue: displayValue
    }) : ___EmotionJSX("div", {
      key: "row-".concat(i),
      className: rowClassName
    }, columns.map(function (column, j) {
      var ColumnRender = column === null || column === void 0 ? void 0 : column.render;
      return ___EmotionJSX("div", {
        key: "column-".concat(i, "-").concat(j),
        style: columnStyles(column),
        className: columnClassName
      }, _isFunction(column.render) ? ___EmotionJSX(ColumnRender, _extends({}, row, {
        NAItem: NAItem
      })) : displayValue(column, row));
    }), ' ');
  }));
};
TableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array
};
export default TableBody;