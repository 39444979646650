import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.repeat.js";
import "core-js/modules/es.array.slice.js";
export var ibanMask = function ibanMask(value) {
  if (!value) return 'N/A';
  var maskedValue = value.replace(/(\+?\d{3})(\d+)(\d{2})/g, function (match, start, middle, end) {
    return start + '*'.repeat(middle.length) + end;
  });
  return maskedValue;
};
export var bicSwiftMask = function bicSwiftMask(value) {
  var maskedValue = value.slice(5, value.length).replace(/^/, '**/**/');
  return maskedValue;
};
export var accountNumberMask = function accountNumberMask(value) {
  var maskedValue = value.replace(/(\+?\w{1})(\w+)(\w{1})/g, function (match, start, middle, end) {
    return start + '*'.repeat(middle.length) + end;
  });
  return maskedValue;
};
export var cardNumberMask = function cardNumberMask(value) {
  var maskedValue = value.replace(/(\+?\w{1})(\w+)(\w{1})/g, function (match, start, middle, end) {
    return start + '*'.repeat(middle.length) + end;
  });
  return maskedValue;
};