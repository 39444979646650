import React from 'react';
import bg1Logo from '../../assets/images/bg1.jpg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ErrorPage = function ErrorPage() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: "kt-grid kt-grid--ver kt-grid--root"
  }, ___EmotionJSX("div", {
    className: "kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1",
    style: {
      backgroundImage: "url(".concat(bg1Logo, ")")
    }
  }, ___EmotionJSX("div", {
    className: "kt-error-v1__container"
  }, ___EmotionJSX("h1", {
    className: "kt-error-v1__number"
  }, "404"), ___EmotionJSX("p", {
    className: "kt-error-v1__desc"
  }, "OOPS! Something went wrong here")))));
};
export default ErrorPage;