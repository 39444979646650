import React from 'react';
import { Grid } from '@material-ui/core';
import { updatePassword } from '@oneecosystem/authenticate';
import Button from '../../../components/UI/Button/Button';
import IconTextCart from '../../../components/UI/Carts/IconTextCart';
import { PortletWrapper, PortletHeader, PortletBody } from '../../../components/UI/Portlet/Portlet';
import { ReactComponent as PadlockIcn } from '../../../assets/images/layout-svg-icons/icn-big-padlock-wt-bg.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Password = function Password() {
  return ___EmotionJSX("div", {
    className: "vk-profile-change-pass-wrap"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    justify: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: true
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Profile Password")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12
  }, ___EmotionJSX("hr", {
    className: "vk-tab-header-separator"
  })))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 8,
    lg: 6
  }, ___EmotionJSX(PortletWrapper, {
    pwStyle: "vk-change-pass-potlet"
  }, ___EmotionJSX(PortletHeader, null, ___EmotionJSX(IconTextCart, {
    image: ___EmotionJSX(PadlockIcn, null),
    title: "Change Profile Password"
  })), ___EmotionJSX(PortletBody, null, ___EmotionJSX("a", {
    href: updatePassword()
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-l",
    link: true
  }, "Change Password")))))));
};
export default Password;