import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.repeat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { PortletWrapper, PortletBody } from '../../../components/UI/Portlet/Portlet';
import { countryComp } from '../../../components/UI/Shared/countrySelectOptions';
import { findCountryImage } from '../../../enums/countries';
import { ReactComponent as EyeIcn } from '../../../assets/images/layout-svg-icons/icn-eye.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ProfileInfo = function ProfileInfo(props) {
  var _user$email, _user$phone, _user$idNumber$split$, _user$idNumber2, _user$idExpiration2;
  var user = props.user;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    idCardNumberToogle = _useState2[0],
    setIdCardNumberToogle = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    idCardExpDateToogle = _useState4[0],
    setIdCardExpDateToogle = _useState4[1];
  var idCardNumberMaskToogleEye = function idCardNumberMaskToogleEye() {
    setIdCardNumberToogle(function (prevState) {
      return !prevState;
    });
  };
  var idCardNumberMask = function idCardNumberMask() {
    var _user$idNumber;
    var idCardNumber = (_user$idNumber = user.idNumber) !== null && _user$idNumber !== void 0 ? _user$idNumber : 'N/A';
    var maskedValue = idCardNumber.replace(/(\+?\d{3})(\d+)(\d{2})/g, function (match, start, middle, end) {
      return start + '*'.repeat(middle.length) + end;
    });
    return maskedValue;
  };
  var idCardExpDateToogleEye = function idCardExpDateToogleEye() {
    setIdCardExpDateToogle(function (prevState) {
      return !prevState;
    });
  };
  var idCardExpDaterMask = function idCardExpDaterMask() {
    var _user$idExpiration$sp, _user$idExpiration;
    var idCardExpiryDate = (_user$idExpiration$sp = (_user$idExpiration = user.idExpiration) === null || _user$idExpiration === void 0 ? void 0 : _user$idExpiration.split('T')[0]) !== null && _user$idExpiration$sp !== void 0 ? _user$idExpiration$sp : 'N/A';
    var maskedValue = idCardExpiryDate.slice(0, 5).replace(/$/, '****').replace(/-/g, '/');
    return maskedValue;
  };
  return ___EmotionJSX(PortletWrapper, null, ___EmotionJSX(PortletBody, {
    pbStyle: "vk-profile-info-wraper"
  }, ___EmotionJSX("h2", {
    className: "vk-page-heading"
  }, "Profile Information"), ___EmotionJSX("div", {
    className: "vk-profile-info-section"
  }, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "Contact information"), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "Email Address")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-value"
  }, (_user$email = user.email) !== null && _user$email !== void 0 ? _user$email : 'N/A')))), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "Phone Number")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-value"
  }, (_user$phone = user.phone) !== null && _user$phone !== void 0 ? _user$phone : 'N/A')))), ___EmotionJSX("div", {
    className: "kt-clearfix"
  })), ___EmotionJSX("div", {
    className: "vk-profile-info-section"
  }, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "Address Details"), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "Country")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("div", {
    className: "vk-profile-info-section-item-value"
  }, countryComp(findCountryImage(user.country), 'vk-profile-ingo-flag-img'), ___EmotionJSX("span", null, user.country))))), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "City")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-value"
  }, user.city)))), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "Address")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-value"
  }, user.address)))), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "Zip Code")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-value"
  }, user.zip)))), ___EmotionJSX("div", {
    className: "kt-clearfix"
  })), ___EmotionJSX("div", {
    className: "vk-profile-info-section"
  }, ___EmotionJSX("h4", {
    className: "vk-sm-section-heading"
  }, "Identification"), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "Passport / ID Card Number")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("div", {
    className: "vk-profile-info-section-item-value"
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: "vk-id-eye ".concat(idCardNumberToogle ? 'visible' : ''),
    onClick: idCardNumberMaskToogleEye
  }, ___EmotionJSX(EyeIcn, null)), ___EmotionJSX("span", null, idCardNumberToogle ? (_user$idNumber$split$ = (_user$idNumber2 = user.idNumber) === null || _user$idNumber2 === void 0 ? void 0 : _user$idNumber2.split('T')[0].replace(/-/g, '/')) !== null && _user$idNumber$split$ !== void 0 ? _user$idNumber$split$ : 'N/A' : idCardNumberMask()))))), ___EmotionJSX("div", {
    className: "vk-profile-info-section-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 0,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("p", {
    className: "vk-profile-info-section-item-label"
  }, "Passport / ID Card Expiry Date")), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: true
  }, ___EmotionJSX("div", {
    className: "vk-profile-info-section-item-value"
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: "vk-id-eye ".concat(idCardExpDateToogle ? 'visible' : ''),
    onClick: idCardExpDateToogleEye
  }, ___EmotionJSX(EyeIcn, null)), ___EmotionJSX("span", null, idCardExpDateToogle ? (_user$idExpiration2 = user.idExpiration) === null || _user$idExpiration2 === void 0 ? void 0 : _user$idExpiration2.split('T')[0].replace(/-/g, '/') : idCardExpDaterMask()))))), ___EmotionJSX("div", {
    className: "kt-clearfix"
  }))));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var user = _ref.user;
  return {
    user: user
  };
};
export default connect(mapStateToProps)(ProfileInfo);