import "core-js/modules/es.array.map.js";
import React from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Login from './Login';
import Register from './Register';
import PrivacyPolicy from '../help/termsAndConditions/PrivacyPolicy';
import TermsAndConditions from '../help/termsAndConditions/TermsAndConditions';
import { dataNewMembers } from './definitions';
import mapBgIcon from '../../assets/images/logos/map-bg.jpg';
import '../../assets/sass/pages/login/login.scss';
import ForgotPassword from './ForgotPassword';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AuthPage = function AuthPage() {
  return ___EmotionJSX("div", {
    className: "kt-login"
  }, ___EmotionJSX("div", {
    className: "kt-login__aside"
  }, ___EmotionJSX("div", {
    className: "kt-login__aside__top"
  }, ___EmotionJSX(Link, {
    to: "/",
    className: "kt-login__logo"
  }, ___EmotionJSX("img", {
    alt: "Logo",
    src: "https://onelife.blob.core.windows.net/images/one_ecosystem_logo_light_v2.png"
  })), ___EmotionJSX("p", {
    className: "kt-login-paragraph"
  }, "OneEcosystem is as revolutionary business concept that aims to bring numerous opportunities to those who want to take charge of their life."), ___EmotionJSX(Link, {
    to: "/",
    className: "kt-login-subtitle"
  }, "About OneEcosystem")), ___EmotionJSX("img", {
    alt: "img",
    src: mapBgIcon,
    className: "kt-grid-item-image"
  }), ___EmotionJSX("div", {
    className: "kt-login__aside__bottom"
  }, ___EmotionJSX("h2", {
    className: "kt-login__title"
  }, "New Members"), ___EmotionJSX(Grid, {
    container: true,
    direction: "row",
    justify: "flex-start",
    alignItems: "flex-start",
    className: "kt-login__members"
  }, dataNewMembers.map(function (el) {
    return ___EmotionJSX(Grid, {
      item: true,
      md: 4,
      key: el.subtitle
    }, ___EmotionJSX("div", {
      className: "kt-login__members-box"
    }, ___EmotionJSX("h2", {
      className: "kt-login__members-number"
    }, el.number), ___EmotionJSX("p", {
      className: "kt-login__members-subtitle"
    }, el.subtitle)));
  })))), ___EmotionJSX("div", {
    className: "kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
  }, ___EmotionJSX(Switch, null, ___EmotionJSX(Route, {
    path: "/auth/login",
    component: Login
  }), ___EmotionJSX(Route, {
    path: "/auth/forgot-password",
    component: ForgotPassword
  }), ___EmotionJSX(Route, {
    path: "/auth/register",
    component: Register
  }), ___EmotionJSX(Route, {
    path: "/terms",
    component: TermsAndConditions
  }), ___EmotionJSX(Route, {
    path: "/privacy-policy",
    component: PrivacyPolicy
  }), ___EmotionJSX(Redirect, {
    to: "/auth/login"
  }))));
};
export default AuthPage;