import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcn } from '../../../assets/images/layout-svg-icons/icn-alert-warning.svg';
import { ReactComponent as SuccessIcn } from '../../../assets/images/layout-svg-icons/icn-sm-checkmark-tick.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CoinInput = function CoinInput(props) {
  var min = props.min,
    max = props.max,
    type = props.type,
    label = props.label,
    appendLabel = props.appendLabel,
    prependLabel = props.prependLabel,
    placeholder = props.placeholder,
    name = props.name,
    value = props.value,
    error = props.error,
    errorMsg = props.errorMsg,
    success = props.success,
    successMsg = props.successMsg,
    _props$disabled = props.disabled,
    disabled = _props$disabled === void 0 ? false : _props$disabled,
    handleBlur = props.handleBlur,
    handleChange = props.handleChange;
  var inputClasses = ['vk-form-control'];
  var formGroupClasses = ['vk-form-group'];
  if (error) {
    inputClasses.push('vk-invalid-input');
    formGroupClasses.push('vk-invalid-group');
  }
  if (success && value) {
    inputClasses.push('vk-valid-input');
    formGroupClasses.push('vk-valid-group');
  }
  return ___EmotionJSX("div", {
    className: formGroupClasses.join(' ')
  }, label && ___EmotionJSX("label", {
    className: "vk-form-label"
  }, label), ___EmotionJSX("div", {
    className: "vk-input-append-wrap"
  }, prependLabel && ___EmotionJSX("div", {
    className: "vk-input-prepend-label"
  }, prependLabel), ___EmotionJSX("input", {
    className: inputClasses.join(' '),
    type: type,
    value: value,
    placeholder: placeholder,
    name: name,
    onBlur: handleBlur,
    onChange: handleChange,
    step: ".1",
    min: min,
    max: max,
    disabled: disabled
  }), appendLabel && ___EmotionJSX("div", {
    className: "vk-input-append-label"
  }, appendLabel)), ___EmotionJSX("div", {
    className: "vk-input-validation-message vk-ivm-error"
  }, ___EmotionJSX("i", null, ___EmotionJSX(ErrorIcn, null)), ___EmotionJSX("span", null, errorMsg)), ___EmotionJSX("div", {
    className: "vk-input-validation-message vk-ivm-success"
  }, ___EmotionJSX("i", null, ___EmotionJSX(SuccessIcn, null)), ___EmotionJSX("span", null, successMsg !== null && successMsg !== void 0 ? successMsg : "".concat(label, " is correct"))));
};
CoinInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  appendLabel: PropTypes.string,
  prependLabel: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  success: PropTypes.bool,
  successMsg: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
};
export default CoinInput;