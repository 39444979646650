import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.to-string.js";
export var isValidCreditCard = function isValidCreditCard(value) {
  var checkSum = function checkSum(sum, check) {
    return (10 - sum % 10) % 10 === check;
  };
  var multiply = function multiply(number) {
    var multiplied = number * 2;
    return multiplied > 9 ? multiplied - 9 : multiplied;
  };
  return String(value).replace(/\D/g, '').split('').map(Number).reduce(function (acc, el, index, arr) {
    return arr.length - 1 === index ? checkSum(acc, el) : acc + (index % 2 === 0 ? multiply(el) : el);
  }, 0);
};
export var validationsRegexp = {
  cvvcode: /^[0-9]{0,3}$/,
  cardNumber: /^[-0-9]*$/,
  userName: /^[a-zA-Z0-9_@\-.+]*$/,
  nickName: /^[A-Za-z0-9]*$/,
  email: /^[a-zA-Z\-0-9!#$%&'*+/=?^_`{|}~.\s@]*$/,
  emailValidPattern: /^[\w-.!#$%&'*+/=?^_`{|}~.\s]+@([\w-]+\.)+[\w-]{2,}$/,
  passwordSpecialCharPattern: /(?=.*[!?/‘`“”~|@#$%^&*()\\\-_=+{};:,<.>[\]])/,
  onlyNumbers: /^[0-9]*$/,
  onlyLetters: /^[a-zA-Z\s]*$/,
  lettersSpaceDash: /^[a-zA-Z-\s]*$/,
  lettersNumbersPunctuation: /^[a-zA-Z0-9-.,\s]*$/,
  lettersNumbers: /^[a-zA-Z0-9\s]*$/,
  lettersNumbersDash: /^[a-zA-Z0-9\s-]*$/,
  guid: /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/,
  phone: /^[+][0-9]{6,30}/,
  creditCard: /^[4|5]/,
  // /^[0-9]{1,}\.*[0-9]{0,6}$/
  getDecimalAmountValidation: function getDecimalAmountValidation() {
    var decimalLength = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2;
    return new RegExp("^[0-9]{1,}\\.*[0-9]{0,".concat(decimalLength, "}$"));
  }
};