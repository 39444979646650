import React from 'react';
import { ReactComponent as ThreeDotsIcn } from '../../../assets/images/layout-svg-icons/three-dots.svg';
// import PropTypes from "prop-types";
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableActionCol = function NetworkTableActionCol() {
  return (
    // const { name } = props;

    ___EmotionJSX("div", {
      className: "vk-network-table-action-col"
    }, ___EmotionJSX("div", {
      className: "vk-network-table-col-action-btn"
    }, ___EmotionJSX(ThreeDotsIcn, null)))
  );
};
// NetworkTableActionCol.propTypes = {
//   name: PropTypes.string
// };

export default NetworkTableActionCol;