import "core-js/modules/es.array.index-of.js";
import { getStorage, setStorage, removeStorage } from '../utils/storage';
var localStorageLastLocationKey = 'lastLocation';
var acceptLocation = function acceptLocation(lastLocation) {
  if (lastLocation && lastLocation.pathname && lastLocation.pathname !== '/' && lastLocation.pathname.indexOf('auth') === -1 && lastLocation.pathname !== '/logout') return true;
  return false;
};
export var saveLastLocation = function saveLastLocation(lastLocation) {
  var localStorageLocations = getStorage(localStorageLastLocationKey);
  var _useLocations = localStorageLocations ? JSON.parse(localStorageLocations) : [];
  if (acceptLocation(lastLocation)) {
    _useLocations.push(lastLocation.pathname);
    setStorage(localStorageLastLocationKey, JSON.stringify(_useLocations), 120);
  }
};
export var forgotLastLocation = function forgotLastLocation() {
  removeStorage(localStorageLastLocationKey);
};
export var getLastLocation = function getLastLocation() {
  var localStorageLocations = getStorage(localStorageLastLocationKey);
  if (!localStorageLocations) return '/';
  var _userLocations = JSON.parse(localStorageLocations);
  var result = _userLocations.length > 0 ? _userLocations.pop() : '/';
  return result;
};
export var MY_NETWORK_MENU_OVERRIDES = {
  headerOverrides: [{
    systemName: 'my-network-binary-tree',
    title: 'My Community',
    page: 'my-community/affiliates'
  }, {
    systemName: 'my-network-community',
    title: 'My Community',
    page: 'my-community/community'
  }],
  asideOverrides: [{
    systemName: 'my-network',
    title: 'My Community',
    desc: 'My Community',
    page: 'my-community'
  }]
};