import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { LastLocationProvider } from 'react-router-last-location';
import { store, persistor } from './app/store';
import Routes from './app/router/Routes';
import PageSpinner from './app/components/UI/Loader/PageSpinner';
import { jsx as ___EmotionJSX } from "@emotion/react";
var App = function App() {
  return (/* Provide Redux store */
    ___EmotionJSX(Provider, {
      store: store
    }, ' ', ___EmotionJSX(PersistGate, {
      persistor: persistor,
      loading: ___EmotionJSX(PageSpinner, null)
    }, ___EmotionJSX(BrowserRouter, null, ___EmotionJSX(LastLocationProvider, null, ___EmotionJSX(Routes, null)))))
  );
};
export default App;