import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var randomString = function randomString() {
  return Array(40).fill().map(function () {
    return Math.random().toString(36).slice(2, Math.round(2 + Math.random() * 4));
  }).join('').slice(0, 60);
};
export var linkText = function linkText(key, route) {
  return function (row, NAItem) {
    return String(row[key]).length ? ___EmotionJSX(Link, {
      to: route,
      className: "vk-link"
    }, row[key]) : NAItem;
  };
};
export var amount = function amount(key) {
  return function (row, NAItem) {
    var amount = row[key];
    return String(amount).length ? ___EmotionJSX("span", null, "".concat(amount, " GAS")) : NAItem;
  };
};
export var dateTime = function dateTime(key) {
  return function (row) {
    var transactionDate = moment.utc(row[key]);
    var dateNow = moment.utc();
    var difference = moment.duration(transactionDate.diff(dateNow));
    return ___EmotionJSX("div", {
      className: "vk-crypto-date-time-col-format"
    }, ___EmotionJSX("span", null, "".concat(transactionDate.local().format('DD/MM/YYYY, HH:mm:ss'))), ___EmotionJSX("span", {
      className: "vk-crypto-date-time-min-ago"
    }, difference.humanize(true, {
      d: 7,
      w: 4
    })));
  };
};