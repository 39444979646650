import "core-js/modules/es.array.concat.js";
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import useCountdown from '../../../components/UI/Shared/useCountdown';
import { getNextMondayString } from '../../../utils/moment';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NoBonusRounds = function NoBonusRounds() {
  var timerCountdown = useBonusRoundCountdown();
  var useBonusRoundCountdown = function useBonusRoundCountdown() {
    var nextMondayString = getNextMondayString();
    useCountdown(nextMondayString);
  };
  return ___EmotionJSX("div", {
    className: "vk-bonus-no-bonus-rounds-container"
  }, ___EmotionJSX("h2", null, "Your first bonus run is after:", ' ', "".concat(timerCountdown.days, " days, ").concat(timerCountdown.hours, " hours, ").concat(timerCountdown.min, " minutes")), ___EmotionJSX("h4", null, "Buy a new package and use it to improve your earnings."), ___EmotionJSX(Link, {
    to: "/shop/all-packages"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-m",
    link: true
  }, "Shop")));
};
export default NoBonusRounds;