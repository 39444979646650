import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ColorBadge = function ColorBadge(props) {
  var type = props.type,
    children = props.children;
  return ___EmotionJSX("span", {
    className: clsx("status-badge-".concat(type))
  }, children);
};
ColorBadge.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any
};
export default ColorBadge;