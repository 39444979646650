import _isNil from "lodash/isNil";
import React from 'react';
import { ReactComponent as BvIcn } from '../../../assets/images/layout-svg-icons/icn-briefcase.svg';
import { ReactComponent as TokensIcn } from '../../../assets/images/layout-svg-icons/icn-token.svg';
import { ReactComponent as DifficultyIcn } from '../../../assets/images/layout-svg-icons/icn-difficulty.svg';
import { ReactComponent as BurnSolid } from '../../../assets/images/layout-svg-icons/burn-solid.svg';
import Skeleton from '../../../components/UI/Loader/BackboneSkeleton';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackageDetailsView = function PackageDetailsView(_ref) {
  var _packageData$academyT;
  var packageData = _ref.packageData,
    config = _ref.config;
  return ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(BvIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Business Volume"), ___EmotionJSX("h6", {
    className: "vk-shop-package-details-infobox-left-item-text-subtitle"
  }, "What\u2019s the Business volume")), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, packageData === null || packageData === void 0 ? void 0 : packageData.businessVolume)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(TokensIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Promotional Tokens"), ___EmotionJSX("h6", {
    className: "vk-shop-package-details-infobox-left-item-text-subtitle"
  }, "How many Promotional Tokens you get?")), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, packageData === null || packageData === void 0 ? void 0 : packageData.tokens)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(TokensIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Academy Tokens"), ___EmotionJSX("h6", {
    className: "vk-shop-package-details-infobox-left-item-text-subtitle"
  }, "How many Academy Tokens you get?")), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, (_packageData$academyT = packageData === null || packageData === void 0 ? void 0 : packageData.academyTokens) !== null && _packageData$academyT !== void 0 ? _packageData$academyT : 0)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(DifficultyIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Diffuclty rate"), ___EmotionJSX("h6", {
    className: "vk-shop-package-details-infobox-left-item-text-subtitle"
  }, "Preferential difficulty rate")), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, !_isNil(config) && !_isNil(packageData === null || packageData === void 0 ? void 0 : packageData.difficulty) ? config - (packageData === null || packageData === void 0 ? void 0 : packageData.difficulty) : ___EmotionJSX(Skeleton, {
    width: 30,
    height: 20
  }))), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(DifficultyIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Exchange rate"), ___EmotionJSX("h6", {
    className: "vk-shop-package-details-infobox-left-item-text-subtitle"
  }, "Preferential exchange rate")), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, !_isNil(config) && !_isNil(packageData === null || packageData === void 0 ? void 0 : packageData.exchangeReduction) ? config - (packageData === null || packageData === void 0 ? void 0 : packageData.exchangeReduction) : ___EmotionJSX(Skeleton, {
    width: 30,
    height: 20
  }))), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon-gas"
  }, ___EmotionJSX(BurnSolid, null))), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Gas"), ___EmotionJSX("h6", {
    className: "vk-shop-package-details-infobox-left-item-text-subtitle"
  }, "What\u2019s the Gas volume")), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, packageData === null || packageData === void 0 ? void 0 : packageData.gasAmount)));
};
export default PackageDetailsView;