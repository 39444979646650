import React from 'react';
import matchingBonusRequirementsImg from '../../../assets/images/bonuses/matching-bonus-requirements.png';
import matchingBonusGenerationsImg from '../../../assets/images/bonuses/matching-bonus-generations.png';
import matchingBonusStarterImg from '../../../assets/images/bonuses/matching-bonus-starter-example.png';
import matchingBonusTraderImg from '../../../assets/images/bonuses/matching-bonus-trader-example.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MatchingBonusInfo = function MatchingBonusInfo() {
  return ___EmotionJSX("div", null, ___EmotionJSX("h1", {
    className: "bonus-help-modal-title"
  }, "MATCHING BONUS"), ___EmotionJSX("div", {
    className: "bonus-help-modal-chapter"
  }, ___EmotionJSX("h2", null, "Requirements"), ___EmotionJSX("div", {
    className: "bonus-help-modal-description"
  }, ___EmotionJSX("p", null, "Members must have aquired the Starter Module in order to eligible."), ___EmotionJSX("p", null, "Members must have at least one other member with a Starter Module directly sponsored by them in each leg."), ___EmotionJSX("p", null, "Bonus varies between 10% and 25% of the Network Bonus and depends on the Modules the Member has purchased and the size of his/her network (see the table below for details).")), ___EmotionJSX("div", {
    className: "bonus-help-modal-example-box matching-help-modal-image-container"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: matchingBonusRequirementsImg,
    className: "bonus-help-modal-image"
  }))), ___EmotionJSX("div", {
    className: "bonus-help-modal-chapter"
  }, ___EmotionJSX("h2", null, "Generations"), ___EmotionJSX("div", {
    className: "bonus-help-modal-description"
  }, ___EmotionJSX("p", null, "Generations are based on the concept of direct/personal sales of the IMA and his/her downline."), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "1", ___EmotionJSX("sup", null, "st"), " Generation"), " are considere to be all personally sponsored members in the downline of the IMA."), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "2", ___EmotionJSX("sup", null, "nd"), " Generation"), " would be all personally sponsored by 1", ___EmotionJSX("sup", null, "st"), " Gen. members."), ___EmotionJSX("p", null, "Therefore, ", ___EmotionJSX("b", null, "3", ___EmotionJSX("sup", null, "rd"), " Generation"), " are all members personally sponsored by the 2", ___EmotionJSX("sup", null, "nd"), " Gen. The same logic applies for the ", ___EmotionJSX("b", null, "4", ___EmotionJSX("sup", null, "th"), " Generation"), ".")), ___EmotionJSX("div", {
    className: "matching-help-modal-image-container"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: matchingBonusGenerationsImg,
    className: "bonus-help-modal-image"
  }))), ___EmotionJSX("div", {
    className: "bonus-help-modal-chapter"
  }, ___EmotionJSX("h2", null, "Examples"), ___EmotionJSX("div", {
    className: "matching-help-modal-image-container"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: matchingBonusStarterImg,
    className: "bonus-help-modal-image"
  })), ___EmotionJSX("br", null), ___EmotionJSX("div", {
    className: "matching-help-modal-image-container"
  }, ___EmotionJSX("img", {
    alt: "bonus_example",
    src: matchingBonusTraderImg,
    className: "bonus-help-modal-image"
  }))));
};
export default MatchingBonusInfo;