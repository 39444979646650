import React from 'react';
import { accountRank, accountRankInformation } from '../../../enums/account';
import { jsx as ___EmotionJSX } from "@emotion/react";
var RecognitionRankInformation = function RecognitionRankInformation(props) {
  var _accountRankInformati;
  var currentAccount = props.currentAccount;
  var leaderRank = currentAccount.rank;
  return ___EmotionJSX("div", {
    className: "recognition-wrapper"
  }, ___EmotionJSX("div", {
    className: "recognition-current-rank "
  }, leaderRank !== accountRank.Rookie && ___EmotionJSX("img", {
    src: (_accountRankInformati = accountRankInformation[leaderRank]) === null || _accountRankInformati === void 0 ? void 0 : _accountRankInformati.rankImage,
    alt: "package",
    className: "recognition-current-package-wrap"
  }), ___EmotionJSX("div", null, ___EmotionJSX("h2", {
    className: "recognition-current-package"
  }, "Leadership rank"), ___EmotionJSX("h2", {
    className: "recognition-name-package"
  }, leaderRank === accountRank.Rookie ? 'None' : leaderRank))));
};
export default RecognitionRankInformation;