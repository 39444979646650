import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.to-fixed.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { shoppingActions } from '../../../store';
import Button from '../../../components/UI/Button/Button';
import BackButton from '../../../components/UI/Button/BackButton';
import CartItem from '../components/CartItem';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ShoppingCard = function ShoppingCard(props) {
  var shoppingCart = props.shoppingCart,
    removeFromShoppingCart = props.removeFromShoppingCart,
    editItemInShoppingCart = props.editItemInShoppingCart;
  if (!shoppingCart.items.length) return ___EmotionJSX(Redirect, {
    to: "/shop"
  });
  return ___EmotionJSX("div", {
    className: "vk-shopping-card-page"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 2
  }, ___EmotionJSX(BackButton, null)), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 8
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Shopping Cart (", shoppingCart.items.length, ")"), ___EmotionJSX("div", {
    className: "vk-shopping-card-items-wrap"
  }, shoppingCart.items.map(function (el, i) {
    return ___EmotionJSX(CartItem, {
      item: el,
      key: "".concat(el.catalogueItemId, "-").concat(i),
      onDelete: removeFromShoppingCart,
      onEdit: editItemInShoppingCart
    });
  })), ___EmotionJSX("div", {
    className: "vk-shopping-card-footer"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center",
    justify: "flex-end"
  }, ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-footer-total"
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-footer-total-label"
  }, "Cart Total:"), ___EmotionJSX("div", {
    className: "vk-shopping-card-footer-total-amount"
  }, "\u20AC\xA0", shoppingCart.total.toFixed(2)))), ___EmotionJSX(Grid, {
    item: true,
    sm: "auto"
  }, ___EmotionJSX(Link, {
    to: "/shop/payment-methods"
  }, ___EmotionJSX(Button, {
    btnType: "vk-primary-btn",
    btnSize: "vk-btn-size-l"
  }, "Check out now"))))))));
};
ShoppingCard.propTypes = {
  shoppingCart: PropTypes.object,
  editItemInShoppingCart: PropTypes.func,
  removeFromShoppingCart: PropTypes.func
};
export default connect(function (_ref) {
  var shoppingCart = _ref.shoppingCart;
  return {
    shoppingCart: shoppingCart
  };
}, shoppingActions)(ShoppingCard);