import React from 'react';
import moment from 'moment';
import { defaultDate } from '../../../utils/moment';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var dateTime = function dateTime(key) {
  return function (props) {
    var NAItem = props.NAItem;
    if (props[key] === defaultDate) return NAItem;
    var transactionDate = moment.utc(props[key]);
    var dateNow = moment.utc();
    var difference = moment.duration(transactionDate.diff(dateNow));
    return ___EmotionJSX("div", {
      className: "vk-crypto-date-time-col-format"
    }, ___EmotionJSX("span", null, "".concat(transactionDate.local().format('DD/MM/YYYY, HH:mm:ss'))), ___EmotionJSX("span", {
      className: "vk-crypto-date-time-min-ago"
    }, difference.humanize(true, {
      d: 7,
      w: 4
    })));
  };
};