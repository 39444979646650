import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _withdrawalStatusMap;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { dateTime } from '../TableDataComponents';
import CancelWithdrawalModal from './CancelWithdrawalModal';
import { Table } from '../../../../components/UI/Table';
import ColorBadge from '../../../../components/UI/Shared/ColorBadge';
import Button from '../../../../components/UI/Button/Button';
import TableFooterBar from '../../../../components/UI/Shared/TableFooterBar';
import { walletType, withdrawalStatus } from '../../../../enums/wallets';
import { paymentMethodTypes } from '../../../../enums/paymentMethods';
import currencyByType from '../../../../utils/currencyByType';
import { getWithdrawalHistory } from '../../../../services/Wallets';
import { accountActions } from '../../../../store';
import { jsx as ___EmotionJSX } from "@emotion/react";
var withdrawalStatusMap = (_withdrawalStatusMap = {}, _defineProperty(_withdrawalStatusMap, withdrawalStatus.Pending, {
  text: 'Pending',
  color: 'blue'
}), _defineProperty(_withdrawalStatusMap, withdrawalStatus.Approved, {
  text: 'Approved',
  color: 'green'
}), _defineProperty(_withdrawalStatusMap, withdrawalStatus.Rejected, {
  text: 'Rejected',
  color: 'red'
}), _defineProperty(_withdrawalStatusMap, withdrawalStatus.Cancelled, {
  text: 'Cancelled',
  color: 'orange'
}), _withdrawalStatusMap);
var WithdrawalsHistory = function WithdrawalsHistory(props) {
  var cashWallet = props.cashWallet,
    refreshCurrentWallets = props.refreshCurrentWallets,
    currentAccountId = props.currentAccountId;
  var cancelModalRef = useRef();
  var tableRef = useRef();
  var columnsDefinition = [{
    name: 'ID',
    value: 'id',
    width: '60px'
  }, {
    name: 'Created Date',
    render: dateTime('createdOn')
  }, {
    name: 'Confirmation Date',
    render: dateTime('executedOnDate')
  }, {
    name: 'Method Type',
    render: function render(row) {
      return row.type === paymentMethodTypes.Bitcoin ? 'Bitcoin' : 'USDT';
    }
  }, {
    name: 'Address',
    render: function render(_ref) {
      var _JSON$parse;
      var value = _ref.value;
      return (_JSON$parse = JSON.parse(value)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.addressNumber;
    }
  }, {
    name: 'Amount and Fee',
    render: function render(row) {
      return ___EmotionJSX("div", null, ___EmotionJSX("p", null, "Amount: ", ___EmotionJSX("b", null, " ", currencyByType(row.amount, walletType.CASH))), ___EmotionJSX("p", null, "Fee: ", ___EmotionJSX("b", null, " ", currencyByType(row.fee, walletType.CASH))));
    }
  }, {
    name: 'Status',
    render: function render(row) {
      var _withdrawalStatusMap$, _withdrawalStatusMap$2;
      return ___EmotionJSX(ColorBadge, {
        type: (_withdrawalStatusMap$ = withdrawalStatusMap[row.status]) === null || _withdrawalStatusMap$ === void 0 ? void 0 : _withdrawalStatusMap$.color
      }, (_withdrawalStatusMap$2 = withdrawalStatusMap[row.status]) === null || _withdrawalStatusMap$2 === void 0 ? void 0 : _withdrawalStatusMap$2.text);
    },
    width: '120px'
  }, {
    name: 'Comment',
    value: 'comment'
  }, {
    name: 'Cancel Withdrawal',
    render: function render(_ref2) {
      var id = _ref2.id,
        status = _ref2.status,
        NAItem = _ref2.NAItem;
      return status === withdrawalStatus.Pending ? ___EmotionJSX(Button, {
        btnType: "vk-primary-btn",
        btnSize: "vk-btn-size-m",
        clicked: function clicked() {
          cancelModalRef.current.showModal(id);
        }
      }, "Cancel") : NAItem;
    },
    width: '110px'
  }];
  return ___EmotionJSX("div", {
    className: "vk-wallets-balance-wraper"
  }, !!(cashWallet !== null && cashWallet !== void 0 && cashWallet.id) && ___EmotionJSX(Grid, {
    container: true,
    spacing: 5
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 12
  }, ___EmotionJSX("div", {
    className: "vk-paper-box"
  }, ___EmotionJSX(Table, {
    ref: tableRef,
    getDataKey: "content",
    getDataMethod: getWithdrawalHistory,
    columns: columnsDefinition,
    pageSize: 10,
    hasPagination: true,
    footerBar: TableFooterBar(),
    emptyMessage: "No Withdrawal History to Show",
    updateOn: currentAccountId
  })), ___EmotionJSX(CancelWithdrawalModal, {
    ref: cancelModalRef,
    onSubmit: function onSubmit() {
      var _tableRef$current;
      (_tableRef$current = tableRef.current) === null || _tableRef$current === void 0 ? void 0 : _tableRef$current.getData();
      refreshCurrentWallets();
    }
  }))));
};
var mapProps = function mapProps(_ref3) {
  var _accounts$current, _accounts$current2;
  var accounts = _ref3.accounts;
  return {
    cashWallet: (accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.wallets.find(function (el) {
      return el.walletType === walletType.CASH;
    })) || {},
    currentAccountId: accounts === null || accounts === void 0 ? void 0 : (_accounts$current2 = accounts.current) === null || _accounts$current2 === void 0 ? void 0 : _accounts$current2.id
  };
};
export default connect(mapProps, accountActions)(WithdrawalsHistory);