import "core-js/modules/es.array.concat.js";
import { walletType, currencyType } from '../enums/wallets';
var COIN = walletType.COIN,
  COINLEGACY = walletType.COINLEGACY,
  OESP = walletType.OESP,
  CASHSAFE = walletType.CASHSAFE,
  CASH = walletType.CASH,
  TRADING = walletType.TRADING,
  TOKEN = walletType.TOKEN,
  BV = walletType.BV,
  GAS = walletType.GAS;
var EURO = currencyType.EURO,
  EURO_LETTERS = currencyType.EURO_LETTERS,
  TKN = currencyType.TKN,
  COINCURRENCY = currencyType.COIN,
  COINLEGACYCURRENCY = currencyType.COINLEGACY,
  BVP = currencyType.BVP,
  GASCURRENCY = currencyType.GAS,
  OESPCURRENCY = currencyType.OESP;
var mapCurrency = function mapCurrency(value, currency, letter) {
  var amount = value || 0;
  switch (true) {
    case currency === COIN:
      // REMOVE TAG HIDE ALL COINSAFE FUNCTIONALITY
      // || currency === COINSAFE
      return "".concat(amount, " ").concat(COINCURRENCY);
    case currency === COINLEGACY:
      return "".concat(amount, " ").concat(COINLEGACYCURRENCY);
    case currency === CASH || currency === TRADING || currency === CASHSAFE:
      return letter ? "".concat(amount, " ").concat(EURO_LETTERS) : "".concat(EURO, " ").concat(amount);
    case currency === TOKEN:
      return "".concat(amount, " ").concat(TKN);
    case currency === GAS:
      return "".concat(amount, " ").concat(GASCURRENCY);
    case currency === BV:
      return "".concat(amount, " ").concat(BVP);
    case currency === OESP:
      return "".concat(amount, " ").concat(OESPCURRENCY);
    default:
      return amount;
  }
};
export default mapCurrency;