import _isNil from "lodash/isNil";
import React from 'react';
import NetworkTablePackageCol from '../../components/NetworkTablePackageCol';
import NetworkTableStatusCol from '../../components/NetworkTableStatusCol';
import NetworkTableCountryCol from '../../components/NetworkTableCountryCol';
import CommunityTableUsernameCol from '../../components/CommunityTableUsernameCol';
import CommunityTableLeadershipCol from '../../components/CommunityTableLeadershipCol';
import CommunityTableSponsorCol from '../../components/CommunityTableSponsorCol';
import CommunityTableEagleCol from '../../components/CommunityTableEagleCol';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var columnsDefinition = function columnsDefinition(packageColorsMap) {
  return [{
    name: 'Username',
    width: '15%',
    render: function render(row) {
      return ___EmotionJSX(CommunityTableUsernameCol, {
        data: row,
        packageColorsMap: packageColorsMap
      });
    }
  }, {
    name: 'Country',
    width: '13%',
    render: NetworkTableCountryCol
  }, {
    name: 'Status',
    width: '100px',
    render: NetworkTableStatusCol
  }, {
    name: 'Package',
    width: '13%',
    render: function render(_ref) {
      var planName = _ref.planName,
        currentPackagePictureUrl = _ref.currentPackagePictureUrl,
        currentPackagePicThumbnailUrl = _ref.currentPackagePicThumbnailUrl,
        packageAvatar = _ref.packageAvatar,
        NAItem = _ref.NAItem;
      return _isNil(planName) ? NAItem : ___EmotionJSX(NetworkTablePackageCol, {
        planName: planName,
        currentPackagePictureUrl: currentPackagePictureUrl,
        currentPackagePicThumbnailUrl: currentPackagePicThumbnailUrl,
        packageAvatar: packageAvatar
      });
    }
  }, {
    name: 'Leadership Rank',
    width: '13%',
    render: function render(_ref2) {
      var rank = _ref2.rank,
        NAItem = _ref2.NAItem;
      return rank === _isNil(rank) ? NAItem : ___EmotionJSX(CommunityTableLeadershipCol, {
        rank: rank
      });
    }
  }, {
    name: 'Eagle Rank',
    width: '13%',
    render: function render(_ref3) {
      var eagleRankId = _ref3.eagleRankId,
        eagleRankStars = _ref3.eagleRankStars,
        NAItem = _ref3.NAItem;
      return _isNil(eagleRankId) && eagleRankStars === 0 ? NAItem : ___EmotionJSX(CommunityTableEagleCol, {
        eagleRankId: eagleRankId,
        eagleRankStars: eagleRankStars
      });
    }
  }, {
    name: 'Network Level',
    width: '80px',
    value: 'networkLevel'
  }, {
    name: 'Matching Level',
    value: 'matchingLevel',
    width: '85px'
  }, {
    name: 'Sponsor',
    width: '90px',
    render: CommunityTableSponsorCol
  }];
};