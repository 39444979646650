import React from 'react';
import directSalesBonusExample from '../../../assets/images/bonuses/direct-sales-bonus-example.png';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DirectSalesBonusInfo = function DirectSalesBonusInfo() {
  return ___EmotionJSX("div", null, ___EmotionJSX("h1", {
    className: "bonus-help-modal-title"
  }, "DIRECT SALES BONUS"), ___EmotionJSX("div", {
    className: "bonus-help-modal-chapter"
  }, ___EmotionJSX("h2", null, "What is Direct Sales?"), ___EmotionJSX("div", {
    className: "bonus-help-modal-description"
  }, ___EmotionJSX("p", null, "Direct Sales Bonus is a bonus where an IMA receives 10% of all Business Volume accumulated in his account by accounts registered using his invitation link. This bonus is based on the revenue reached only by personal sales.")), ___EmotionJSX("img", {
    alt: "bonus_example",
    src: directSalesBonusExample,
    className: "bonus-help-modal-image"
  }), ___EmotionJSX("div", {
    className: "direct-startup-help-modal-example-container"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-example-box"
  }, ___EmotionJSX("span", null, "Example:"), ___EmotionJSX("h4", null, "1000 BV DS x 10% = 100 EUR")), ___EmotionJSX("div", {
    className: "bonus-help-modal-bottom-box"
  }, ___EmotionJSX("div", {
    className: "bonus-help-modal-bottom-box-text"
  }, ___EmotionJSX("span", null, "DS")), ___EmotionJSX("div", {
    className: "bonus-help-modal-bottom-box-description"
  }, ___EmotionJSX("p", null, "- Directly Sponsored"), ___EmotionJSX("p", null, "- Direct/Personal Sales"))))));
};
export default DirectSalesBonusInfo;