import React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PageSpinner = function PageSpinner() {
  return ___EmotionJSX("div", {
    className: "vk-page-spinner"
  }, ___EmotionJSX("svg", {
    className: "spinner",
    viewBox: "0 0 50 50"
  }, ___EmotionJSX("circle", {
    className: "path",
    cx: "25",
    cy: "25",
    r: "20",
    fill: "none",
    strokeWidth: "5"
  })));
};
export default PageSpinner;