import _isNil from "lodash/isNil";
import "core-js/modules/es.function.name.js";
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ValuePercent from '../../../components/UI/Shared/ValuePercent';
import { Skeleton } from '../../../components/UI/Loader';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DashboardHeadingSection = function DashboardHeadingSection(props) {
  var _bonuses$current, _bonuses$current2;
  var user = props.user,
    currentPackage = props.packages.current,
    bonuses = props.bonuses;
  var utcStrFirstPart = moment().utc().format('ddd  |  MMMM Do');
  var utcStrSecondPart = moment().utc().format('hh:mm:ss');
  return ___EmotionJSX("div", {
    className: "vk-dashboard-heading-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-container"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center",
    justify: "space-between"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 6
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-heading-avatar-text-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-dashboard-heading-avatar-wrap"
  }, ___EmotionJSX("img", {
    alt: "Pic",
    src: user.picThumbnailUrl
  })), ___EmotionJSX("div", {
    className: "vk-dashboard-heading-text-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-page-heading"
  }, "Welcome back, ", user.firstName, "."), ___EmotionJSX("p", {
    className: "vk-dashboard-heading-text"
  }, "Your current module is ", currentPackage.name, " \xA0", ___EmotionJSX(Link, {
    to: "/shop",
    className: "vk-link vk-link-sm"
  }, "Upgrade"))))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 2
  }, ___EmotionJSX("div", null, ___EmotionJSX("h3", {
    className: "vk-dashboard-utc-time-first-part"
  }, utcStrFirstPart), ___EmotionJSX("p", {
    className: "vk-dashboard-utc-time-second-part"
  }, utcStrSecondPart))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, !(bonuses !== null && bonuses !== void 0 && bonuses.isEmpty) && ___EmotionJSX("div", {
    className: "vk-dashboard-heading-amounts-wrap"
  }, ___EmotionJSX("h3", {
    className: "vk-section-heading-light"
  }, "Bonuses Earned This Week"), !_isNil(bonuses === null || bonuses === void 0 ? void 0 : (_bonuses$current = bonuses.current) === null || _bonuses$current === void 0 ? void 0 : _bonuses$current.total) ? ___EmotionJSX(ValuePercent, {
    value: bonuses === null || bonuses === void 0 ? void 0 : (_bonuses$current2 = bonuses.current) === null || _bonuses$current2 === void 0 ? void 0 : _bonuses$current2.total
  }) : ___EmotionJSX(Skeleton, {
    height: 30,
    width: 90
  }))))));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var user = _ref.user,
    packages = _ref.packages,
    bonuses = _ref.bonuses;
  return {
    user: user,
    packages: packages,
    bonuses: bonuses
  };
};
export default connect(mapStateToProps)(DashboardHeadingSection);