import React from 'react';
import { GiftCodeWithStatus, redeemedByNameAccountId } from './TableDataComponents';
import ColorBadge from '../../../components/UI/Shared/ColorBadge';
import { compareWithNow, displayDate } from '../../../utils/moment';
import currencyByType from '../../../utils/currencyByType';
import { walletType } from '../../../enums/wallets';
import { giftCodeStatuses } from '../../../enums/orders';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var columnsDefinition = [{
  name: 'Module Name',
  value: 'moduleName'
}, {
  name: 'Payment Type',
  value: 'paymentType'
}, {
  name: 'Redeemed On',
  render: function render(row) {
    var _displayDate;
    return ___EmotionJSX("h6", {
      className: "date-of-redeemed"
    }, (_displayDate = displayDate(row.redeemedOn, 'DD/MM/YYYY, HH:mm:ss')) !== null && _displayDate !== void 0 ? _displayDate : ___EmotionJSX("div", {
      className: "table-data__cell__no-value"
    }));
  },
  width: '95px'
}, {
  name: 'Redeemed By',
  render: redeemedByNameAccountId
}, {
  name: 'Academy Tokens',
  render: function render(row) {
    return ___EmotionJSX("div", {
      className: "academy-tokens-container"
    }, row.academyTokensRewardedOn && ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Rewarded On: "), displayDate(row.academyTokensRewardedOn, 'DD/MM/YYYY, HH:mm:ss')), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Rewarded Tokens: "), currencyByType(row.academyTokensReward, walletType.TOKEN)));
  }
}, {
  name: 'Activation fee',
  render: function render(row) {
    return ___EmotionJSX(ColorBadge, {
      type: row !== null && row !== void 0 && row.activationFee ? 'green' : 'red'
    }, row !== null && row !== void 0 && row.activationFee ? 'Yes' : 'No');
  },
  width: '90px'
}, {
  name: 'Gift Code',
  render: GiftCodeWithStatus,
  width: '130px'
}, {
  name: 'Expire Date',
  render: function render(row) {
    var _displayDate2;
    var hasExpired = compareWithNow(row.expireDate) < 0;
    return ___EmotionJSX("h6", {
      className: "date-of-redeemed ".concat(hasExpired ? 'expired' : '')
    }, (_displayDate2 = displayDate(row.expireDate, 'DD/MM/YYYY, HH:mm:ss')) !== null && _displayDate2 !== void 0 ? _displayDate2 : ___EmotionJSX("div", {
      className: "table-data__cell__no-value"
    }));
  },
  width: '100px'
}];
export var optionsGiftCodeStatus = [{
  label: 'Gift Code Status',
  value: null
}, {
  label: 'Not Redeemed',
  value: giftCodeStatuses.NotRedeemed
}, {
  label: 'Redeemed',
  value: giftCodeStatuses.Redeemed
}, {
  label: 'Not Paid',
  value: giftCodeStatuses.Unpaid
}];
export var activationFeePackages = [{
  value: null,
  label: 'Activation Fee'
}, {
  value: true,
  label: 'With Activation'
}, {
  value: false,
  label: 'Without Activation'
}];