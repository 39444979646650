function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcn } from '../../../assets/images/layout-svg-icons/icn-sm-checkmark-tick.svg';
import { ReactComponent as QuestionIcn } from '../../../assets/images/layout-svg-icons/icn-question-gray.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CustomCheckBox = function CustomCheckBox(props) {
  var label = props.label,
    name = props.name,
    customStyle = props.customStyle,
    onCheckboxChange = props.onCheckboxChange,
    checked = props.checked,
    checkContol = props.checkContol,
    checkContolValue = props.checkContolValue,
    checkForm = props.checkForm,
    helpText = props.helpText;
  var _useState = useState(checked),
    _useState2 = _slicedToArray(_useState, 2),
    checkedState = _useState2[0],
    setCheckedState = _useState2[1];
  var checkbox = useRef();
  var handleCheckboxChange = function handleCheckboxChange(event) {
    if (checkContol) onCheckboxChange(checkContolValue);else if (checkForm) {
      setCheckedState(event.target.checked);
      onCheckboxChange(event);
    } else {
      setCheckedState(event.target.checked);
      onCheckboxChange(event.target.checked);
    }
  };
  useEffect(function () {
    if (checkContol) checkbox.current.checked = checkContolValue;else checkbox.current.checked = checkedState;
  });
  return ___EmotionJSX("div", {
    className: ['vk-custom-check-box-wrap', customStyle].join(' ')
  }, ___EmotionJSX("div", {
    className: "vk-custom-check-box-inner",
    role: "checkbox",
    "aria-checked": "false",
    tabIndex: "0"
  }, ___EmotionJSX("input", {
    ref: checkbox,
    type: "checkbox",
    id: name,
    name: name,
    defaultChecked: checkContol ? checkContolValue : checkedState,
    onChange: handleCheckboxChange
  }), ___EmotionJSX("div", {
    tabIndex: "0",
    role: "button",
    className: "vk-custom-check-box-svg-wrap ".concat((checkContol ? checkContolValue : checkedState) ? 'checked' : ''),
    onClick: function onClick() {
      checkbox.current.click();
    }
  }, ___EmotionJSX(SuccessIcn, null)), ___EmotionJSX("label", {
    htmlFor: name
  }, " ", label), helpText && ___EmotionJSX("div", {
    className: "vk-custom-check-box-svg-question-wrap"
  }, ___EmotionJSX(QuestionIcn, null))));
};
CustomCheckBox.defaultProps = {
  checked: false
};
CustomCheckBox.propTypes = {
  label: PropTypes.string,
  customStyle: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  checkContol: PropTypes.bool,
  checkContolValue: PropTypes.bool,
  checkForm: PropTypes.bool,
  helpText: PropTypes.string,
  onCheckboxChange: PropTypes.func
};
export default CustomCheckBox;