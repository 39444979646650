import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Zoom } from '@material-ui/core';
import { ReactComponent as TrashIcn } from '../../../assets/images/layout-svg-icons/icn-remove-trash.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var KycModalUploadFiles = function KycModalUploadFiles(_ref) {
  var file = _ref.file,
    onRemove = _ref.onRemove,
    _onChange = _ref.onChange;
  return ___EmotionJSX("div", {
    className: "vk-kyc-upload-docs-modal-upload-box"
  }, ___EmotionJSX("div", {
    className: "vk-kyc-upload-docs-modal-upload-list"
  }, !!file && ___EmotionJSX(Zoom, {
    "in": true
  }, ___EmotionJSX("div", {
    className: "vk-kyc-upload-docs-modal-upload-item"
  }, ___EmotionJSX("span", null, file.name), ___EmotionJSX("i", {
    onClick: onRemove,
    role: "button",
    tabIndex: "0"
  }, ___EmotionJSX(TrashIcn, null))))), ___EmotionJSX("input", {
    id: "docs-upload",
    className: "vk-upload-pictures-input",
    value: "",
    type: "file",
    name: "file",
    accept: "image/*",
    onChange: function onChange(_ref2) {
      var target = _ref2.target;
      return _onChange(target.files);
    }
  }), !file && ___EmotionJSX("label", {
    htmlFor: "docs-upload",
    className: "vk-kyc-upload-docs-modal-browse-btn"
  }, "Document Image"));
};
KycModalUploadFiles.propTypes = {
  values: PropTypes.object,
  file: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func
};
export default KycModalUploadFiles;