import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _vendorsInformation;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import myLifeLogo from '../../assets/images/logos/mylife-logo.png';
import oneForexLogo from '../../assets/images/logos/one-forex-logo.png';
import oneVoyageLogo from '../../assets/images/logos/one-voyage-logo.png';
import basicLogo from '../../assets/images/logos/OE_VOYAGE_BASIC.png';
import unlimitedLogo from '../../assets/images/logos/OE_VOYAGE_GOLDEN UNLIMITED.jpg';
import premiumLogo from '../../assets/images/logos/OE_VOYAGE_PREMIUM.jpg';
export var vendorNames = {
  MyLife: 'OneApp',
  OneForex: 'OneForex',
  OneVoyage: 'OneVoyage'
};
export var vendorBenefits = {
  BEST_PRICE: '110% Best Price Guarantee',
  HOTEL_POINTS: 'ONE Voyage Hotel points',
  AIRPORT_LOUNGE_ACCESS: 'Airport Lounge access',
  GROUP_RESERVATIONS: 'Concierge for groups reservations',
  TRIP_COINS: 'Trip coins'
};
export var VOYAGE_BENEFIT_TYPES = {
  6: {
    AIRPORT_LOUNGE_ACCESS: '4 times free access'
  },
  12: {
    AIRPORT_LOUNGE_ACCESS: 'Unlimited Access'
  }
};
export var vendorsInformation = (_vendorsInformation = {}, _defineProperty(_vendorsInformation, vendorNames.MyLife, {
  name: vendorNames.MyLife,
  color: '#AB8735',
  title: 'Get started with our app!',
  description: 'My Life app subscription plans',
  logo: myLifeLogo
}), _defineProperty(_vendorsInformation, vendorNames.OneForex, {
  name: vendorNames.OneForex,
  color: '#1A1E21',
  title: 'One Forex subscription plans',
  description: 'One Forex app subscription plans',
  logo: oneForexLogo
}), _defineProperty(_vendorsInformation, vendorNames.OneVoyage, {
  name: vendorNames.OneVoyage,
  color: '#60D9AA',
  title: 'One Voyage subscription plans',
  description: 'One Voyage app subscription plans',
  logo: oneVoyageLogo,
  plansThumbs: [basicLogo, premiumLogo, unlimitedLogo]
}), _vendorsInformation);
export var getVoyageSubscriptionsBenefits = function getVoyageSubscriptionsBenefits(subscriptionName) {
  if (subscriptionName.toLowerCase().includes('basic')) {
    return ['BEST_PRICE', 'HOTEL_POINTS'];
  }
  if (subscriptionName.toLowerCase().includes('premium')) {
    return ['BEST_PRICE', 'HOTEL_POINTS', 'AIRPORT_LOUNGE_ACCESS', 'GROUP_RESERVATIONS', 'TRIP_COINS'];
  }
  if (subscriptionName.toLowerCase().includes('unlimited')) {
    return ['BEST_PRICE', 'HOTEL_POINTS', 'AIRPORT_LOUNGE_ACCESS', 'GROUP_RESERVATIONS', 'TRIP_COINS'];
  }
  return [];
};