import { amount } from './TableDataComponents';
export var columnsDefinition = [{
  name: 'Date',
  value: 'paidOn',
  width: '100px',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Deposit ID',
  value: 'id'
}, {
  name: 'Initial Amount',
  render: amount('initialAmount')
}, {
  name: 'Accumulated Amount',
  render: amount('accruedAmount')
}];