export var optionsStatus = [{
  value: 'new',
  label: 'New'
}, {
  value: 'pending',
  label: 'Pending'
}, {
  value: 'active',
  label: 'Active'
}, {
  value: 'frozen',
  label: 'Frozen'
}, {
  value: 'terminated',
  label: 'Terminated'
}, {
  value: 'inactive',
  label: 'Inactive'
}];
export var optionsRank = [{
  value: 0,
  label: 'Rookie'
}, {
  value: 1,
  label: 'Sapphire'
}, {
  value: 2,
  label: 'Ruby'
}, {
  value: 3,
  label: 'Emerald'
}, {
  value: 4,
  label: 'Diamond'
}, {
  value: 5,
  label: 'Blue Diamond'
}, {
  value: 6,
  label: 'Black Diamond'
}, {
  value: 7,
  label: 'Crown Diamond'
}];
export var matchingOptions = [{
  value: 1,
  label: 'First Level'
}, {
  value: 2,
  label: 'Second Level'
}, {
  value: 3,
  label: 'Third Level'
}, {
  value: 4,
  label: 'Fourth Level'
}];
export var eagleRankOptions = [{
  value: 1,
  label: 'Eagle'
}, {
  value: 2,
  label: 'Bronze Eagle'
}, {
  value: 3,
  label: 'Silver Eagle'
}, {
  value: 4,
  label: 'Gold Eagle'
}, {
  value: 5,
  label: 'Platinum Eagle'
}, {
  value: 6,
  label: 'Lifetime Eagle'
}];
export var eagleRankStars = [{
  value: 1,
  label: 'One Star'
}, {
  value: 2,
  label: 'Two Stars'
}, {
  value: 3,
  label: 'Three Stars'
}, {
  value: 4,
  label: 'Four Stars'
}, {
  value: 5,
  label: 'Five Stars'
}, {
  value: 6,
  label: 'Six Stars'
}, {
  value: 7,
  label: 'Seven Stars'
}, {
  value: 8,
  label: 'Eight Stars'
}, {
  value: 9,
  label: 'Nine Stars'
}, {
  value: 10,
  label: 'Ten Stars'
}, {
  value: 11,
  label: 'Eleven Stars'
}, {
  value: 12,
  label: 'Twelve Stars'
}];