import React from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import EagleNextRankInfo from '../components/EagleNextRankInfo';
import EagleRankInformation from '../components/EagleRankInformation';
import EagleTable from '../components/EagleTable';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Eagle = function Eagle(_ref) {
  var user = _ref.user;
  return ___EmotionJSX("div", {
    className: "vk-container vk-pt-32"
  }, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    spacing: 4
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 12
  }, ___EmotionJSX("div", {
    className: "vk-pb-40"
  }, ___EmotionJSX("h1", {
    className: "vk-tab-heading"
  }, "Eagle Rank"), ___EmotionJSX("hr", null))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 5
  }, ___EmotionJSX(EagleRankInformation, {
    profileInfo: user
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    lg: 7
  }, ___EmotionJSX(EagleNextRankInfo, {
    profileInfo: user
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 12
  }, ___EmotionJSX(EagleTable, null))));
};
var mapStateProps = function mapStateProps(_ref2) {
  var user = _ref2.user;
  return {
    user: user
  };
};
export default connect(mapStateProps)(Eagle);