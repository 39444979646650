import NetworkTableNameCol from '../../components/NetworkTableNameCol';
import NetworkTablePackageCol from '../../components/NetworkTablePackageCol';
import NetworkTableStatusCol from '../../components/NetworkTableStatusCol';
import NetworkTableCountryCol from '../../components/NetworkTableCountryCol';
import NetworkTableActionCol from '../../components/NetworkTableActionCol';
export var columns = [{
  value: 'name',
  render: NetworkTableNameCol
}, {
  value: 'package',
  width: '15%',
  render: NetworkTablePackageCol
}, {
  value: 'status',
  width: '100px',
  render: NetworkTableStatusCol
}, {
  value: 'country',
  width: '15%',
  render: NetworkTableCountryCol
}, {
  name: 'Network',
  width: '100px',
  value: 'depth'
}, {
  name: 'Matching',
  value: 'level',
  width: '100px',
  sortable: true
}, {
  value: 'action',
  width: '40px',
  render: NetworkTableActionCol
}];