import "core-js/modules/es.array.map.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as EmptyIcon } from '../../../../../assets/images/layout-svg-icons/icn-empty.svg';
import { Skeleton } from '../../../../../components/UI/Loader';
import { ShowDate } from './components/ShowDate';
import { CharityAmount } from './components/CharityAmount';
import { walletTransactionsLink, walletType } from '../../../../../enums/wallets';
import './styles.scss';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DashboardCharityTransactions = function DashboardCharityTransactions(_ref) {
  var _charityWallet$0$tran;
  var charityWallet = _ref.charityWallet;
  if (!charityWallet) return ___EmotionJSX(Skeleton, {
    count: 3
  });
  if (!charityWallet.length) return ___EmotionJSX("p", null, "There are no results for your region yet!");
  return ___EmotionJSX("div", {
    className: "dashboard-charity-container"
  }, ___EmotionJSX(Grid, {
    container: true,
    item: true,
    alignItems: "stretch",
    className: "dashboard-charity-results-header-row"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 2
  }, ___EmotionJSX("p", null, "Date")), ___EmotionJSX(Grid, {
    item: true,
    xs: 4
  }, ___EmotionJSX("p", null, "Name")), ___EmotionJSX(Grid, {
    item: true,
    xs: 4
  }, ___EmotionJSX("p", null, "Nickname")), ___EmotionJSX(Grid, {
    item: true,
    xs: 2
  }, ___EmotionJSX("p", null, "Amount"))), ___EmotionJSX(Grid, {
    container: true,
    className: "dashboard-charity-results"
  }, (_charityWallet$0$tran = charityWallet[0].transactions) !== null && _charityWallet$0$tran !== void 0 && _charityWallet$0$tran.length ? charityWallet[0].transactions.map(function (transaction, index) {
    var _transaction$sender, _transaction$sender2;
    return ___EmotionJSX(Grid, {
      container: true,
      item: true,
      alignItems: "center",
      className: "dashboard-charity-results-row",
      key: "leader-".concat(index)
    }, ___EmotionJSX(Grid, {
      item: true,
      xs: 2
    }, ___EmotionJSX("div", null, ___EmotionJSX(ShowDate, {
      date: transaction === null || transaction === void 0 ? void 0 : transaction.createdOn
    }))), ___EmotionJSX(Grid, {
      item: true,
      xs: 4
    }, ___EmotionJSX("div", null, (_transaction$sender = transaction.sender) === null || _transaction$sender === void 0 ? void 0 : _transaction$sender.profileName)), ___EmotionJSX(Grid, {
      item: true,
      xs: 4
    }, ___EmotionJSX("div", null, (_transaction$sender2 = transaction.sender) === null || _transaction$sender2 === void 0 ? void 0 : _transaction$sender2.accountNickName)), ___EmotionJSX(Grid, {
      item: true,
      xs: 2
    }, ___EmotionJSX(CharityAmount, {
      data: transaction
    })));
  }) : ___EmotionJSX("div", {
    className: "no-latest-trans-box"
  }, ___EmotionJSX(EmptyIcon, {
    className: "no-latest-trans-icon"
  }), ___EmotionJSX("p", null, "No transactions in this wallet.")), ___EmotionJSX(Link, {
    className: "secondary-btn btn-size-s",
    to: walletTransactionsLink[walletType.CHARITY]
  }, "View Full History")));
};