import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ViewDocument = function ViewDocument(props) {
  var row = props.row;
  return ___EmotionJSX("a", {
    target: "_blank",
    rel: "noopener noreferrer",
    href: row.viewUrl,
    className: "vk-ima-tools-container"
  }, ___EmotionJSX("span", {
    className: "vk-ima-tools-title-view"
  }, "View"), ___EmotionJSX("i", {
    className: "far fa-eye iconHelpContainer"
  }));
};
ViewDocument.propTypes = {
  row: PropTypes.object
};
export default ViewDocument;