import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Footer = function Footer() {
  var today = new Date().getFullYear();
  return ___EmotionJSX("div", {
    id: "kt_footer",
    className: "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
  }, ___EmotionJSX("div", {
    className: "kt-container"
  }, ___EmotionJSX("div", {
    className: "kt-footer__copyright"
  }, "Copyright\xA0\xA9\xA0", ___EmotionJSX("div", null, today.toString(), " OneEcosystem")), ___EmotionJSX("div", {
    className: "kt-footer__menu"
  }, ___EmotionJSX("div", {
    className: "vk-two-cols-aside-footer vk-terms-container"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 1
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: true,
    className: "vk-ml-24"
  }, ___EmotionJSX(Link, {
    to: "/terms",
    className: "vk-link-sm-gray"
  }, "Terms & Conditions")), ___EmotionJSX(Grid, {
    item: true,
    xs: true,
    className: "vk-ml-24"
  }, ___EmotionJSX(Link, {
    to: "/privacy-policy",
    className: "vk-link-sm-gray"
  }, "Privacy Policy")))))));
};
export default Footer;