import React from 'react';
import PropTypes from 'prop-types';
import { findCountryImage } from '../../../enums/countries';
import { countryComp } from '../../../components/UI/Shared/countrySelectOptions';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableCountryCol = function NetworkTableCountryCol(props) {
  var country = props.country;
  return ___EmotionJSX("div", {
    className: "vk-network-table-country-col"
  }, countryComp(findCountryImage(country), 'vk-mr-8'), ___EmotionJSX("div", {
    className: "vk-network-table-country-col-name"
  }, country));
};
NetworkTableCountryCol.propTypes = {
  country: PropTypes.string
};
export default NetworkTableCountryCol;